import { ReactComponent as SaleLine } from "../../img/sale-line.svg";

const ProductSalePrice = ({ oldPrice, price, isBonusProduct }) => {
    return (
        <span>
            <span className="old-price">
                {oldPrice} ₽<SaleLine className="old-price-line" />
            </span>
            &nbsp;
            <span className="main-color">
                {!isBonusProduct ? `${Math.ceil(price)} ₽` : "Подарок"}
            </span>
        </span>
    );
};

export default ProductSalePrice;
