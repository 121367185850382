const initialState = {
    bonusProductModal: null,
};

const bonusProductModal = (state = initialState, action) => {
    switch (action.type) {
        case "SET_MODAL_BONUS_PRODUCT": {
            return {
                ...state,
                bonusProductModal: action.payload,
            };
        }
        default:
            return state;
    }
};

export default bonusProductModal;
