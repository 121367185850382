import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { _getDomain, _getPlatform, _isMobile } from "../../components/helpers";
import axios from "axios";
import {
    Box,
    CircularProgress,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import { format } from "date-fns";
import clsx from "clsx";
import useApiRequests from "../../hooks/useApiRequests";

const UserBonusesHistory = () => {
    const { getRequestAPI } = useApiRequests();

    const user = useSelector(({ user }) => user.user);

    const ROWS_PER_PAGE = 8;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState(null);

    useEffect(() => {
        const request = axios.CancelToken.source();
        if (user.token && user.phone) {
            setLoading(true);

            getRequestAPI("getUserBonuses", "", {
                params: {
                    token: user.token,
                    phone: user.phone,
                    page: currentPage + 1,
                    perPage: ROWS_PER_PAGE,
                },
                cancelToken: request.token,
            })
                .then((resp) => {
                    if (resp?.data?.status === "success") {
                        setTotal(resp.data.bonusesHistory.total);
                        setData(resp.data.bonusesHistory.items);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        setError(true);
                    }
                })
                .catch((err) => {
                    setError(true);
                    console.log(err.message);
                });
        }
        return () => {
            request.cancel();
        };
    }, [currentPage]);

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    if (!data && !loading && !error) {
        return null;
    }

    if ((!data?.length || error) && !loading) {
        return (
            <>
                <h3>Иcтория операций</h3>
                <div className="user-bonuses-history">
                    <div className="user-bonuses-history--empty-table">
                        {error
                            ? "При загрузке истории произошла ошибка, попробуйте позже"
                            : "Здесь пока пусто"}
                    </div>

                    <TablePagination
                        component="div"
                        count={total}
                        rowsPerPage={ROWS_PER_PAGE}
                        rowsPerPageOptions={[]}
                        page={currentPage}
                        onPageChange={handleChangePage}
                        labelDisplayedRows={({ from, to, count }) =>
                            data?.length ? `${from}-${to} из ${count}` : ""
                        }
                        sx={{
                            "& p": {
                                marginBottom: 0,
                            },
                        }}
                    />
                </div>
            </>
        );
    }

    const emptyRows =
        currentPage > 0 ? Math.max(0, ROWS_PER_PAGE - data?.length) : 0;

    return (
        <>
            <h3>Иcтория операций</h3>
            <div className="user-bonuses-history">
                <div className="user-bonuses-history--table-container">
                    {_isMobile() ? (
                        <div className="user-bonuses-history--mobile-table">
                            {!data && loading ? (
                                [...Array(ROWS_PER_PAGE)].map(() => (
                                    <div className="mobile-table--operation">
                                        <Skeleton sx={{ width: "100%" }} />
                                    </div>
                                ))
                            ) : (
                                <>
                                    {data.map((operation) => (
                                        <div className="mobile-table--operation">
                                            <div className="mobile-table--operation-left-col">
                                                <div className="mobile-table--operation-text">
                                                    {operation.text}
                                                </div>
                                                <div className="mobile-table--operation-date">
                                                    {format(
                                                        parseInt(
                                                            operation.time *
                                                                1000
                                                        ),
                                                        "d.MM.yyyy H:mm"
                                                    )}
                                                </div>
                                            </div>
                                            <div className="mobile-table--operation-right-col">
                                                <div
                                                    className={clsx(
                                                        "mobile-table--operation-amount",
                                                        operation.type === "add"
                                                            ? "add"
                                                            : "decrease"
                                                    )}
                                                >
                                                    {operation.type === "add"
                                                        ? "+"
                                                        : "-"}
                                                    {parseInt(
                                                        operation.amount
                                                    ).toLocaleString("ru-RU")}
                                                </div>
                                                <div className="mobile-table--operation-type">
                                                    {operation.type === "add"
                                                        ? "Начисление"
                                                        : operation.type ===
                                                          "decrease"
                                                        ? "Списание"
                                                        : operation.type}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {emptyRows > 0 && (
                                        <div
                                            style={{
                                                height: emptyRows * 86,
                                                borderBottom:
                                                    "1px solid #f1f1f1",
                                            }}
                                        ></div>
                                    )}
                                </>
                            )}
                        </div>
                    ) : (
                        <TableContainer>
                            <Table
                                sx={{
                                    "& .MuiTableCell-root": {
                                        borderColor: "var(--divider-color)",
                                    },
                                }}
                            >
                                <TableHead
                                    sx={{
                                        "& .MuiTableCell-head": {
                                            fontWeight: 700,
                                            lineHeight: 1.5,
                                        },
                                    }}
                                >
                                    <TableRow>
                                        <TableCell width={"22%"}>
                                            Дата и время
                                        </TableCell>
                                        <TableCell align="left" width={"20%"}>
                                            Тип операции
                                        </TableCell>
                                        <TableCell align="left">
                                            Сумма
                                        </TableCell>
                                        <TableCell align="left">
                                            Операция
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {loading && !data ? (
                                    <TableBody>
                                        {[...Array(ROWS_PER_PAGE)].map(
                                            (_, index) => {
                                                return (
                                                    <TableRow
                                                        key={index}
                                                        style={{
                                                            height: 53,
                                                        }}
                                                    >
                                                        <TableCell>
                                                            <Skeleton />
                                                        </TableCell>
                                                        <TableCell
                                                            width={"20%"}
                                                        >
                                                            <Skeleton />
                                                        </TableCell>
                                                        <TableCell
                                                            width={"15%"}
                                                        >
                                                            <Skeleton />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Skeleton />
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }
                                        )}
                                    </TableBody>
                                ) : (
                                    <TableBody sx={{ position: "relative" }}>
                                        {data.map((operation) => (
                                            <TableRow key={operation.id}>
                                                <TableCell width={"22%"}>
                                                    {format(
                                                        parseInt(
                                                            operation.time *
                                                                1000
                                                        ),
                                                        "d.MM.yyyy H:mm"
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    width={"20%"}
                                                >
                                                    {operation.type === "add"
                                                        ? "Начисление"
                                                        : operation.type ===
                                                          "decrease"
                                                        ? "Списание"
                                                        : operation.type}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <span
                                                        className={clsx(
                                                            "operation-amount",
                                                            operation.type ===
                                                                "add"
                                                                ? "add"
                                                                : "decrease"
                                                        )}
                                                    >
                                                        {operation.type ===
                                                        "add"
                                                            ? "+"
                                                            : "-"}
                                                        {parseInt(
                                                            operation.amount
                                                        ).toLocaleString(
                                                            "ru-RU"
                                                        )}
                                                    </span>
                                                </TableCell>
                                                <TableCell align="left">
                                                    {operation.text}
                                                </TableCell>
                                            </TableRow>
                                        ))}

                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: 53 * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    )}

                    {loading && data?.length ? (
                        <Box
                            sx={{
                                position: "absolute",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                bgcolor: "var(--loading-backdrop-color)",
                                borderRadius: "8px 8px 0 0",
                            }}
                        >
                            <CircularProgress size={44} />
                        </Box>
                    ) : null}
                </div>
                <TablePagination
                    component="div"
                    count={total}
                    rowsPerPage={ROWS_PER_PAGE}
                    rowsPerPageOptions={[]}
                    page={currentPage}
                    onPageChange={handleChangePage}
                    labelDisplayedRows={({ from, to, count }) =>
                        data?.length ? `${from}-${to} из ${count}` : ""
                    }
                    sx={{
                        "& p": {
                            marginBottom: 0,
                        },
                    }}
                />
            </div>
        </>
    );
};

export default UserBonusesHistory;
