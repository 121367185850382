import useBonusProducts from "../../hooks/useBonusProducts";

export default function CheckoutBonusProduct() {
    const { cartBonusProduct } = useBonusProducts();

    let dataAttributes = {};
    if (cartBonusProduct.attributes) {
        Object.values(cartBonusProduct.attributes).forEach((value, index) => {
            dataAttributes[index] = value.name;
        });
    }

    return (
        <div
            className="checkout--product"
            data-product-id={cartBonusProduct.id}
        >
            <div className="checkout--product-name">
                {cartBonusProduct.title}
                <div className="minicart--product-attributes">
                    {cartBonusProduct.options.weight ? (
                        <div className="weight">
                            {cartBonusProduct.options.weight} гр.
                        </div>
                    ) : (
                        ""
                    )}
                    {cartBonusProduct.options.count_rolls ? (
                        <div className="count-rolls">
                            {cartBonusProduct.options.count_rolls} шт.
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
            <div className="checkout--product-result">
                <span className="main-color">Подарок</span>
            </div>
        </div>
    );
}
