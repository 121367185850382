import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useSelector } from "react-redux";

const CheckoutTypeDelivery = ({ typeDelivery, handleChangeTypeDelivery }) => {
    const deliveryTypeStatus = useSelector(
        (state) =>
            state.config.data.CONFIG_type_receiving_delivery_status === "active"
    );
    const deliveryTypeName = useSelector(
        (state) =>
            state.config.data.CONFIG_type_receiving_delivery_name || "Доставка"
    );

    const selfdeliveryTypeStatus = useSelector(
        (state) =>
            state.config.data.CONFIG_type_receiving_selfdelivery_status ===
            "active"
    );
    const selfdeliveryTypeName = useSelector(
        (state) =>
            state.config.data.CONFIG_type_receiving_selfdelivery_name ||
            "Самовывоз"
    );

    const inRestorauntTypeStatus = useSelector(
        (state) =>
            state.config.data.CONFIG_type_receiving_inRestoraunt_status ===
            "active"
    );
    const inRestorauntTypeName = useSelector(
        (state) =>
            state.config.data.CONFIG_type_receiving_inRestoraunt_name ||
            "В зале"
    );

    return (
        <RadioGroup
            row
            aria-labelledby="typeDelivery-label"
            name="typeDelivery"
            value={typeDelivery}
            onChange={handleChangeTypeDelivery}
            sx={{
                "& .MuiFormControlLabel-root": {
                    alignItems: "start",
                },
            }}
        >
            {deliveryTypeStatus ? (
                <FormControlLabel
                    className="custom-radio"
                    value="delivery"
                    control={<Radio />}
                    label={deliveryTypeName}
                />
            ) : null}
            {selfdeliveryTypeStatus ? (
                <FormControlLabel
                    className="custom-radio"
                    value="self"
                    control={<Radio />}
                    label={selfdeliveryTypeName}
                />
            ) : null}
            {inRestorauntTypeStatus ? (
                <FormControlLabel
                    className="custom-radio"
                    value="inRestoraunt"
                    control={<Radio />}
                    label={inRestorauntTypeName}
                />
            ) : null}
        </RadioGroup>
    );
};

export default CheckoutTypeDelivery;
