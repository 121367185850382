import React, { useEffect } from "react";

export const YandexAuthCaptcha = ({
    onVerifyAuth,
    authSmartCaptchaWidgetId,
    smartCaptchaToken,
    updateError,
}) => {
    useEffect(() => {
        const authWidgetId = window.smartCaptcha.render(
            "auth-captcha-container",
            {
                sitekey: smartCaptchaToken || "",
                invisible: true,
                callback: onVerifyAuth,
                hideShield: true,
            }
        );

        window.smartCaptcha.subscribe(authWidgetId, "network-error", () => {
            updateError({
                status: "error",
                message: "Возникла сетевая ошибка",
            });
        });

        window.smartCaptcha.subscribe(authWidgetId, "javascript-error", () => {
            updateError({
                status: "error",
                message: "Возникла критическая ошибка JS",
            });
        });

        authSmartCaptchaWidgetId.current = authWidgetId;

        return () => {
            window.smartCaptcha.destroy(authWidgetId);
        };
    }, []);

    return <></>;
};

export const YandexRecallCaptcha = ({
    onVerifyRecall,
    recallSmartCaptchaWidgetId,
    smartCaptchaToken,
}) => {
    useEffect(() => {
        const recallWidgetId = window.smartCaptcha.render(
            "recall-captcha-container",
            {
                sitekey: smartCaptchaToken || "",
                invisible: true,
                callback: onVerifyRecall,
                hideShield: true,
            }
        );

        recallSmartCaptchaWidgetId.current = recallWidgetId;

        return () => {
            window.smartCaptcha.destroy(recallWidgetId);
        };
    }, []);

    return <></>;
};

export const YandexResmsCaptcha = ({
    onVerifyResms,
    resmsSmartCaptchaWidgetId,
    smartCaptchaToken,
}) => {
    useEffect(() => {
        const resmsWidgetId = window.smartCaptcha.render(
            "resms-captcha-container",
            {
                sitekey: smartCaptchaToken || "",
                invisible: true,
                callback: onVerifyResms,
                hideShield: true,
            }
        );

        resmsSmartCaptchaWidgetId.current = resmsWidgetId;

        return () => {
            window.smartCaptcha.destroy(resmsWidgetId);
        };
    }, []);

    return <></>;
};
