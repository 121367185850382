import * as React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, Divider, Grid, Skeleton } from "@mui/material";
import { _getPlatform } from "../helpers";
import FooterAddressList from "./FooterAddressList";
import FooterMenu from "./FooterMenu";
import FooterSocialLinks from "./FooterSocialLinks";
import "../../css/footer.css";
import clsx from "clsx";
import useTownNavigate from "../../hooks/useTownNavigate";
import MobileAppsWidget from "../MobileAppsWidget";
import useMetricFunctions from "../../hooks/useMetricFunctions";

export default function Footer() {
    const { rootTownPathname } = useTownNavigate();
    const { pathname } = useLocation();
    const { mectricClickPhone } = useMetricFunctions();

    const config = useSelector((state) => state.config.data);

    const footerType = config.CONFIG_type_footer;

    const isOnHomePage =
        pathname === rootTownPathname || pathname === rootTownPathname + "/";

    if (_getPlatform() !== "site") return <div className="footer-space"></div>;

    return (
        <div className={clsx("footer", footerType === "one" && "white")}>
            {config.CONFIG_app_download_vidget === "disabled" ||
            pathname.includes("checkout") ||
            pathname.includes("order-complete") ? null : (
                <MobileAppsWidget />
            )}
            <Container className="footer--container">
                {isOnHomePage ? (
                    <>
                        {config?.CONFIG_footer_text ? (
                            <div className="about">
                                {footerType === "one" ? (
                                    <Divider
                                        sx={{
                                            mt: "0",
                                            mb: "48px",
                                            borderColor:
                                                footerType === "one"
                                                    ? "rgba(0, 0, 0, 0.12)"
                                                    : "#3c3b3b",
                                        }}
                                    />
                                ) : null}
                                {config.CONFIG_site_h1_title ? (
                                    <h1 className="about-title">
                                        {config.CONFIG_site_h1_title}
                                    </h1>
                                ) : null}
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: config.CONFIG_footer_text,
                                    }}
                                ></div>
                            </div>
                        ) : null}
                        {footerType === "one" ? null : (
                            <hr className="about-divider" />
                        )}
                    </>
                ) : null}
                {footerType === "one" ? (
                    <Divider
                        sx={{
                            mb: "3rem",
                            borderColor:
                                footerType === "one"
                                    ? "rgba(0, 0, 0, 0.12)"
                                    : "#3c3b3b",
                        }}
                    />
                ) : null}

                <Grid container spacing={5}>
                    <Grid item sm={12} md={4} sx={{ width: 1 }}>
                        <img
                            src={
                                footerType === "one"
                                    ? window.CONFIG_company_logo_main
                                    : window.CONFIG_company_logo_footer
                            }
                            className="footer--logo"
                            alt="Логотип"
                        />
                        <FooterAddressList />
                    </Grid>
                    <Grid item sm={12} md={4} sx={{ width: 1 }}>
                        <FooterMenu />
                    </Grid>
                    <Grid
                        item
                        sm={12}
                        md={4}
                        sx={{ width: 1 }}
                        className="right-col"
                    >
                        {config.CONFIG_format_phone ? (
                            <h4>
                                <a
                                    href={`tel:${config.CONFIG_format_phone}`}
                                    onClick={mectricClickPhone}
                                    target="_parent"
                                >
                                    {config.CONFIG_format_phone}
                                </a>
                            </h4>
                        ) : null}
                        <FooterSocialLinks />
                    </Grid>
                </Grid>

                <div className="footer-copyright">
                    <Grid container spacing={5}>
                        {!config.CONFIG_active_no_whitelabel && (
                            <Grid
                                item
                                sm={12}
                                md={4}
                                sx={{ width: 1 }}
                                className="right-col"
                            >
                                <div className="creators">
                                    Работает на платформе{" "}
                                    <a
                                        href="https://foodninja.pro"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        FoodNinja
                                    </a>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </div>
            </Container>
        </div>
    );
}
