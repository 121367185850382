import React, { useState, useEffect } from "react";
import { setModalBonusProduct } from "../redux/actions/bonusProductModal";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { addBonusProductToCart } from "../redux/actions/cart";
import useBonusProducts from "../hooks/useBonusProducts";
import useContentOverflow from "hooks/useContentOverflow";
import { Button } from "@mui/material";
import { priceToLocale } from "./helpers";
import soon from "../img/photo-soon.svg";
import { ReactComponent as SaleLine } from "../img/sale-line.svg";

const ModalBonusProduct = ({ bonusProduct, handleClose }) => {
    const dispatch = useDispatch();
    const { contentOverflow, containerRef, contentRef } = useContentOverflow();

    const { userCartBonusProduct } = useSelector(
        ({ products, cart, bonusesProductsModal }) => {
            return {
                bonuses_items: products.bonuses_items,
                userCartBonusProduct: cart.bonusProduct,
                openBonusesProductsModal:
                    bonusesProductsModal.openBonusesProductsModal,
            };
        },
        shallowEqual
    );

    const {
        cartTotalPrice,
        bonusesDisabledByCategory,
        bonusesDisabledByPromocode,
    } = useBonusProducts();

    const [showMore, setShowMore] = useState(false);

    const handleChooseBonusProduct = () => {
        if (userCartBonusProduct.id === bonusProduct.id) {
            dispatch(addBonusProductToCart({}));
            return;
        }
        if (
            cartTotalPrice < bonusProduct.limit ||
            bonusesDisabledByCategory ||
            bonusesDisabledByPromocode
        ) {
            return;
        }
        dispatch(addBonusProductToCart({ ...bonusProduct }));
        handleClose();
        // if (
        //     bonusProduct.type === "variations"
        //     || hasRequiredModificator
        // ) {
        //     dispatch(setModalBonusProduct({ ...bonusProduct }));
        // } else {
        //     dispatch(addBonusProductToCart({ ...bonusProduct }));
        //     handleClose();
        // }
    };

    // const hasRequiredModificator = bonusProduct.product_addons?.find(
    //     (modificator) => modificator.required === "yes"
    // );

    return (
        <div
            className={`bonuses-modal__cart product product-item ${
                userCartBonusProduct.id === bonusProduct.id
                    ? "bonuses-modal__cart-active"
                    : ""
            }`}
        >
            <div className="product--image " onClick={handleChooseBonusProduct}>
                <img
                    src={bonusProduct.img ? bonusProduct.img : soon}
                    alt={bonusProduct.title}
                />
            </div>
            <div className="bonuses-modal__inner-wrapper">
                <div
                    className="bonuses-modal__cart-title"
                    onClick={handleChooseBonusProduct}
                    dangerouslySetInnerHTML={{
                        __html: bonusProduct.full_title || bonusProduct.title,
                    }}
                ></div>
                <div className="bonuses-modal__price-container">
                    <span className="bonuses-modal__cart-price">
                        {bonusProduct.options._price} ₽
                        <SaleLine className="old-price-line" />
                    </span>{" "}
                    <span className="main-color">Бесплатно</span>
                </div>

                <div ref={containerRef}>
                    <div
                        ref={contentRef}
                        className={`bonuses-modal__cart-content ${
                            showMore ? "bonuses-modal__cart-content--mod" : ""
                        }`}
                        dangerouslySetInnerHTML={{
                            __html: bonusProduct.content,
                        }}
                    ></div>
                </div>
                <div className="bonuses-modal__cart-info">
                    <div className="bonuses-modal__cart-cost">
                        от {bonusProduct.limit} ₽
                    </div>
                    {contentOverflow && (
                        <div className="bonuses-modal__detail-wrapper">
                            <div
                                className="bonuses-modal__cart-detail"
                                onClick={() => setShowMore(!showMore)}
                            >
                                {showMore ? "Свернуть" : "Подробнее"}
                            </div>
                        </div>
                    )}
                </div>

                {cartTotalPrice >= bonusProduct.limit &&
                    userCartBonusProduct.id !== bonusProduct.id && (
                        <Button
                            variant="button"
                            className="btn btn--action"
                            sx={{
                                width: 1,
                                mt: 2,
                            }}
                            onClick={handleChooseBonusProduct}
                            disabled={
                                bonusesDisabledByPromocode ||
                                bonusesDisabledByCategory
                            }
                        >
                            Выбрать
                        </Button>
                    )}
                {cartTotalPrice >= bonusProduct.limit &&
                    userCartBonusProduct.id === bonusProduct.id && (
                        <Button
                            variant="button"
                            className="btn btn--action"
                            sx={{
                                width: 1,
                                mt: 2,
                            }}
                            onClick={handleChooseBonusProduct}
                        >
                            Отменить
                        </Button>
                    )}
                {cartTotalPrice < bonusProduct.limit && (
                    <Button
                        disabled
                        variant="button"
                        sx={{
                            width: 1,
                            mt: 2,
                        }}
                        className="btn btn--outline"
                    >
                        Не хватает{" "}
                        {priceToLocale(bonusProduct.limit - cartTotalPrice)}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default ModalBonusProduct;
