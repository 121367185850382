import { MiniCartProduct } from "components";
import { useSelector } from "react-redux";
import { getItemTotalPrice } from "redux/reducers/cart";

const MiniCartProductsList = () => {
    const items = useSelector((state) => state.products.items);
    const cartProducts = useSelector((state) => state.cart.items);

    return Object.keys(cartProducts).map((key, index) => {
        if (
            items[key] &&
            items[key].type !== undefined &&
            items[key].type === "variations"
        ) {
            return cartProducts[key].items.map(
                (keyVariantProduct, indexVariantProduct) => (
                    <MiniCartProduct
                        disabled={cartProducts[key].disabled}
                        key={indexVariantProduct}
                        productIndex={indexVariantProduct}
                        productCart={
                            cartProducts[key].items[indexVariantProduct]
                        }
                        productCount={1}
                        productTotalPrice={cartProducts[key].totalPrice}
                    />
                )
            );
        } else {
            const itemsWithoutModificators = cartProducts[key].items?.filter(
                (el) => !el.choosenModificators?.length
            );
            const itemsWithoutModificatorsTotal = getItemTotalPrice(
                itemsWithoutModificators
            );
            let itemWithoutModificatorRendered = false;
            return cartProducts[key].items.map((el, inx) => {
                if (el.choosenModificators?.length) {
                    return (
                        <MiniCartProduct
                            disabled={cartProducts[key].disabled}
                            key={inx}
                            productIndex={inx}
                            productCart={el}
                            productCount={1}
                            productTotalPrice={
                                el.options._promocode_price
                                    ? Math.ceil(el.options._promocode_price)
                                    : el.options._price
                            }
                        />
                    );
                } else if (!itemWithoutModificatorRendered) {
                    itemWithoutModificatorRendered = true;
                    return (
                        <MiniCartProduct
                            disabled={cartProducts[key].disabled}
                            key={inx}
                            productIndex={inx}
                            productCart={el}
                            productCount={itemsWithoutModificators.length}
                            productTotalPrice={itemsWithoutModificatorsTotal}
                        />
                    );
                }
            });
        }
    });
};

export default MiniCartProductsList;
