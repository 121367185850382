import { Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";

const CheckoutAddressPanelSkeleton = () => {
    return (
        <div className="checkout--address-panel">
            <h4>Укажите адрес</h4>
            <Skeleton
                variant="rounded"
                width={200}
                height={18}
                sx={{
                    borderRadius: "20px",
                    mb: "16px",
                }}
            />
            <Skeleton
                variant="rounded"
                width={240}
                height={18}
                sx={{
                    borderRadius: "20px",
                    mb: "16px",
                }}
            />
            <Skeleton
                variant="rounded"
                width={170}
                height={18}
                sx={{
                    borderRadius: "20px",
                    mb: "16px",
                }}
            />
        </div>
    );
};

export default CheckoutAddressPanelSkeleton;
