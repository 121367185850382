import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModalSale } from "redux/actions/saleModal";

const useActiveSale = () => {
    const dispatch = useDispatch();

    const sales = useSelector((state) => state.pages.sales);

    const handleCloseSaleModal = useCallback(() => {
        let url = new URL(window.location.href);
        if (url.searchParams.has("sale_id")) {
            url.searchParams.delete("sale_id");
            window.history.replaceState(
                "",
                document.title,
                window.location.pathname
            );
        }
        dispatch(setModalSale(null));
    }, []);

    const handleSetActiveSale = useCallback((sale) => {
        let url = new URL(window.location.href);
        if (!url.searchParams.has("sale_id")) {
            url.searchParams.append("sale_id", sale.saleID);
            window.history.pushState({}, "", url.href);
        }
        dispatch(setModalSale(sale));
    }, []);

    const openSaleFromLink = useCallback((link) => {
        const saleId = link.replace("?sale_id=", "");
        const sale = sales?.find((sale) => sale.saleID == saleId);
        if (sale) {
            handleSetActiveSale(sale);
        }
    }, []);

    return {
        handleCloseSaleModal,
        handleSetActiveSale,
        openSaleFromLink,
    };
};

export default useActiveSale;
