import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Container } from "@mui/material";
import { closeMobileMenu } from "../../redux/actions/header";
import clsx from "clsx";

const HeaderTopMenu = ({ hide }) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const topMenu = useSelector((state) => state.pages.topMenu);
    const { mobileMenuOpen } = useSelector((state) => state.header);
    const pages = useSelector(({ pages }) => pages.topMenu);

    if (!topMenu?.length || hide) {
        return null;
    }

    const handleClickTopMenu = () => {
        if (mobileMenuOpen) {
            dispatch(closeMobileMenu());
        }
    };

    const currentPage = pages.find((el) => el.url === pathname);

    return (
        <div
            className="top-menu"
            style={{
                paddingTop: window.newHeaderDesign ? "16px" : 0,
                marginBottom: window.newHeaderDesign ? "16px" : 0,
            }}
        >
            <Container>
                <ul>
                    {pages.map((item, index) => (
                        <li key={item.id}>
                            {item.target === "_blank" ? (
                                <a
                                    href={item.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    title={item.title}
                                >
                                    {item.title}
                                </a>
                            ) : (
                                <Link
                                    onClick={() => handleClickTopMenu()}
                                    className={clsx(
                                        item.id === currentPage?.id && "active"
                                    )}
                                    to={item.url}
                                >
                                    {item.title}
                                </Link>
                            )}
                        </li>
                    ))}
                </ul>
            </Container>
        </div>
    );
};

export default HeaderTopMenu;
