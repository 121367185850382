import { useSelector } from "react-redux";
import { ReactComponent as HeaderClockIcon } from "../../img/header-clock.svg";
import { _isObjEmpty } from "../helpers";
import HeaderWorkTimeSkeleton from "../Skeletons/HeaderWorkTimeSkeleton";

const HeaderWorkTimeBlock = () => {
    const formatStartWork = useSelector(
        (state) => state.config.data.CONFIG_format_start_work
    );
    const formatEndWork = useSelector(
        (state) => state.config.data.CONFIG_format_end_work
    );
    const isConfigEmpty = useSelector((state) =>
        _isObjEmpty(state.config.data)
    );

    return (
        <div className="header-work">
            <HeaderClockIcon className="icn" />
            {!isConfigEmpty ? (
                <div className="info-wrapper">
                    {formatStartWork && formatEndWork ? (
                        <>
                            <div className="title">Сегодня работаем</div>
                            <div className="info">
                                {`с ${formatStartWork} до ${formatEndWork}`}
                            </div>
                        </>
                    ) : (
                        <div className="info">Сегодня мы не работаем</div>
                    )}
                </div>
            ) : (
                <HeaderWorkTimeSkeleton />
            )}
        </div>
    );
};

export default HeaderWorkTimeBlock;
