const colorsPalette = {
    light: {
        text: "#000",
        background: "#fff",
        rgbBackground: "255, 255 , 255",
        boxShadow: "0 0 20px rgb(0 0 0 / 10%)",
        productBackground: "#fff",
        productBoxShadow: "0 0 20px rgb(0 0 0 / 10%)",
        productHoverBoxShadow: "0 0 20px rgb(0 0 0 / 20%)",
        productBorder: "#fff",
        productFadeColor: "255, 255, 255",
        recommendProductBoxShadow: "0 0 20px rgb(0 0 0 / 10%)",
        modalBackground: "#fff",
        rgbModalBackground: "255, 255 , 255",
        modalBackgroundPrimary: "#f2f3f5",
        addonBackground: "#fff",
        variationsBackround: "#dee2e6",
        selectedVariationBackround: "#fff",
        bodyBackground:
            !window.backgroundColor || window.backgroundColor === "default"
                ? "#fff"
                : window.backgroundColor,
        miniCartBackground: "#f3f3f7",
        headerBackground: "#fff",
        headerBorder: window.fullWidthSite
            ? "rgba(156, 157, 172, 0.25)"
            : "#fff",
        mobileMenuBtnHover: "#dadce3",
        topMenuBackground: "transparent",
        topMenuBorder: window.newHeaderDesign ? "1px solid #eee" : "none",
        stickedBarBackground: "255, 255, 255",
        stickedBarOpacity: window.fullWidthSite ? "1" : "0.75",
        dividerColor: "#eee",
        dividerColorContrast: "#ccc",
        counterButtonsColor: "#f3f3f7",
        loadingBackdropColor: "rgba(255,255,255,0.7)",
        footerBackground: "#333",
        scrollBtnBackground: "#fff",
        scrollBtnColor: window.CONFIG_main_color,
    },
    dark: {
        text: "#e1e3e6",
        background: "#141414",
        rgbBackground: "20, 20 , 20",
        boxShadow: "0 0 20px rgb(255 255 255 / 5%)",
        productBackground: "#222222",
        productBoxShadow: "0 0 10px rgb(255 255 255 / 10%)",
        productHoverBoxShadow: "0 0 10px rgb(255 255 255 / 20%)",
        productBorder: "#363738",
        productFadeColor: "34, 34, 34",
        recommendProductBoxShadow: "none",
        modalBackground: "#292929",
        rgbModalBackground: "41, 41, 41",
        modalBackgroundPrimary: "#292929",
        modalContrast: "#292929",
        addonBackground: "#1a1a1a",
        variationsBackround: "#1a1a1a",
        selectedVariationBackround: "#333",
        bodyBackground: window.backgroundColor
            ? window.backgroundColor
            : "#141414",
        miniCartBackground: "#202021",
        headerBackground: window.newHeaderDesign ? "transparent" : "#222222",
        headerBorder: window.newHeaderDesign ? "transparent" : "#363738",
        mobileMenuBtnHover: "#535353",
        topMenuBackground: window.newHeaderDesign ? "#222222" : "transparent",
        topMenuBorder: window.newHeaderDesign ? "1px solid #363738" : "none",
        stickedBarBackground: "0, 0, 0",
        stickedBarOpacity: window.fullWidthSite ? "1" : "0.75",
        dividerColor: "#363738",
        dividerColorContrast: "#363738",
        counterButtonsColor: "rgba(255, 255, 255, 0.08)",
        loadingBackdropColor: "rgba(255,255,255,0.15)",
        footerBackground: "#222",
        scrollBtnBackground: window.CONFIG_main_color,
        scrollBtnColor: "#fff",
    },
};

export default colorsPalette;
