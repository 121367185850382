import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const useMetricFunctions = () => {
    const yaCounterID = useSelector(
        (state) => state.config.data.CONFIG_id_metrika
    );
    const vkPriceListID = useSelector(
        (state) => state.config.data.CONFIG_id_vk_price_list
    );

    const metricViewCategory = () => {
        window.ym?.(yaCounterID, "reachGoal", "view_category");
        window.gtag?.("event", "view_item");
        window.VK?.Retargeting?.ProductEvent(vkPriceListID, "view_category");
        window.fbq?.("track", "ViewContent");
    };

    const mectricOpenCart = () => {
        window.ym?.(yaCounterID, "reachGoal", "view_cart");
        window.fbq?.("track", "ViewContent");
    };

    const metricViewProduct = (product) => {
        window.ym?.(yaCounterID, "reachGoal", "view_product");
        let dataLayer = window.dataLayer || [];
        dataLayer.push({
            ecommerce: {
                currencyCode: "RUB",
                detail: {
                    products: [
                        {
                            id: product.id,
                            name: product.title,
                            price: product.options._price,
                            quantity: 1,
                        },
                    ],
                },
            },
        });
        window.gtag?.("event", "view_item");
        window.VK?.Retargeting?.ProductEvent(vkPriceListID, "view_product");
        window.fbq?.("track", "ViewContent");
    };

    const metricAddProductToCart = (product) => {
        window.ym?.(yaCounterID, "reachGoal", "add_to_cart");
        let dataLayer = window.dataLayer || [];
        dataLayer.push({
            ecommerce: {
                currencyCode: "RUB",
                add: {
                    products: [
                        {
                            id:
                                product.type === "variations" &&
                                product.variant?.variant_id
                                    ? product.variant?.variant_id
                                    : product.id,
                            name: product.title,
                            price:
                                product.type === "variations" &&
                                product.variant?.price
                                    ? product.variant?.price
                                    : product.options._price,
                            quantity: 1,
                        },
                    ],
                },
            },
        });
        window.gtag?.("event", "add_to_cart");
        window.VK?.Retargeting?.ProductEvent(vkPriceListID, "add_to_cart");
        window.fbq?.("track", "AddToCart");
    };

    const metricMakeOrder = (cartProducts, promocode, userPhone) => {
        window.ym?.(yaCounterID, "reachGoal", "make_order");

        let dataLayer = window.dataLayer || [];
        const dataProducts = [];
        Object.values(cartProducts).forEach((product) => {
            if (product.items[0].type === "variations") {
                product.items.forEach((productItem) => {
                    dataProducts.push({
                        id: productItem.variant?.variant_id,
                        name: productItem.title,
                        price: productItem.variant?.price,
                        quantity: 1,
                    });
                });
            } else {
                dataProducts.push({
                    id: product.items[0].id,
                    name: product.items[0].title,
                    price: product.items[0].options._price,
                    quantity: product.items.length,
                });
            }
        });
        const data = {
            ecommerce: {
                currencyCode: "RUB",
                purchase: {
                    actionField: {
                        id: userPhone,
                    },
                    products: dataProducts,
                },
            },
        };
        if (promocode?.code) {
            data.ecommerce.purchase.actionField.coupon = promocode.code;
        }
        dataLayer.push(data);

        window.VK?.Retargeting?.ProductEvent(vkPriceListID, "purchase");
        window.fbq?.("track", "ViewContent");
    };

    const metricVisitCheckout = () => {
        window.ym?.(yaCounterID, "reachGoal", "checkout");
        window.VK?.Retargeting?.ProductEvent(
            vkPriceListID,
            "initiate_checkout"
        );
        window.fbq?.("track", "ViewContent");
    };

    const mectricClickPhone = () => {
        window.ym?.(yaCounterID, "reachGoal", "click_phone");
    };

    const mectricClickIos = () => {
        window.ym?.(yaCounterID, "reachGoal", "click_ios");
    };

    const mectricClickAndroid = () => {
        window.ym?.(yaCounterID, "reachGoal", "click_android");
    };

    return {
        metricViewCategory,
        mectricOpenCart,
        metricViewProduct,
        metricAddProductToCart,
        metricMakeOrder,
        metricVisitCheckout,
        mectricClickPhone,
        mectricClickIos,
        mectricClickAndroid,
    };
};

export default useMetricFunctions;
