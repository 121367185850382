import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

import { openMobileMenu } from "redux/actions/header";

import { Button, Container, Step, StepLabel, Stepper } from "@mui/material";
import MenuDrawer from "./MenuDrawer";
import { ChooseTownModal, MiniCart, TownLink } from "components";

import MenuIcon from "@mui/icons-material/Menu";

import {
    _getPlatform,
    _isAuthEnabled,
    _isMobile,
    _isObjEmpty,
} from "components/helpers";
import clsx from "clsx";

import "../../css/full-width-header.css";
import HeaderWorkTimeSkeleton from "components/Skeletons/HeaderWorkTimeSkeleton";

const FullWidthHeader = ({
    hadleClickAccount,
    openAuthModalBtnClick,
    handleOpenTownModal,
}) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const config = useSelector((state) => state.config.data);
    const isConfigEmpty = useSelector((state) =>
        _isObjEmpty(state.config.data)
    );
    const configStatus = useSelector((state) => state.config.status);
    const user = useSelector((state) => state.user.user);
    const formatStartWork = useSelector(
        (state) => state.config.data.CONFIG_format_start_work
    );
    const formatEndWork = useSelector(
        (state) => state.config.data.CONFIG_format_end_work
    );

    const isTownChoiceAvailable =
        window.townsTypeRoute === "subDomain" ||
        window.townsTypeRoute === "folder";

    const stepperPage = ["/cart", "/checkout", "/order-complete"];
    const steps = ["Корзина", "Оформление заказа", "Заказ принят"];
    const lastPathname =
        "/" +
        pathname
            .split("/")
            ?.filter((el) => el)
            .slice(-1)?.[0];

    return (
        <div className="fullwidth-header--wrapper">
            <Container>
                <div
                    className={clsx(
                        "fullwidth-header",
                        _getPlatform() === "vk" && "vk"
                    )}
                >
                    <MenuDrawer
                        handleOpenTownModal={handleOpenTownModal}
                        hadleClickAccount={hadleClickAccount}
                        openAuthModalBtnClick={openAuthModalBtnClick}
                    />

                    <div
                        className="fullwidth-header--menu-btn"
                        onClick={() => {
                            dispatch(openMobileMenu());
                        }}
                    >
                        <MenuIcon style={{ color: "var(--text-color)" }} />
                    </div>

                    <TownLink
                        to="/"
                        className="fullwidth-header--logo-container"
                    >
                        <img
                            src={
                                window.CONFIG_design_dark_theme !== "active"
                                    ? window.CONFIG_company_logo_main
                                    : window.CONFIG_company_logo_footer
                            }
                            className="fullwidth-header--logo"
                            alt="Логотип"
                        />
                    </TownLink>

                    <div className="fullwidth-header--town-info">
                        {!isConfigEmpty ? (
                            <>
                                <b
                                    className="fullwidth-header--town"
                                    style={{
                                        cursor: isTownChoiceAvailable
                                            ? "pointer"
                                            : "default",
                                    }}
                                    onClick={
                                        isTownChoiceAvailable
                                            ? handleOpenTownModal
                                            : null
                                    }
                                >
                                    {config.CONFIG_town}
                                </b>
                                <span
                                    style={{ fontSize: "14px", color: "#666" }}
                                    className="fullwidth-header--time"
                                >
                                    {formatStartWork && formatEndWork
                                        ? `Время работы: ${formatStartWork}-${formatEndWork}`
                                        : "Сегодня мы не работаем"}
                                </span>
                            </>
                        ) : (
                            <HeaderWorkTimeSkeleton />
                        )}
                    </div>

                    {!stepperPage.includes(lastPathname) ? (
                        <div className="fullwidth-header--right-col">
                            {_isAuthEnabled(config) ? (
                                <div className="header-login">
                                    {!user.token ? (
                                        <Button
                                            onClick={openAuthModalBtnClick}
                                            className="btn--action"
                                            variant="contained"
                                            disabled={!configStatus}
                                        >
                                            Войти
                                        </Button>
                                    ) : (
                                        <Button
                                            className="btn--action"
                                            onClick={hadleClickAccount}
                                            variant="contained"
                                            sx={{
                                                whiteSpace: "nowrap",
                                            }}
                                        >
                                            Личный кабинет
                                        </Button>
                                    )}
                                </div>
                            ) : null}

                            <MiniCart />
                        </div>
                    ) : (
                        <Stepper
                            activeStep={stepperPage.indexOf(lastPathname)}
                            alternativeLabel
                            className="header-stepper"
                            sx={{
                                "&.MuiStepper-root": { alignItems: "center" },
                                marginLeft: "auto",
                            }}
                        >
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel className="step-label">
                                        {label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    )}
                </div>
            </Container>
            <ChooseTownModal />
        </div>
    );
};

export default FullWidthHeader;
