import { useSelector } from "react-redux";

const AccrueInfo = () => {
    const { deliveryCashback, selfDeliveryCashback } = useSelector(
        ({
            config: {
                data: { bonusProgramm },
            },
        }) => {
            return {
                deliveryCashback: bonusProgramm.deliveryPercent,
                selfDeliveryCashback: bonusProgramm.selfDeliveryPercent,
            };
        }
    );

    if (!deliveryCashback && !selfDeliveryCashback) {
        return null;
    }

    return (
        <div className="bonus-page--accrue-info-main">
            {deliveryCashback && selfDeliveryCashback ? (
                deliveryCashback === selfDeliveryCashback ? (
                    <>
                        Возвращаем{" "}
                        <span className="main-color">{deliveryCashback}%</span>{" "}
                        от каждого заказа бонусами.
                    </>
                ) : (
                    <>
                        Возвращаем{" "}
                        <span className="main-color">{deliveryCashback}%</span>{" "}
                        бонусами от каждого заказа на доставку и{" "}
                        <span className="main-color">
                            {selfDeliveryCashback}%
                        </span>{" "}
                        — от заказа навынос.
                    </>
                )
            ) : deliveryCashback && !selfDeliveryCashback ? (
                <>
                    Возвращаем бонусами{" "}
                    <span className="main-color">{deliveryCashback}%</span> от
                    каждого заказа на доставку.
                </>
            ) : (
                <>
                    Возвращаем бонусами{" "}
                    <span className="main-color">{selfDeliveryCashback}%</span>{" "}
                    от каждого заказа навынос.
                </>
            )}
        </div>
    );
};

export default AccrueInfo;
