import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setOpenModalAuth } from "redux/actions/user";
import { updateAlerts } from "redux/actions/systemAlerts";
import {
    removePromocode,
    addBonusProductToCart,
    setConditionalPromocode,
    addPromocode,
    clearConditionalPromocode,
} from "redux/actions/cart";
import { closeMiniCart } from "redux/actions/miniCart";
import { isCartEmptySelector } from "redux/selectors/cartSelectors";

import { Alert, Drawer, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import MiniCartBonusProduct from "components/Product/MiniCartBonusProduct";
import MiniCartFreeAddons from "components/Product/MiniCartFreeAddons";
import MiniCartReccomends from "components/MiniCart/MiniCartRecommends";
import CartBonusesProducts from "components/CartBonusesProducts";
import PromocodeMiniCartProduct from "components/Product/PromocodeMiniCartProduct";
import MiniCartTotal from "./MiniCartTotal";
import MiniCartProductsList from "./MiniCartProductsList";
import MiniCartButton from "./MiniCartButton";
import { ReactComponent as CatErrorSvg } from "../../img/cat-error.svg";

import {
    _checkPromocode,
    _getPlatform,
    _isAuthEnabled,
    _isMobile,
    _isPromocodesEnabled,
} from "components/helpers.js";
import clsx from "clsx";

import useTownNavigate from "hooks/useTownNavigate";
import useMetricFunctions from "hooks/useMetricFunctions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import catError from "img/cat-error.svg";

import "css/minicart.css";

function MiniCart() {
    const { navigateTo } = useTownNavigate();
    const dispatch = useDispatch();
    const { metricVisitCheckout } = useMetricFunctions();

    const minicartInner = useRef();

    const config = useSelector((state) => state.config.data);
    const bonuses_items = useSelector((state) => state.products.bonuses_items);
    const cartProducts = useSelector((state) => state.cart.items);
    const cartSubTotalPrice = useSelector((state) => state.cart.subTotalPrice);
    const promocode = useSelector((state) => state.cart.promocode);
    const conditionalPromocode = useSelector(
        (state) => state.cart.conditionalPromocode
    );
    const promocodeProduct = useSelector(
        (state) => state.cart.promocodeProducts
    );
    const userCartBonusProduct = useSelector(
        (state) => state.cart.bonusProduct
    );
    const user = useSelector((state) => state.user.user);
    const miniCartOpen = useSelector((state) => state.miniCart.miniCartOpen);
    const isCartEmpty = useSelector(isCartEmptySelector);

    const emptyCartImg = window.CONFIG_empty_cart_image;

    const hashEventListener = (event) => {
        if (
            window.location.hash !== "minicart-sidebar" &&
            event.oldURL.includes("#minicart-sidebar")
        ) {
            handleCloseMiniCart();
        }
    };

    useEffect(() => {
        window.addEventListener("hashchange", hashEventListener);
        return () => {
            window.removeEventListener("hashchange", hashEventListener);
        };
    }, []);

    if (!miniCartOpen && window.location.hash === "minicart-sidebar") {
        window.history.replaceState(
            "",
            document.title,
            window.location.pathname
        );
    }

    const handleCloseMiniCart = () => {
        if (window.location.hash === "#minicart-sidebar") {
            window.history.replaceState(
                "",
                document.title,
                window.location.pathname
            );
        }
        dispatch(closeMiniCart());
    };

    const handleMakeOrder = () => {
        // Проверка авторизации
        if (!user.token && _isAuthEnabled(config)) {
            dispatch(setOpenModalAuth(true));
        } else {
            metricVisitCheckout();
            dispatch(closeMiniCart());
            window.scrollTo(0, 0);
            navigateTo("/checkout");
        }
    };

    useEffect(() => {
        if (
            Object.keys(promocode).length !== 0 &&
            promocode.constructor === Object &&
            !conditionalPromocode
        ) {
            const resultCheckPromocode = _checkPromocode({
                promocode,
                promocodeProduct,
                items: cartProducts,
                cartTotal: cartSubTotalPrice,
                config,
            });
            if (resultCheckPromocode.status === "error") {
                dispatch(removePromocode());
                dispatch(setConditionalPromocode(promocode));
                dispatch(
                    updateAlerts({
                        open: true,
                        message: resultCheckPromocode.alert,
                    })
                );
            }
        }

        if (conditionalPromocode && !Object.keys(promocode).length) {
            const resultCheckPromocode = _checkPromocode({
                promocode: conditionalPromocode,
                items: cartProducts,
                cartTotal: cartSubTotalPrice,
                config,
            });
            if (resultCheckPromocode.status !== "error") {
                dispatch(addPromocode(conditionalPromocode));
                dispatch(clearConditionalPromocode());
            }
        }
    }, [
        promocode,
        conditionalPromocode,
        promocodeProduct,
        cartProducts,
        config,
        cartSubTotalPrice,
    ]);

    useEffect(() => {
        if (config.CONFIG_free_products_program_status !== "on") {
            dispatch(addBonusProductToCart({}));
        }
    }, [config.CONFIG_free_products_program_status]);

    return (
        <div
            className={
                bonuses_items !== undefined && bonuses_items.length
                    ? "minicart--wrapper active-bonuses"
                    : "minicart--wrapper"
            }
        >
            {_isMobile() ? null : <MiniCartButton />}
            <Drawer
                anchor="right"
                open={miniCartOpen}
                onClose={handleCloseMiniCart}
            >
                <div className="minicart--inner" ref={minicartInner}>
                    <div className="minicart--product-list">
                        <h2
                            className={clsx(
                                "minicart--inner-title",
                                _getPlatform() === "vk" && "vk"
                            )}
                        >
                            <div>Корзина</div>
                            <IconButton
                                color="inherit"
                                onClick={handleCloseMiniCart}
                                className="minicart--close"
                            >
                                {_getPlatform() === "vk" ? (
                                    <FontAwesomeIcon
                                        icon={faAngleLeft}
                                        className={"minicart--angle-icon"}
                                    />
                                ) : (
                                    <CloseIcon />
                                )}
                            </IconButton>
                        </h2>

                        {config.CONFIG_cart_info_text !== undefined &&
                        config.CONFIG_cart_info_text &&
                        !isCartEmpty ? (
                            <Alert
                                className="minicart--text-info"
                                severity="info"
                                sx={{ mb: 2, mr: 2, ml: 2 }}
                            >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: config.CONFIG_cart_info_text,
                                    }}
                                ></div>
                            </Alert>
                        ) : null}

                        {!isCartEmpty ? (
                            <MiniCartProductsList />
                        ) : (
                            <div className="minicart--empty">
                                {emptyCartImg ? (
                                    <img
                                        src={emptyCartImg}
                                        className="minicart--empty-logo"
                                        alt="Логотип"
                                    />
                                ) : (
                                    <CatErrorSvg />
                                )}
                                <h4>Ой, пусто!</h4>
                                <div className="minicart--empty-text">
                                    Добавьте товары в корзину.
                                </div>
                            </div>
                        )}

                        {userCartBonusProduct.id && (
                            <MiniCartBonusProduct
                                productCart={userCartBonusProduct}
                                productCount={1}
                                productTotalPrice={
                                    userCartBonusProduct.options._price
                                }
                            />
                        )}

                        {!!promocodeProduct.id && <PromocodeMiniCartProduct />}

                        {!isCartEmpty ? <MiniCartFreeAddons /> : null}

                        {!isCartEmpty ? (
                            <CartBonusesProducts minicart={true} />
                        ) : null}

                        {!isCartEmpty ? (
                            <MiniCartReccomends minicartInner={minicartInner} />
                        ) : null}
                    </div>

                    <MiniCartTotal
                        handleCloseMiniCart={handleCloseMiniCart}
                        handleMakeOrder={handleMakeOrder}
                    />
                </div>
            </Drawer>
        </div>
    );
}

export default MiniCart;
