import React from "react";
import { useSelector } from "react-redux";
import { _isMobile, _isObjEmpty } from "../components/helpers.js";
import {
    Card,
    CardContent,
    CardMedia,
    CardActionArea,
    Container,
    Slide,
    Typography,
    Grid,
    Box,
    Button,
    CardActions,
} from "@mui/material";
import {
    Header,
    Footer,
    PromocodeButton,
    TopCategoriesMenu,
    SaleCard,
} from "../components";
import useActiveSale from "hooks/useActiveSale.js";
import "../css/sale.css";
import SalesListSkeleton from "../components/Skeletons/SalesListSkeleton.jsx";

export default function Sales() {
    const { handleSetActiveSale } = useActiveSale();

    const sales = useSelector(({ pages }) => pages.sales);

    const config = useSelector((state) => state.config.data);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
            <Header />
            <TopCategoriesMenu />
            <Container sx={{ flexGrow: 1 }}>
                <h1 className="page-title" lang="ru">
                    Акции
                </h1>
                <div className="sales-wrapper">
                    <Grid container spacing={4}>
                        {!sales.length && _isObjEmpty(config) ? (
                            <SalesListSkeleton />
                        ) : sales.length ? (
                            sales.map((sale, index) => (
                                <SaleCard
                                    sale={sale}
                                    handleSetActiveSale={handleSetActiveSale}
                                    key={sale.saleID}
                                />
                            ))
                        ) : (
                            <Grid item xs={12} md={4}>
                                <div className="sale--empty-list-placeholder">
                                    В данный момент нет активных акций.
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </div>
            </Container>
            <Footer />
        </Box>
    );
}
