import { useParams } from "react-router";

const useChoosenTown = () => {
    const params = useParams();

    if (window.townsTypeRoute !== "folder") {
        return { choosenTown: null };
    }

    const choosenTown = window.towns?.find((town) => params.town === town.slug);

    return { choosenTown };
};

export default useChoosenTown;
