import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import useTownNavigate from "../../hooks/useTownNavigate";
import TownLink from "../TownLink";
import { setTownModal } from "../../redux/actions/config";
import { setOpenModalAuth } from "../../redux/actions/user";
import { closeMobileMenu } from "../../redux/actions/header";
import { _isMobile, _getPlatform, _isAuthEnabled } from "../helpers.js";
import {
    AppBar,
    Button,
    Container,
    Step,
    Stepper,
    StepLabel,
    Toolbar,
} from "@mui/material";
import "../../css/header.css";
import MenuDrawer from "./MenuDrawer";
import HeaderTopMenu from "./HeaderTopMenu";
import clsx from "clsx";
import HeaderPhoneBlock from "./HeaderPhoneBlock";
import HeaderWorkTimeBlock from "./HeaderWorkTimeBlock";
import FullWidthHeader from "./FullWidthHeader";
import HeaderBonusesBtn from "./HeaderBonusesBtn";
import PersonIcon from "@mui/icons-material/Person";

function Header() {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { navigateTo } = useTownNavigate();

    const lastPathname =
        "/" +
        pathname
            .split("/")
            ?.filter((el) => el)
            .slice(-1)?.[0];

    const configStatus = useSelector((state) => state.config.status);
    const config = useSelector((state) => state.config.data);
    const user = useSelector((state) => state.user.user);
    const mobileMenuOpen = useSelector((state) => state.header.mobileMenuOpen);

    const headerType = config.CONFIG_type_header;

    useEffect(() => dispatch(closeMobileMenu()), [dispatch]);

    const hadleClickAccount = useCallback(() => {
        if (mobileMenuOpen) {
            dispatch(closeMobileMenu());
        }
        navigateTo("/account");
    }, [dispatch, mobileMenuOpen]);

    const openAuthModalBtnClick = () => {
        dispatch(setOpenModalAuth(true));
    };

    const handleOpenTownModal = () => {
        dispatch(setTownModal(true));
    };

    const stepperPage = ["/cart", "/checkout", "/order-complete"];
    const steps = ["Корзина", "Оформление заказа", "Заказ принят"];

    if (window.fullWidthSite) {
        return (
            <FullWidthHeader
                hadleClickAccount={hadleClickAccount}
                openAuthModalBtnClick={openAuthModalBtnClick}
                handleOpenTownModal={handleOpenTownModal}
            />
        );
    }

    return (
        <div>
            {!!window.newHeaderDesign && (
                <HeaderTopMenu hide={stepperPage.includes(lastPathname)} />
            )}

            <AppBar position="static" className="header-bar">
                <Container>
                    <Toolbar
                        className={clsx(
                            "header-wrapper",
                            headerType === "one" && "one-type",
                            headerType === "two" && "two-type"
                        )}
                    >
                        {!stepperPage.includes(lastPathname) ? (
                            <div
                                className={clsx(
                                    "standart-header",
                                    _getPlatform() === "vk" && "vk"
                                )}
                            >
                                <MenuDrawer
                                    handleOpenTownModal={handleOpenTownModal}
                                    hadleClickAccount={hadleClickAccount}
                                    openAuthModalBtnClick={
                                        openAuthModalBtnClick
                                    }
                                />

                                <div
                                    className={clsx(
                                        "header-logo-wrapper",
                                        _getPlatform() === "vk" && "vk"
                                    )}
                                >
                                    <TownLink
                                        to="/"
                                        className="header-logo-link"
                                    >
                                        <img
                                            src={
                                                window.CONFIG_design_dark_theme !==
                                                "active"
                                                    ? window.CONFIG_company_logo_main
                                                    : window.CONFIG_company_logo_footer
                                            }
                                            className="header-logo"
                                            alt="Логотип"
                                        />
                                    </TownLink>
                                </div>

                                <HeaderPhoneBlock
                                    handleOpenTownModal={handleOpenTownModal}
                                />

                                <HeaderWorkTimeBlock />

                                <div className="header--right-col">
                                    {window.newHeaderDesign &&
                                        config.bonusProgramm?.status ===
                                            "active" && <HeaderBonusesBtn />}

                                    {_isAuthEnabled(config) ? (
                                        <div className="header-login">
                                            {!user.token ? (
                                                <Button
                                                    onClick={
                                                        openAuthModalBtnClick
                                                    }
                                                    className={
                                                        window.newHeaderDesign
                                                            ? "btn--outline-dark"
                                                            : "btn--action"
                                                    }
                                                    variant="contained"
                                                    disabled={!configStatus}
                                                >
                                                    Войти
                                                </Button>
                                            ) : window.newHeaderDesign ? (
                                                <div
                                                    onClick={hadleClickAccount}
                                                    className="header--icon-btn"
                                                >
                                                    <PersonIcon className="icon-btn--icon" />
                                                    <span className="header--bonuses-title">
                                                        Мой кабинет
                                                    </span>
                                                </div>
                                            ) : (
                                                <Button
                                                    onClick={hadleClickAccount}
                                                    className={
                                                        window.newHeaderDesign
                                                            ? "btn--outline-dark"
                                                            : "btn--action"
                                                    }
                                                    variant="contained"
                                                    sx={{
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    Личный кабинет
                                                </Button>
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        ) : (
                            <div className="stepper-header">
                                <MenuDrawer
                                    handleOpenTownModal={handleOpenTownModal}
                                    hadleClickAccount={hadleClickAccount}
                                    openAuthModalBtnClick={
                                        openAuthModalBtnClick
                                    }
                                />

                                <div
                                    className={clsx(
                                        "header-logo-wrapper",
                                        _getPlatform() === "vk" && "vk"
                                    )}
                                >
                                    <TownLink
                                        to="/"
                                        className="header-logo-link"
                                    >
                                        <img
                                            src={
                                                window.CONFIG_design_dark_theme !==
                                                "active"
                                                    ? window.CONFIG_company_logo_main
                                                    : window.CONFIG_company_logo_footer
                                            }
                                            className="header-logo"
                                            alt="Логотип"
                                        />
                                    </TownLink>
                                </div>

                                <Stepper
                                    activeStep={stepperPage.indexOf(
                                        lastPathname
                                    )}
                                    alternativeLabel
                                    className="header-stepper"
                                >
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel className="step-label">
                                                {label}
                                            </StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </div>
                        )}
                    </Toolbar>
                </Container>
            </AppBar>

            {!window.newHeaderDesign && (
                <HeaderTopMenu hide={stepperPage.includes(lastPathname)} />
            )}
        </div>
    );
}

export default Header;
