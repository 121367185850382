import { _isCategoryDisabled } from "components/helpers";

export const getRecommendProducts = (state) => {
    if (!state.products.categories || !state.products.recommend_items) {
        return null;
    }

    const disabledCategoriesArr = state.products.categories.filter(
        (category) => _isCategoryDisabled(category).disabled
    );

    return state.products.recommend_items.filter((product) => {
        if (state.cart.items[product.id]) {
            return false;
        }
        if (
            product.categories.some((categoryId) =>
                disabledCategoriesArr.includes(categoryId)
            )
        ) {
            return false;
        }
        return true;
    });
};

export const getTopSalesProducts = (state) => {
    if (!state.products.categories || !state.products.top_sales_products) {
        return null;
    }

    const disabledCategoriesArr = state.products.categories.filter(
        (category) => _isCategoryDisabled(category).disabled
    );

    return state.products.top_sales_products.filter((product) => {
        if (
            product.categories.some((categoryId) =>
                disabledCategoriesArr.includes(categoryId)
            )
        ) {
            return false;
        }
        return true;
    });
};
