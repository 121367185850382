import React from "react";

import { useDispatch } from "react-redux";
import { addProductToCart, decreaseProductInCart } from "redux/actions/cart";

import { Button } from "@mui/material";

import useMetricFunctions from "hooks/useMetricFunctions";
import { _clone } from "components/helpers";

const MiniCartProductBuyBtn = ({ product, productCount }) => {
    const dispatch = useDispatch();
    const { metricAddProductToCart } = useMetricFunctions();

    const handleAddProduct = () => {
        metricAddProductToCart(product);
        dispatch(addProductToCart(product));
    };

    const handleDecreaseProduct = () => {
        dispatch(decreaseProductInCart(product));
    };

    const handleAddVariationsProduct = () => {
        const productWithoutModificators = _clone(product);

        // Уменьшаем цену продукта от цены модификаторов перед добавлением,
        // т.к. в редьюсере цена модификаторов добавится еще раз
        if (product.modificatorsAmount) {
            if (product.options._promocode_price) {
                productWithoutModificators.options._promocode_price -=
                    product.modificatorsAmount;
            }
            productWithoutModificators.options._price -=
                product.modificatorsAmount;
        }

        metricAddProductToCart(productWithoutModificators);
        dispatch(addProductToCart(productWithoutModificators));
    };

    if (product.type === "variations" || product.choosenModificators?.length) {
        return (
            <div className="minicart--product-quantity">
                <Button
                    onClick={handleAddVariationsProduct}
                    className="minicart--variations-buy"
                    // variant="contained"
                    sx={{ height: "32px" }}
                >
                    Добавить еще
                </Button>
            </div>
        );
    }

    return (
        <div className="minicart--product-quantity">
            <Button
                className="btn--default product-decrease"
                onClick={handleDecreaseProduct}
            >
                -
            </Button>
            <input
                className="quantity"
                type="text"
                readOnly
                value={productCount}
                data-product-id={product.id}
            />
            <Button
                className="btn--default product-add btn-buy"
                onClick={handleAddProduct}
            >
                +
            </Button>
        </div>
    );
};

export default MiniCartProductBuyBtn;
