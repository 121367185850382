import { useSelector } from "react-redux";

export default function PromocodeCheckoutProduct() {
    const promocode = useSelector(({ cart }) => cart.promocode);
    const promocodeProduct = useSelector(({ cart }) => cart.promocodeProducts);

    let dataAttributes = {};
    if (promocodeProduct.attributes) {
        Object.values(promocodeProduct.attributes).forEach((value, index) => {
            dataAttributes[index] = value.name;
        });
    }

    return (
        <div
            className="checkout--product"
            data-product-id={promocodeProduct.id}
        >
            <div className="checkout--product-name">
                {promocodeProduct.title}

                <div className="minicart--product-attributes">
                    {promocodeProduct.type === "variations"
                        ? Object.values(
                              promocodeProduct.variant.attributes
                          ).map((attribute, keyAttr) => (
                              <div
                                  className="minicart--product-attribute"
                                  key={keyAttr}
                              >
                                  {dataAttributes[keyAttr]}: {attribute}
                              </div>
                          ))
                        : null}

                    {promocodeProduct.type === "variations" ? (
                        promocodeProduct.variant.weight && (
                            <div className="weight">
                                {promocodeProduct.variant.weight} гр.
                            </div>
                        )
                    ) : (
                        <div>
                            {promocodeProduct.options.weight ? (
                                <div className="weight">
                                    {promocodeProduct.options.weight} гр.
                                </div>
                            ) : (
                                ""
                            )}
                            {promocodeProduct.options.count_rolls ? (
                                <div className="count-rolls">
                                    {promocodeProduct.options.count_rolls} шт.
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    )}

                    <div className="promocode-name">
                        Промокод «{promocode.code}»
                    </div>
                </div>
            </div>
            <div className="checkout--product-result">
                <span className="old-price">
                    {promocodeProduct.type === "variations" &&
                    promocodeProduct.variant
                        ? `${promocodeProduct.variant.price} ₽`
                        : `${promocodeProduct.options._price} ₽`}
                </span>
                &nbsp;
                <span className="main-color">
                    {promocodeProduct.options._promocode_price} ₽
                </span>
            </div>
        </div>
    );
}
