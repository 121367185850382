import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import useTownNavigate from "../hooks/useTownNavigate";
import axios from "axios";
import useAccrueBonuses from "../hooks/useAccrueBonuses";
import useBonuses from "../hooks/useBonuses";
import { Alert, Box, CircularProgress, Collapse } from "@mui/material";
import BonusesSlider from "./BonusesSlider";
import BootstrapTooltip from "./BootstrapTooltip";
import { _declension, _getDomain, _getPlatform } from "./helpers";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import useApiRequests from "../hooks/useApiRequests";

const CheckoutUserBonuses = ({
    usedBonuses,
    handleChangeUsedBonuses,
    typeDelivery,
    deliveryZone,
    autoDiscount,
    autoDiscountAmount,
}) => {
    const { navigateTo } = useTownNavigate();

    const { postRequestAPI } = useApiRequests();

    const user = useSelector((state) => state.user.user);
    const platformBonusProgramStatus = useSelector(
        (state) => state.config.data.bonusProgramm.status === "active"
    );
    const bonusProgrammType = useSelector(
        (state) => state.config.data.bonusProgrammType
    );

    const [userBonuses, setUserBonuses] = useState(0);
    const [loading, setLoading] = useState(
        platformBonusProgramStatus ? false : true
    );

    const { deliveryAccruedBonuses, selfDeliveryAccruedBonuses } =
        useAccrueBonuses(usedBonuses, autoDiscountAmount, autoDiscount);

    const {
        useBonusesLimit,
        maxBonuses,
        disabledByPromocode,
        disabledByBonusProduct,
        disabledByDiscountProduct,
        disabledByExcludedCategory,
        disabledByOnlyCategories,
        excludedCategoriesNamesInCart,
        specifiedCategoriesNames,
    } = useBonuses({
        userBonuses,
        typeDelivery,
        deliveryZone,
        autoDiscountAmount,
    });

    useEffect(() => {
        if (user) {
            if (platformBonusProgramStatus) {
                setUserBonuses(
                    user.bonuses > 0 || user.bonuses === 0
                        ? parseInt(user.bonuses)
                        : 0
                );
            } else {
                if (user.token && user.phone) {
                    setLoading(true);

                    let apiUrl = "";

                    if (bonusProgrammType === "frontpad") {
                        apiUrl = "getFrontpadBonuses";
                    } else if (bonusProgrammType === "sbis") {
                        apiUrl = "getSbisBonuses";
                    }

                    postRequestAPI(apiUrl, "", {
                        token: user.token,
                        phone: user.phone,
                    }).then((resp) => {
                        if (resp.data.user?.bonuses >= 0) {
                            setUserBonuses(resp.data.user?.bonuses);
                        }
                        setLoading(false);
                    });
                }
            }
        }
    }, [platformBonusProgramStatus, bonusProgrammType]);

    useEffect(() => {
        if (usedBonuses > maxBonuses) {
            handleChangeUsedBonuses(maxBonuses);
        }
    }, [maxBonuses]);

    const disableReasons = [];
    if (disabledByPromocode) {
        disableReasons.push("c примененным промокодом");
    }
    if (disabledByBonusProduct) {
        disableReasons.push("c подарками");
    }
    if (disabledByDiscountProduct) {
        disableReasons.push("c товарами по скидке");
    }
    if (disabledByExcludedCategory) {
        disableReasons.push(
            `c товарами из следующих категорий: ${excludedCategoriesNamesInCart.join(
                ", "
            )}`
        );
    }
    if (disabledByOnlyCategories) {
        disableReasons.push(
            `c товарами из других категорий кроме: ${specifiedCategoriesNames.join(
                ", "
            )}`
        );
    }

    let disableReasonString = "";
    !!disableReasons.length &&
        disableReasons.forEach((text, index, array) => {
            if (index === 0) {
                disableReasonString += text;
            } else if (index === array.length - 1) {
                disableReasonString += " и " + text;
            } else {
                disableReasonString += ", " + text;
            }
        });

    return (
        <div className="checkout--user-bonuses">
            <div className="checkout--bonuses-payming">
                <div className="checkout--payming-bonuses-info">
                    <span className="title">Оплата бонусами</span>
                    <BootstrapTooltip
                        placement="top"
                        enterTouchDelay={0}
                        title={
                            <Box sx={{ fontSize: "13px", m: "6px" }}>
                                <Box className="main-color" sx={{ mb: "6px" }}>
                                    Бонусная программа!
                                </Box>
                                {platformBonusProgramStatus ? (
                                    <Box sx={{ mb: "6px" }}>
                                        <div>Начислим кешбэк:</div>
                                        <div>
                                            <span className="main-color">
                                                +{deliveryAccruedBonuses}
                                            </span>{" "}
                                            за заказ на доставку
                                        </div>
                                        <div>
                                            <span className="main-color">
                                                +{selfDeliveryAccruedBonuses}
                                            </span>{" "}
                                            за заказ навынос
                                        </div>
                                    </Box>
                                ) : null}
                                <Box sx={{ mb: "6px" }}>
                                    Бонусами можно оплатить до{" "}
                                    <span className="main-color">
                                        {useBonusesLimit}%
                                    </span>{" "}
                                    от суммы заказа!
                                </Box>
                                {!platformBonusProgramStatus ? (
                                    <Box sx={{ mb: "6px" }}>
                                        Бонусы обновляются 1 раз в 15 минут
                                    </Box>
                                ) : null}
                                {platformBonusProgramStatus ? (
                                    <Box
                                        onClick={() => {
                                            navigateTo("/bonuses");
                                        }}
                                        sx={{
                                            color: "var(--main-color)",
                                            cursor: "pointer",
                                            width: "fit-content",
                                            textDecoration: "underline",
                                        }}
                                    >
                                        Подробнее о бонусах
                                    </Box>
                                ) : null}
                            </Box>
                        }
                    >
                        <InfoOutlinedIcon className="checkout--info-icon" />
                    </BootstrapTooltip>
                </div>
                {loading ? (
                    <CircularProgress size={20} />
                ) : (
                    <span className="bonuses-price">
                        <span className="money">
                            {usedBonuses.toLocaleString("ru-RU")}
                        </span>{" "}
                        &#8381;
                    </span>
                )}
            </div>

            <Collapse
                in={
                    !!autoDiscount &&
                    !!autoDiscountAmount &&
                    !autoDiscount.allowUserBonuses
                }
            >
                <Alert severity="error" sx={{ mb: "10px" }}>
                    Списание бонусов отменит действие скидки «
                    {autoDiscount?.name}»
                </Alert>
            </Collapse>

            <BonusesSlider
                disabled={
                    disabledByPromocode ||
                    disabledByBonusProduct ||
                    disabledByDiscountProduct ||
                    disabledByExcludedCategory ||
                    disabledByOnlyCategories ||
                    maxBonuses === 0
                }
                maxValue={maxBonuses}
                handleChangeUsedBonuses={handleChangeUsedBonuses}
                usedBonuses={usedBonuses}
            />
            {disableReasons.length ? (
                <Alert severity="error" sx={{ mb: "10px" }}>
                    Оплата бонусами недоступна {disableReasonString}.
                </Alert>
            ) : null}
        </div>
    );
};

export default CheckoutUserBonuses;
