import { useSelector } from "react-redux";
import useTownNavigate from "../../hooks/useTownNavigate";
import { Button, Grid } from "@mui/material";
import clsx from "clsx";
import { _declension } from "components/helpers";

const UserBonusesInfo = ({ borderType }) => {
    const { navigateTo } = useTownNavigate();

    const user = useSelector((state) => state.user.user);

    const { useBonusesLimit } = useSelector(
        ({
            config: {
                data: { bonusProgramm },
            },
        }) => {
            return {
                useBonusesLimit: bonusProgramm.paymentPercent,
            };
        }
    );

    const handleBackToMenu = () => {
        window.scrollTo(0, 0);
        navigateTo("/");
    };

    const userBonusesAmount = user.bonuses ? parseInt(user.bonuses) : 0;

    return (
        <Grid item xs={12} sm={6} desctop={12}>
            <div
                className={clsx(
                    "user-bonuses-info",
                    borderType === "solid" && "solid"
                )}
            >
                <div>
                    Бонусами можно оплатить до {useBonusesLimit}% от общей суммы
                    заказа
                </div>
                <div className="user-bonuses-info--exchange">
                    <span>1 бонус = </span>
                    <span style={{ whiteSpace: "nowrap" }}>1 рубль</span>
                </div>
                {user.token ? (
                    <>
                        <div className="user-bonuses-info--user-bonuses-title">
                            У вас на счету
                        </div>
                        <div className="user-bonuses-info--user-bonuses">
                            <span className="main-color">
                                {userBonusesAmount.toLocaleString("ru-RU")}
                            </span>{" "}
                            {_declension(userBonusesAmount, [
                                "бонус",
                                "бонуса",
                                "бонусов",
                            ])}
                        </div>
                    </>
                ) : null}
                <Button
                    onClick={handleBackToMenu}
                    variant="contained"
                    className="btn--action"
                    sx={{ mt: "20px", width: "100%", whiteSpace: "nowrap" }}
                >
                    Перейти в меню
                </Button>
            </div>
        </Grid>
    );
};

export default UserBonusesInfo;
