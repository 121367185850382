import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { ErrorBoundary } from "./components";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./redux/store";
import { Provider } from "react-redux";
import bridge from "@vkontakte/vk-bridge";
import { _getPlatform, ScrollToTop } from "./components/helpers";
import AppRouter from "./routes/AppRouter";
import StyledGlobalTheme from "theme/StyledGlobalTheme";
import MaterialThemeProvider from "theme/MaterialThemeProvider";

bridge.send("VKWebAppInit");

window.ReactNativeWebView &&
    window.ReactNativeWebView.postMessage("our-mobile-app-check");

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <MaterialThemeProvider>
                <StyledGlobalTheme />
                <ErrorBoundary>
                    <Provider store={store}>
                        <ScrollToTop />
                        <AppRouter />
                    </Provider>
                </ErrorBoundary>
            </MaterialThemeProvider>
        </Router>
    </React.StrictMode>,
    document.getElementById("root")
);
