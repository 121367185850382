import { Skeleton } from "@mui/material";

const HeaderWorkTimeSkeleton = () => {
    return (
        <div className="info-wrapper">
            <div className="title">
                <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{
                        width: 120,
                        height: 20,
                    }}
                />
            </div>
            <div className="info">
                <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ width: 150, height: 24, transform: "scale(1, 0.80)" }}
                />
            </div>
        </div>
    );
};

export default HeaderWorkTimeSkeleton;
