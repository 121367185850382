const initialState = {
    items: [],
    addon_items: [],
    recommend_items: [],
    top_sales_products: [],
    bonuses_items: [],
    categories: [],
    productsIsLoading: false,
    categoriesIsLoading: false,
    status: false,
};

const products = (state = initialState, action) => {
    switch (action.type) {
        case "SET_PRODUCTS_STATUS":
            return {
                ...state,
                status: action.payload,
            };
        case "SET_PRODUCTS":
            return {
                ...state,
                items: action.payload,
                productsIsLoading: true,
            };
        case "SET_ADDON_PRODUCTS":
            return {
                ...state,
                addon_items: action.payload,
            };
        case "SET_RECOMMEND_PRODUCTS":
            return {
                ...state,
                recommend_items: action.payload,
            };
        case "SET_TOP_SALES_PRODUCTS":
            return {
                ...state,
                top_sales_products: action.payload,
            };
        case "SET_BONUSES_PRODUCTS":
            return {
                ...state,
                bonuses_items: action.payload,
            };
        case "SET_CATEGORIES":
            return {
                ...state,
                categories: action.payload,
                categoriesIsLoading: true,
            };
        case "CLEAN_STORE": {
            return { ...initialState };
        }
        default:
            return state;
    }
};

export default products;
