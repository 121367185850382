import { useSelector } from "react-redux";

const AdditionalPaymentInfo = () => {
    const productCategories = useSelector((state) => state.products.categories);
    const {
        specifiedСategories,
        excludeCategories,
        disableMinPrice,
        allowWithPromocode,
        allowWithBonusProduct,
        allowWithDiscountProducts,
    } = useSelector(
        ({
            config: {
                data: { bonusProgramm },
            },
        }) => {
            return {
                specifiedСategories: bonusProgramm.paymentCategories,
                excludeCategories:
                    bonusProgramm.paymentExcludeCategories === "yes",
                disableMinPrice:
                    bonusProgramm.paymentIgnoreMinimalPrice === "active",
                allowWithPromocode:
                    bonusProgramm.paymentDisableWithPromocode !== "active",
                allowWithBonusProduct:
                    bonusProgramm.paymentDisableWithBonusProduct !== "active",
                allowWithDiscountProducts:
                    bonusProgramm.paymentDisableWithSaleProduct !== "active",
            };
        }
    );

    if (
        disableMinPrice &&
        !specifiedСategories.length &&
        allowWithPromocode &&
        allowWithBonusProduct &&
        allowWithDiscountProducts
    ) {
        return null;
    }

    const specifiedСategoriesNames = [];
    if (specifiedСategories.length) {
        specifiedСategories.forEach((specifiedСategoryId) => {
            const category = productCategories.find(
                (productCategory) =>
                    productCategory.term_id === specifiedСategoryId
            );
            if (category) {
                specifiedСategoriesNames.push(`«${category.name}»`);
            }
        });
    }

    const disableReasons = [];
    if (!allowWithPromocode) {
        disableReasons.push("c примененным промокодом");
    }
    if (!allowWithBonusProduct) {
        disableReasons.push("c подарками");
    }
    if (!allowWithDiscountProducts) {
        disableReasons.push("c товарами по скидке");
    }
    if (specifiedСategoriesNames.length && excludeCategories) {
        disableReasons.push(
            `c товарами из следующих категорий: ${specifiedСategoriesNames.join(
                ", "
            )}`
        );
    }
    if (specifiedСategoriesNames.length && !excludeCategories) {
        disableReasons.push(
            `c товарами из других категорий кроме: ${specifiedСategoriesNames.join(
                ", "
            )}`
        );
    }

    let disableReasonString = "";
    !!disableReasons.length &&
        disableReasons.forEach((text, index, array) => {
            if (index === 0) {
                disableReasonString += text;
            } else if (index === array.length - 1) {
                disableReasonString += " и " + text;
            } else {
                disableReasonString += ", " + text;
            }
        });

    return (
        <div className="bonus-page--payment-info">
            <h3>Условия списания</h3>
            {!disableMinPrice ? (
                <div>
                    Бонусами можно оплатить только сумму свыше минимального
                    заказа.
                </div>
            ) : null}
            {disableReasons.length ? (
                <div>Оплата бонусами недоступна {disableReasonString}.</div>
            ) : null}
        </div>
    );
};

export default AdditionalPaymentInfo;
