import axios from "axios";
import useChoosenTown from "./useChoosenTown";
import { _getDomain, _getPlatform } from "../components/helpers";

const useApiRequests = () => {
    const { choosenTown } = useChoosenTown();

    const baseURL = choosenTown ? choosenTown.url : "https://" + _getDomain();

    let baseParams = "&platform=" + _getPlatform();

    if (choosenTown) {
        baseParams += "&townId=" + choosenTown.id;
    }

    const getRequestAPI = (apiMethod, queryParams, options) => {
        let apiURL = baseURL + "/?rest-api=" + apiMethod + baseParams;

        if (queryParams) {
            apiURL += queryParams;
        }

        return axios.get(apiURL, options);
    };

    const postRequestAPI = (apiMethod, queryParams, data, options) => {
        let apiURL = baseURL + "/?rest-api=" + apiMethod + baseParams;

        if (queryParams) {
            apiURL += queryParams;
        }

        return axios.post(apiURL, data, options);
    };

    return { getRequestAPI, postRequestAPI };
};

export default useApiRequests;
