import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
    Autocomplete,
    Alert,
    Button,
    Grid,
    Popper,
    TextField,
} from "@mui/material";
import axios from "axios";
import { _getDomain, _isMobile } from "./helpers";

const CustomPopper = (props) => {
    return (
        <Popper
            sx={{
                "& .MuiPaper-root": {
                    borderRadius: "12px",
                    mt: "4px",
                    mb: "8px",
                },
            }}
            {...props}
        />
    );
};

const CheckoutNewAddressForm = ({
    streetProps,
    homeProps,
    newUserAddressStreet,
    newUserAddressHome,
    newUserAddressPorch,
    newUserAddressFloor,
    newUserAddressApartment,
    handleChangeNewUserAddress,
    iikoStreets,
    iikoError,
}) => {
    const config = useSelector(({ config }) => config.data);
    const isIikoStreetsActive = useSelector(
        ({ config }) => config.data.useStreetsAutoComplete === "active"
    );

    return (
        <div className="checkout--form-new-address">
            {iikoError && isIikoStreetsActive ? (
                <Alert
                    severity="error"
                    sx={{
                        mb: 2,
                        "& .MuiAlert-action": {
                            flexDirection: "column",
                            justifyContent: "center",
                        },
                    }}
                    action={
                        _isMobile() ? null : (
                            <Button
                                variant="button"
                                className=" btn--action"
                                onClick={() => window.location.reload()}
                            >
                                Обновить
                            </Button>
                        )
                    }
                >
                    <div>
                        Не удалось получить данные улиц, нажмите кнопку
                        «Обновить».
                    </div>
                    {_isMobile() ? (
                        <Button
                            variant="button"
                            className=" btn--action"
                            onClick={() => window.location.reload()}
                            sx={{
                                width: "100%",
                                mt: "8px",
                            }}
                        >
                            Обновить
                        </Button>
                    ) : null}
                </Alert>
            ) : null}

            <Grid container spacing={2}>
                <Grid
                    item
                    xs={8}
                    md={6}
                    sx={{
                        width: 1,
                    }}
                >
                    {isIikoStreetsActive ? (
                        <Autocomplete
                            options={iikoStreets}
                            clearText="Очистить"
                            openText="Раскрыть"
                            closeText="Свернуть"
                            noOptionsText="Улица не найдена"
                            disabled={iikoError}
                            value={
                                newUserAddressStreet
                                    ? newUserAddressStreet
                                    : null
                            }
                            onChange={(event, value) => {
                                if (value) {
                                    handleChangeNewUserAddress(value, "street");
                                } else {
                                    handleChangeNewUserAddress("", "street");
                                }
                            }}
                            id="street"
                            PopperComponent={CustomPopper}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Улица"
                                    size="small"
                                    {...streetProps}
                                />
                            )}
                            renderOption={(props, option, index) => {
                                return (
                                    <li {...props} key={props.id}>
                                        {option}
                                    </li>
                                );
                            }}
                        />
                    ) : (
                        <TextField
                            size="small"
                            id="street"
                            label="Улица"
                            value={newUserAddressStreet}
                            onChange={(event) => {
                                handleChangeNewUserAddress(
                                    event.target.value,
                                    "street"
                                );
                            }}
                            sx={{
                                width: 1,
                            }}
                            {...streetProps}
                        />
                    )}
                </Grid>
                <Grid
                    item
                    xs={4}
                    md={6}
                    sx={{
                        width: 1,
                    }}
                >
                    <TextField
                        size="small"
                        id="home"
                        label="Дом"
                        value={newUserAddressHome}
                        onChange={(event) => {
                            handleChangeNewUserAddress(
                                event.target.value,
                                "home"
                            );
                        }}
                        sx={{
                            width: 1,
                        }}
                        {...homeProps}
                    />
                </Grid>
                {config.CONFIG_checkout_hide_porch === "yes" ? null : (
                    <Grid item xs={4} md={4}>
                        <TextField
                            size="small"
                            id="porch"
                            label="Подъезд"
                            value={newUserAddressPorch}
                            onChange={(event) => {
                                handleChangeNewUserAddress(
                                    event.target.value,
                                    "porch"
                                );
                            }}
                            sx={{
                                width: 1,
                            }}
                        />
                    </Grid>
                )}
                {config.CONFIG_checkout_hide_floor === "yes" ? null : (
                    <Grid item xs={4} md={4}>
                        <TextField
                            size="small"
                            id="floor"
                            label="Этаж"
                            value={newUserAddressFloor}
                            onChange={(event) => {
                                handleChangeNewUserAddress(
                                    event.target.value,
                                    "floor"
                                );
                            }}
                            sx={{
                                width: 1,
                            }}
                        />
                    </Grid>
                )}
                {config.CONFIG_checkout_hide_apartment === "yes" ? null : (
                    <Grid item xs={4} md={4}>
                        <TextField
                            size="small"
                            id="apartment"
                            label="Кв./Офис"
                            value={newUserAddressApartment}
                            onChange={(event) => {
                                handleChangeNewUserAddress(
                                    event.target.value,
                                    "apartment"
                                );
                            }}
                            sx={{
                                width: 1,
                            }}
                        />
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default CheckoutNewAddressForm;
