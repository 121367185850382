import { useState, useEffect, useRef } from "react";

const useContentOverflow = () => {
    const containerRef = useRef(null);
    const contentRef = useRef(null);

    const [contentOverflow, setContentOverflow] = useState(false);

    useEffect(() => {
        const descriptionContainerHeight = containerRef.current?.offsetHeight;
        const descriptionHeight = contentRef.current?.scrollHeight;

        if (
            descriptionContainerHeight &&
            descriptionHeight &&
            descriptionHeight > descriptionContainerHeight
        ) {
            setContentOverflow(true);
        }
    }, []);

    return { contentOverflow, containerRef, contentRef };
};

export default useContentOverflow;
