import React, { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { setOpenBonusesModal } from "../redux/actions/bonusesProductsModal";
import { addBonusProductToCart } from "../redux/actions/cart";
import {
    Alert,
    Container,
    Dialog,
    Divider,
    Grid,
    IconButton,
    Slide,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FooterBonusesGift from "../img/footer-bonuses-gift.svg";
import { _isMobile } from "./helpers";
import "../css/footer-bonuses.css";
import useBonusProducts from "../hooks/useBonusProducts";
// import BonusProductModal from "./BonusProductModal";
import ModalBonusProduct from "./ModalBonusProduct";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function BonusesProductsModal() {
    const dispatch = useDispatch();

    const { bonuses_items, openBonusesProductsModal } = useSelector(
        ({ products, bonusesProductsModal }) => {
            return {
                bonuses_items: products.bonuses_items,
                openBonusesProductsModal:
                    bonusesProductsModal.openBonusesProductsModal,
            };
        },
        shallowEqual
    );

    const closeModalByCross = useSelector(
        (state) => state.config.data.CONFIG_close_modal_by_cross
    );

    const {
        cartTotalPrice,
        bonusesHardmode,
        disabledCategories,
        disabledCategoriesNames,
        bonusesDisabled,
        bonusesDisabledByPromocode,
    } = useBonusProducts();

    useEffect(() => {
        if (bonusesDisabled && openBonusesProductsModal) {
            dispatch(setOpenBonusesModal(false));
        }
    }, [bonusesDisabled]);

    if (!openBonusesProductsModal && window.location.hash === "bonuses-modal") {
        window.history.replaceState(
            "",
            document.title,
            window.location.pathname
        );
    }

    const hashEventListener = (event) => {
        if (!window.location.hash && event.oldURL.includes("#bonuses-modal")) {
            handleClose();
        }
    };

    useEffect(() => {
        window.addEventListener("hashchange", hashEventListener);
        return () => {
            window.removeEventListener("hashchange", hashEventListener);
        };
    }, []);

    const handleClose = () => {
        if (window.location.hash === "#bonuses-modal") {
            window.history.replaceState(
                "",
                document.title,
                window.location.pathname
            );
        }
        dispatch(setOpenBonusesModal(false));
    };

    const handleBonusesHandler = () => {
        window.location.hash = "bonuses-modal";
        dispatch(setOpenBonusesModal(true));
    };

    let maxBonusesPrice = 0;
    if (bonuses_items.length)
        bonuses_items.forEach((element) => {
            if (element.limit > maxBonusesPrice)
                maxBonusesPrice = element.limit;
        });

    let dialogProps = {
        open: openBonusesProductsModal,
        maxWidth: "md",
    };
    if (_isMobile()) {
        dialogProps.TransitionComponent = Transition;
        dialogProps.fullScreen = true;
        dialogProps.scroll = "body";
    }

    return (
        <div>
            <Slide
                direction="up"
                in={!bonusesDisabled}
                mountOnEnter
                unmountOnExit
            >
                <div className="footer-bonuses">
                    <Container className="footer-bonuses__container">
                        <div
                            className="footer-bonuses__info"
                            onClick={handleBonusesHandler}
                        >
                            Выбери подарок
                            <img
                                src={FooterBonusesGift}
                                alt={"FooterBonusesGift"}
                                style={{ marginLeft: 10 }}
                            />
                        </div>

                        <Divider
                            orientation="vertical"
                            sx={{
                                borderColor: "#fff",
                                height: "40px",
                                borderWidth: "1px",
                            }}
                        />

                        <div className="footer-bonuses__points">
                            <div
                                className="footer-bonuses__points-bg"
                                style={{
                                    width: `${
                                        cartTotalPrice
                                            ? (cartTotalPrice /
                                                  maxBonusesPrice) *
                                              100
                                            : 0
                                    }%`,
                                }}
                            ></div>
                            {bonuses_items.map((item) => (
                                <div
                                    key={item.id}
                                    className="footer-bonuses__point"
                                    style={{
                                        left: `${
                                            (item.limit / maxBonusesPrice) * 100
                                        }%`,
                                    }}
                                >
                                    <div className="footer-bonuses__point-block">
                                        {item.limit}
                                        <span className="footer-bonuses__point-valute">
                                            ₽
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Container>
                </div>
            </Slide>

            <Dialog
                maxWidth="md"
                fullWidth
                onClose={(event, reason) => {
                    if (reason === "escapeKeyDown") {
                        handleClose();
                    }
                    if (
                        reason === "backdropClick" &&
                        closeModalByCross !== "active"
                    ) {
                        handleClose();
                    }
                }}
                {...dialogProps}
                className="bonuses-modal--dialog"
                sx={{
                    "& .MuiDialog-paper": {
                        borderRadius: _isMobile() ? "0px" : "20px",
                    },
                }}
            >
                <div className="modal-alert--wrapper bonuses-modal--wrapper">
                    <div className="bonuses-modal--inner-wrapper">
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            className="modal-close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <h2 className="modal-alert--title">Выберите подарок</h2>

                        <Container disableGutters={!_isMobile()}>
                            {bonusesDisabledByPromocode ? (
                                <Alert severity="error" sx={{ my: 2 }}>
                                    Бонусные товары нельзя выбрать при
                                    использовании промокода.
                                </Alert>
                            ) : null}
                            {bonusesHardmode && disabledCategories?.length ? (
                                <Alert severity="error" sx={{ my: 2 }}>
                                    Товары из категории:{" "}
                                    {disabledCategoriesNames.join(", ")} нельзя
                                    использовать вместе со шкалой подарков.
                                </Alert>
                            ) : null}
                            {!bonusesHardmode && disabledCategories?.length ? (
                                <Alert severity="error" sx={{ my: 2 }}>
                                    Товары из категории:{" "}
                                    {disabledCategoriesNames.join(", ")} не
                                    участвуют в шкале подарков.
                                </Alert>
                            ) : null}
                            <div className="bonuses-modal__carts">
                                <Grid container spacing={1}>
                                    {bonuses_items?.length &&
                                        bonuses_items.map((item, index) => (
                                            <Grid
                                                key={item.id}
                                                item
                                                sm={12}
                                                md={4}
                                                sx={{ width: 1 }}
                                            >
                                                <ModalBonusProduct
                                                    bonusProduct={item}
                                                    handleClose={handleClose}
                                                    key={`${index}_${item.title}`}
                                                />
                                                {_isMobile() &&
                                                index !==
                                                    bonuses_items.length - 1 ? (
                                                    <Divider
                                                        sx={{
                                                            borderColor:
                                                                "var(--divider-color)",
                                                        }}
                                                    />
                                                ) : null}
                                            </Grid>
                                        ))}
                                </Grid>
                            </div>
                        </Container>
                    </div>
                </div>
            </Dialog>
            {/* <BonusProductModal handleCloseBonusesModal={handleClose} /> */}
        </div>
    );
}
