import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    addPromocode,
    clearConditionalPromocode,
    completelyRemovePromocode,
    removePromocode,
    setConditionalPromocode,
} from "../redux/actions/cart";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    Alert,
    AlertTitle,
    Collapse,
    IconButton,
    TextField,
    InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import CheckIcon from "@mui/icons-material/Check";
import {
    _checkPromocode,
    _getDomain,
    _getPlatform,
    _isObjEmpty,
} from "./helpers.js";
import { updateAlerts } from "../redux/actions/systemAlerts";
import "../css/promocode.css";
import PromocodeErrorsAlert from "./PromocodeErrorsAlert";
import useApiRequests from "../hooks/useApiRequests";
import usePromocode from "hooks/usePromocode";

export default function Promocode({
    ignoreMinPrice = false,
    typeDelivery,
    preorderDate,
    preorderTime,
}) {
    const {
        handleApplyPromocode,
        handleRemovePromocode,
        loading,
        alertMessage,
        handleCloseAlertMessage,
    } = usePromocode();

    const {
        config,
        cartPromocode,
        conditionalPromocode,
        userCartBonusProduct,
        allowPromocodeWithBonusProduct,
    } = useSelector(({ cart, config }) => {
        return {
            config: config.data,
            allowPromocodeWithBonusProduct:
                config.data.CONFIG_promocode_with_bonus_program === "on",
            cartPromocode: cart.promocode,
            conditionalPromocode: cart.conditionalPromocode,
            cartTotal: cart.totalPrice,
            userCartBonusProduct: cart.bonusProduct,
        };
    });

    const [promocode, setPromocode] = React.useState(
        cartPromocode ? cartPromocode.code : ""
    );

    const handleChangePromocode = (e) => {
        setPromocode(e.target.value);
    };

    const onRemovePromocode = () => {
        setPromocode("");
        handleRemovePromocode();
    };

    return (
        <div className="promocode-wrapper">
            <div className="promocode--input-wrapper">
                <TextField
                    size="small"
                    id="promocode"
                    label="Промокод"
                    onInput={handleChangePromocode}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            handleApplyPromocode(promocode);
                        }
                    }}
                    value={
                        promocode ||
                        cartPromocode?.code ||
                        conditionalPromocode?.code ||
                        ""
                    }
                    disabled={
                        (cartPromocode.code !== undefined &&
                            !!cartPromocode.code) ||
                        !!conditionalPromocode?.code ||
                        (!!userCartBonusProduct.id &&
                            !allowPromocodeWithBonusProduct)
                    }
                    InputProps={
                        conditionalPromocode?.code || cartPromocode.code
                            ? {
                                  startAdornment: (
                                      <InputAdornment position="start">
                                          {conditionalPromocode?.code ? (
                                              <WarningIcon color="error" />
                                          ) : cartPromocode.code ? (
                                              <CheckIcon color="success" />
                                          ) : null}
                                      </InputAdornment>
                                  ),
                              }
                            : {}
                    }
                />
                {(cartPromocode.code !== undefined && cartPromocode.code) ||
                conditionalPromocode?.code ? (
                    <LoadingButton
                        loading={loading}
                        size="small"
                        variant="button"
                        className="btn--action promocode-button"
                        onClick={onRemovePromocode}
                    >
                        Отменить
                    </LoadingButton>
                ) : (
                    <LoadingButton
                        loading={loading || _isObjEmpty(config)}
                        size="small"
                        variant="button"
                        disabled={
                            !!userCartBonusProduct.id &&
                            !allowPromocodeWithBonusProduct
                        }
                        className="btn--action promocode-button"
                        onClick={() => handleApplyPromocode(promocode)}
                    >
                        Применить
                    </LoadingButton>
                )}
            </div>

            <PromocodeErrorsAlert
                ignoreMinPrice={ignoreMinPrice}
                typeDelivery={typeDelivery}
                preorderDate={preorderDate}
                preorderTime={preorderTime}
            />

            {Object.keys(userCartBonusProduct).length &&
            !allowPromocodeWithBonusProduct ? (
                <Alert severity="error" sx={{ mt: 2 }}>
                    Промокоды нельзя применять с подарками.
                </Alert>
            ) : (
                ""
            )}
            {cartPromocode.description !== undefined &&
                cartPromocode.description && (
                    <Collapse sx={{ mt: 1 }} in={true}>
                        <Alert severity="success">
                            <AlertTitle>Акция активирована!</AlertTitle>
                            {cartPromocode.description}
                        </Alert>
                    </Collapse>
                )}

            <Collapse sx={{ mt: !!alertMessage ? 1 : 0 }} in={!!alertMessage}>
                <Alert
                    severity={"error"}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={handleCloseAlertMessage}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: ignoreMinPrice ? 0 : 2 }}
                >
                    {alertMessage}
                </Alert>
            </Collapse>
        </div>
    );
}
