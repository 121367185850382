import React, { useState, useEffect } from "react";
import useTownNavigate from "hooks/useTownNavigate";
import CurrencyRubleIcon from "@mui/icons-material/CurrencyRuble";
import { useSelector } from "react-redux";

const HeaderBonusesBtn = () => {
    const { navigateTo } = useTownNavigate();

    const user = useSelector((state) => state.user.user);

    return (
        <div
            onClick={() => {
                navigateTo("/bonuses");
            }}
            className="header--icon-btn"
        >
            <CurrencyRubleIcon className="icon-btn--icon" />
            <span className="header--bonuses-title">Бонусы</span>
            {user.token ? (
                <span className="header--bonuses-amount">
                    {user.bonuses
                        ? parseInt(user.bonuses).toLocaleString("ru-RU")
                        : 0}
                </span>
            ) : null}
        </div>
    );
};

export default HeaderBonusesBtn;
