import React, { useState, useEffect } from "react";
import { Helmet, helmet } from "react-helmet";
import { useSelector } from "react-redux";

const MetrikScripts = () => {
    const parser = new DOMParser();

    const yaMetrikaScript = useSelector(
        (state) => state.config.data.CONFIG_code_metrika
    );

    const googleMetrika = useSelector(
        (state) => state.config.data.CONFIG_code_google_analytics
    );

    const vkPixel = useSelector(
        (state) => state.config.data.CONFIG_code_vk_pixel
    );

    const fbPixel = useSelector(
        (state) => state.config.data.CONFIG_code_fb_pixel
    );

    const tiktokPixel = useSelector(
        (state) => state.config.data.CONFIG_code_tiktok_pixel
    );

    if (
        !yaMetrikaScript &&
        !googleMetrika &&
        !vkPixel &&
        !fbPixel &&
        !tiktokPixel
    ) {
        return null;
    }

    const yaMetrikaScriptElement = parser.parseFromString(
        yaMetrikaScript,
        "text/html"
    );

    const googleMetrikaScriptElement = parser.parseFromString(
        googleMetrika,
        "text/html"
    );

    const vkPixelScriptElement = parser.parseFromString(vkPixel, "text/html");

    const fbPixelScriptElement = parser.parseFromString(fbPixel, "text/html");

    const tiktokPixelScriptElement = parser.parseFromString(
        tiktokPixel,
        "text/html"
    );

    const scripts = [];
    const noscripts = [];
    const scriptParser = (element) => {
        element.querySelectorAll("script").forEach((script) => {
            scripts.push({
                ...(script.src && { src: script.src }),
                ...(script.type && { type: script.type }),
                ...(script.async && { async: script.async }),
                ...(script.innerHTML && { innerHTML: script.innerHTML }),
            });
        });
        element.querySelectorAll("body").forEach((noscript) => {
            noscripts.push({
                ...(noscript.innerHTML && {
                    innerHTML: noscript.innerHTML,
                }),
            });
        });
    };

    if (yaMetrikaScriptElement) {
        scriptParser(yaMetrikaScriptElement);
    }

    if (googleMetrikaScriptElement) {
        scriptParser(googleMetrikaScriptElement);
    }

    if (vkPixelScriptElement) {
        scriptParser(vkPixelScriptElement);
    }

    if (fbPixelScriptElement) {
        scriptParser(fbPixelScriptElement);
    }

    if (tiktokPixelScriptElement) {
        scriptParser(tiktokPixelScriptElement);
    }

    return <Helmet async={true} script={scripts} noscript={noscripts}></Helmet>;
};

export default MetrikScripts;
