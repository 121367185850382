import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { getDay } from "date-fns";
import { useSelector } from "react-redux";

const CheckoutInRestorauntAddresses = ({
    selfDeliveryAddress,
    handleChooseSelfDeliveryAddress,
}) => {
    const config = useSelector((state) => state.config.data);

    // Функция рендера графика работы филиала
    const currentDayOfWeek =
        getDay(new Date()) === 0 ? 6 : getDay(new Date()) - 1;

    const renderFilialLabel = (filial) => {
        return (
            <div>
                <span>{filial.address}</span>
                {
                    // Если у филиала свой график работы
                    filial.workingTime ? (
                        filial.workingTime[currentDayOfWeek][0] &&
                        filial.workingTime[currentDayOfWeek][1] ? (
                            <div className="adress-schdedule">
                                <span>Сегодня с</span>{" "}
                                {filial.workingTime[currentDayOfWeek][0]} до{" "}
                                {filial.workingTime[currentDayOfWeek][1]}
                            </div>
                        ) : (
                            <div>
                                <span>Сегодня закрыто</span>
                            </div>
                        )
                    ) : // Если график работы филиала совпадает с основным
                    config.CONFIG_format_start_work &&
                      config.CONFIG_format_end_work ? (
                        <div className="adress-schdedule">
                            <span>Сегодня с</span>{" "}
                            {config.CONFIG_format_start_work} до{" "}
                            {config.CONFIG_format_end_work}
                        </div>
                    ) : (
                        <div>
                            <span>Сегодня закрыто</span>
                        </div>
                    )
                }
            </div>
        );
    };

    return (
        <div className="checkout--self-address-panel">
            <h4>Выберите адрес</h4>
            <RadioGroup
                value={selfDeliveryAddress}
                aria-labelledby="selfDeliveryAddress-label"
                name="selfDeliveryAddress"
                onChange={handleChooseSelfDeliveryAddress}
                sx={{
                    mb: 2,
                    "& .MuiFormControlLabel-root": {
                        alignItems: "start",
                    },
                }}
            >
                <FormControlLabel
                    className="custom-radio"
                    value="main"
                    control={<Radio size="small" />}
                    label={
                        <div>
                            <span>{config.CONFIG_address}</span>
                            {config.CONFIG_format_start_work &&
                            config.CONFIG_format_end_work ? (
                                <div>
                                    <div className="adress-schdedule">
                                        <span>Сегодня с</span>{" "}
                                        {config.CONFIG_format_start_work} до{" "}
                                        {config.CONFIG_format_end_work}
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <span>Сегодня закрыто</span>
                                </div>
                            )}
                        </div>
                    }
                />
                {config.CONFIG_filials &&
                    config.CONFIG_filials.map((filial, index) => (
                        <FormControlLabel
                            key={index}
                            className="custom-radio"
                            value={index}
                            control={<Radio size="small" />}
                            label={renderFilialLabel(filial)}
                        />
                    ))}
            </RadioGroup>
        </div>
    );
};

export default CheckoutInRestorauntAddresses;
