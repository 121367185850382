import { Box, Container } from "@mui/material";
import { Footer, Header, TownLink } from "../components";
import { ReactComponent as CatErrorSvg } from "../img/cat-error.svg";
import "../css/order-failed-page.css";

const OrderFailed = () => {
    const customOrderFailedImg = window.CONFIG_order_failed_image;

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
            }}
        >
            <Header />
            <Container sx={{ flexGrow: 1 }}>
                <h1 className="page-title" lang="ru">
                    Ошибка оформления заказа
                </h1>
                <div className="order-failed-page">
                    <div className="order-failed--logo-container">
                        <div className="order-failed--logo-inner-container">
                            {customOrderFailedImg ? (
                                <img
                                    src={customOrderFailedImg}
                                    className="order-failed--logo"
                                    alt="Ошибка оформления заказа"
                                />
                            ) : (
                                <CatErrorSvg className="order-failed--logo" />
                            )}
                        </div>
                    </div>

                    <div className="order-failed-page--content-container">
                        <p className="order-failed-page--content">
                            Во время оформления заказа произошла ошибка.
                            Попробуйте еще раз.
                        </p>
                        <TownLink
                            className="btn btn--action"
                            to="/"
                            style={{ marginTop: "15px" }}
                        >
                            На главную страницу
                        </TownLink>
                    </div>
                </div>
            </Container>
            <Footer />
        </Box>
    );
};

export default OrderFailed;
