import { Grid } from "@mui/material";

const AdditionalBonusesInfo = () => {
    return (
        <div className="bonus-page--additional-info-container">
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} mobilelg={6}>
                    <div className="bonus-page--additional-info-block">
                        <h3>Получайте кешбэк с каждым заказом</h3>
                        <span>
                            Бонусы начисляются автоматически с каждым заказом.
                            Баланс можно посмотреть в личном кабинете.
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} mobilelg={6}>
                    <div className="bonus-page--additional-info-block">
                        <h3>Меняйте бонусы на товары</h3>
                        <span>
                            Добавляйте блюда в корзину и списывайте бонусы при
                            оформлении заказа.
                        </span>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default AdditionalBonusesInfo;
