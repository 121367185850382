import { useSelector } from "react-redux";
import {
    _checkAutoDiscount,
    checkProductForAutoDiscount,
} from "../components/helpers";

const useAutoDiscount = ({ typeDelivery, preorderDate, usedBonuses }) => {
    const autoDiscounts = useSelector(
        (state) => state.autoDiscounts.autoDiscounts
    );
    const cartProducts = useSelector((state) => state.cart.items);
    const promocode = useSelector((state) => state.cart.promocode);
    const bonusProduct = useSelector((state) => state.cart.bonusProduct);
    const cartSubTotal = useSelector((state) => state.cart.subTotalPrice);
    const cartTotal = useSelector((state) => state.cart.totalPrice);

    let autoDiscount = null;
    let autoDiscountAmount = 0;

    if (autoDiscounts && autoDiscounts.length) {
        autoDiscounts.forEach((discount) => {
            if (
                !autoDiscount &&
                _checkAutoDiscount({
                    discount,
                    cartProducts,
                    cartTotal: cartSubTotal,
                    promocode,
                    bonusProduct,
                    typeDelivery,
                    preorderDate,
                    usedBonuses,
                })
            ) {
                autoDiscount = discount;
            }
        });
    }

    if (autoDiscount) {
        if (autoDiscount.type === "fixed") {
            autoDiscountAmount = autoDiscount.amount;
        } else if (autoDiscount.type === "percent") {
            let autoDiscountBase = 0;
            Object.values(cartProducts).forEach((cartItem) => {
                cartItem.items.forEach((product) => {
                    const isProductDiscounted = checkProductForAutoDiscount(
                        autoDiscount,
                        product
                    );

                    if (!isProductDiscounted) {
                        return;
                    }

                    // Учитываем применённые промокоды при подсчёте скидки
                    if (promocode.type === "fixed_cart") {
                        const paidWithPromocodePercent =
                            promocode.amount / cartSubTotal;
                        autoDiscountBase +=
                            parseInt(product.options._price) *
                            (1 - paidWithPromocodePercent);
                    } else if (promocode.type === "percent") {
                        if (product.options._promocode_price) {
                            autoDiscountBase += parseInt(
                                product.options._promocode_price
                            );
                        } else {
                            autoDiscountBase += parseInt(
                                product.options._price
                            );
                        }
                    } else {
                        autoDiscountBase += parseInt(product.options._price);
                    }
                });
            });
            autoDiscountAmount = Math.floor(
                autoDiscountBase * (autoDiscount.amount / 100)
            );
        }
    }

    if (autoDiscountAmount > cartTotal) {
        autoDiscountAmount = cartTotal;
    }

    return { autoDiscountAmount, autoDiscount };
};

export default useAutoDiscount;
