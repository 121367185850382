import React from "react";

import clsx from "clsx";

import soon from "../../img/photo-soon.svg";

import "../../css/recommend-products.css";

export default function MiniCartReccomendProduct({
    product,
    onProductClick = () => {},
}) {
    return (
        <div
            className={clsx(
                product.type === "simple" && "btn-buy",
                "recommend-product"
            )}
            data-product-id={product.id}
            onClick={() => {
                onProductClick(product);
            }}
        >
            <div className="product-image">
                <img
                    src={product.img ? product.img : soon}
                    alt={product.title}
                />
            </div>

            <div className="right-block">
                <div className="product-name">{product.title}</div>

                <div className="product--price">
                    {product.type === "variations" && "от "}
                    {product.options._price.toLocaleString("ru-RU")} &#8381;
                </div>
            </div>
        </div>
    );
}
