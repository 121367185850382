import { createGlobalStyle } from "styled-components";
import {
    CeraFont,
    ManropeFont,
    NunitoFont,
    FiraSansFont,
    PTSansFont,
    RubikFont,
} from "fonts/index";
import colorsPalette from "./colorsPalette";
import { useSelector } from "react-redux";

const MainTheme = createGlobalStyle`
	:root {
		--main-color: ${(props) =>
            props.mainColor.match(/#[a-f0-9]{6}\b/gi)
                ? props.mainColor
                : "#ff5722"};
        
		--sec-color: ${(props) =>
            props.secondColor.match(/#[a-f0-9]{6}\b/gi)
                ? props.secondColor
                : "#000"};
        ${(props) => `
        --text-color: ${props.colors.text};
        --background-color: ${props.colors.background};
        --rgb-background-color: ${props.colors.rgbBackground};
        --body-background: ${props.colors.bodyBackground};
        --box-shadow: ${props.colors.boxShadow};
        --product-background: ${props.colors.productBackground};
        --product-box-shadow: ${props.colors.productBoxShadow};
        --product-hover-box-shadow: ${props.colors.productHoverBoxShadow};
        --product-border: ${props.colors.productBorder};
        --product-fade-color: ${props.colors.productFadeColor};
        --recommend-product-box-shadow: ${props.colors.recommendProductBoxShadow};
        --modal-background: ${props.colors.modalBackground};
        --rgb-modal-background: ${props.colors.rgbModalBackground};
        --modal-background-primary: ${props.colors.modalBackgroundPrimary};
        --addon-background:${props.colors.addonBackground};
        --variations-backround:${props.colors.variationsBackround};
        --selected-variation-backround:${props.colors.selectedVariationBackround};
        --mini-cart-background: ${props.colors.miniCartBackground};
        --header-background: ${props.colors.headerBackground};
        --header-border: ${props.colors.headerBorder};
        --mobile-menu-btn-hover: ${props.colors.mobileMenuBtnHover};
        --top-menu-background: ${props.colors.topMenuBackground};
        --top-menu-border: ${props.colors.topMenuBorder};
        --sticked-bar-background: ${props.colors.stickedBarBackground};
        --sticked-bar-opacity: ${props.colors.stickedBarOpacity};
        --divider-color: ${props.colors.dividerColor};
        --divider-color-contrast: ${props.colors.dividerColorContrast};
        --counter-buttons-color: ${props.colors.counterButtonsColor};
        --loading-backdrop-color: ${props.colors.loadingBackdropColor};
        --footer-background: ${props.colors.footerBackground};
        --scroll-btn-background: ${props.colors.scrollBtnBackground};
        --scroll-btn-color: ${props.colors.scrollBtnColor};
        `}
        
	}
    ${(props) => {
        switch (props.font) {
            case "cera":
                return CeraFont;
            case "manrope":
                return ManropeFont;
            case "nunito":
                return NunitoFont;
            case "firasans":
                return FiraSansFont;
            case "ptsans":
                return PTSansFont;
            case "rubik":
                return RubikFont;
            default:
                return CeraFont;
        }
    }}
    `;

const StyledGlobalTheme = () => {
    const themeMode =
        window.CONFIG_design_dark_theme == "active" ? "dark" : "light";

    const colors =
        themeMode === "dark" ? colorsPalette.dark : colorsPalette.light;

    const mainColor = window.CONFIG_main_color
        ? window.CONFIG_main_color
        : "#000";
    const secondColor = window.CONFIG_second_color
        ? window.CONFIG_second_color
        : "#000";

    return (
        <MainTheme
            mainColor={mainColor}
            secondColor={secondColor}
            font={window.CONFIG_type_font}
            colors={colors}
        />
    );
};

export default StyledGlobalTheme;
