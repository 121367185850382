import { FormControlLabel, Grid, Radio } from "@mui/material";
import BootstrapTooltip from "./BootstrapTooltip";
import CartBonusProduct from "../components/Product/CartBonusProduct";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { addBonusProductToCart } from "../redux/actions/cart";
import { setModalBonusProduct } from "../redux/actions/bonusProductModal";

const CartBonusesProduct = ({
    product,
    cartTotalPrice,
    bonusesDisabledByCategory,
    bonusesDisabledByPromocode,
    minicart,
}) => {
    const dispatch = useDispatch();

    const cartBonusProduct = useSelector((state) => state.cart.bonusProduct);

    // const hasRequiredModificator = product.product_addons?.find(
    //     (modificator) => modificator.required === "yes"
    // );

    const handleChooseBonusProduct = (product) => {
        if (cartBonusProduct.id === product.id) {
            dispatch(addBonusProductToCart({}));
        } else {
            if (
                !bonusesDisabledByPromocode &&
                !bonusesDisabledByCategory &&
                cartTotalPrice >= product.limit
            ) {
                dispatch(addBonusProductToCart({ ...product }));
                // if (
                //     product.type === "variations"
                //     || hasRequiredModificator
                // ) {
                //     dispatch(setModalBonusProduct({ ...product }));
                // } else {
                //     dispatch(addBonusProductToCart({ ...product }));
                // }
            }
        }
    };

    return (
        <Grid item md={minicart ? 12 : 6} sm={12} sx={{ width: "100%" }}>
            <BootstrapTooltip
                placement="left"
                title={
                    cartTotalPrice < product.limit
                        ? "От " +
                          product.limit +
                          " ₽ (не хватает " +
                          (product.limit - cartTotalPrice) +
                          " ₽)"
                        : "Выбрать"
                }
            >
                <div
                    disabled={
                        !!(
                            cartTotalPrice < product.limit ||
                            bonusesDisabledByPromocode ||
                            bonusesDisabledByCategory
                        )
                    }
                    className="bonus-product-choose"
                    onClick={() => handleChooseBonusProduct(product)}
                >
                    <FormControlLabel
                        disabled={
                            !!(
                                cartTotalPrice < product.limit ||
                                bonusesDisabledByPromocode ||
                                bonusesDisabledByCategory
                            )
                        }
                        value={product.id}
                        control={<Radio />}
                    />
                    <div className="bonus-product-choose-content">
                        <CartBonusProduct product={product} />
                        {cartBonusProduct.id === product.id ? (
                            <div className="cart--product-remove">
                                <CloseIcon />
                            </div>
                        ) : null}
                    </div>
                </div>
            </BootstrapTooltip>
        </Grid>
    );
};

export default CartBonusesProduct;
