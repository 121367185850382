import React, { useState, useEffect } from "react";
import { AddressSuggestions } from "react-dadata";
import { Alert } from "@mui/material";
import clsx from "clsx";
import "react-dadata/dist/react-dadata.css";
import { useSelector } from "react-redux";

const DadataAddressInput = React.forwardRef(
    ({ dadataValue, handleDadataValueChange, error, helperText }, ref) => {
        const config = useSelector((state) => state.config.data);
        const [disabled, setDisabled] = useState(
            !config.deliveryZones?.daDataApiKey
        );

        // Начальная проверка токена на валидность
        useEffect(() => {
            const url =
                "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
            const query = "Москва";
            const options = {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization:
                        "Token " + config.deliveryZones?.daDataApiKey,
                },
                body: JSON.stringify({ query: query }),
            };

            fetch(url, options)
                .then((response) => {
                    if (!response.ok) {
                        setDisabled(true);
                    }
                })
                .then((result) => {})
                .catch((error) => {
                    console.log("error", error);
                    setDisabled(true);
                });
        }, []);

        return (
            <div className={clsx("dadata-address-input", error && "error")}>
                <div className="dadata-input-container">
                    <AddressSuggestions
                        token={config.deliveryZones?.daDataApiKey}
                        value={dadataValue}
                        filterLocationsBoost={[
                            {
                                kladr_id: config.deliveryZones?.townKladrId
                                    ? config.deliveryZones?.townKladrId
                                    : "",
                            },
                        ]}
                        inputProps={{
                            placeholder: "Введите улицу и дом",
                            disabled: disabled,
                        }}
                        onChange={handleDadataValueChange}
                        minChars={1}
                        ref={ref}
                    />
                    <fieldset className="dadata-address-fieldset" />
                </div>

                {error && helperText ? (
                    <p className="dadata-helper-text">{helperText}</p>
                ) : null}

                {disabled && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                        Поиск адреса временно недоступен, пожалуйста, укажите
                        адрес на карте.
                    </Alert>
                )}
            </div>
        );
    }
);
export default DadataAddressInput;
