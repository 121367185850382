import { useSelector } from "react-redux";

const AdditionalAccrueInfo = () => {
    const productCategories = useSelector((state) => state.products.categories);
    const {
        specifiedСategories,
        excludeCategories,
        allowWithPromocode,
        allowWithBonusProduct,
        allowWithDiscountProducts,
        allowWithBonuses,
    } = useSelector(
        ({
            config: {
                data: { bonusProgramm },
            },
        }) => {
            return {
                specifiedСategories: bonusProgramm.categories,
                excludeCategories: bonusProgramm.excludeCategories === "yes",
                allowWithPromocode: bonusProgramm.allowPromocode === "active",
                allowWithBonusProduct:
                    bonusProgramm.allowBonusProduct === "active",
                allowWithDiscountProducts:
                    bonusProgramm.allowSaleProduct === "active",
                allowWithBonuses: bonusProgramm.allowWithBonuses === "active",
            };
        }
    );
    if (
        !specifiedСategories.length &&
        allowWithPromocode &&
        allowWithBonusProduct &&
        allowWithDiscountProducts &&
        allowWithBonuses
    ) {
        return null;
    }

    const specifiedСategoriesNames = [];
    if (specifiedСategories.length) {
        specifiedСategories.forEach((specifiedСategoryId) => {
            const category = productCategories.find(
                (productCategory) =>
                    productCategory.term_id === specifiedСategoryId
            );
            if (category) {
                specifiedСategoriesNames.push(`«${category.name}»`);
            }
        });
    }

    return (
        <div className="bonus-page--accrue-info">
            <h3>Условия начисления</h3>
            {specifiedСategoriesNames.length ? (
                excludeCategories ? (
                    <div>
                        Бонусы начисляются на все товары, кроме следующих
                        категорий: {specifiedСategoriesNames.join(", ")}
                        {allowWithDiscountProducts
                            ? ""
                            : " и товаров со скидкой"}
                        .
                    </div>
                ) : (
                    <div>
                        Бонусы начисляются на товары следующих категорий:{" "}
                        {specifiedСategoriesNames.join(", ")}
                        {allowWithDiscountProducts
                            ? ""
                            : ", кроме товаров со скидкой"}
                        .
                    </div>
                )
            ) : !allowWithDiscountProducts ? (
                <div>Бонусы не начисляются на товары со скидкой.</div>
            ) : null}
            {!allowWithPromocode ||
            !allowWithBonusProduct ||
            !allowWithDiscountProducts ||
            !allowWithBonuses ? (
                <div className="accrue-info--disable-reasons-container">
                    Бонусы не начисляются если вы:
                    <ul>
                        {!allowWithPromocode ? (
                            <li>применили промокод</li>
                        ) : null}
                        {!allowWithBonusProduct ? (
                            <li>выбрали подарок</li>
                        ) : null}
                        {!allowWithBonuses ? (
                            <li>используете бонусы для оплаты заказа</li>
                        ) : null}
                    </ul>
                </div>
            ) : null}
        </div>
    );
};

export default AdditionalAccrueInfo;
