import { useSelector } from "react-redux";
import { isCartEmptySelector } from "redux/selectors/cartSelectors";

import MiniCartBonuses from "./MiniCartBonuses";
import { Promocode } from "components";
import { Button, Divider } from "@mui/material";

import { _isPromocodesEnabled } from "components/helpers";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const MiniCartTotal = ({ handleCloseMiniCart, handleMakeOrder }) => {
    const config = useSelector((state) => state.config.data);
    const cartDiscount = useSelector((state) => state.cart.discount);
    const cartTotalPrice = useSelector((state) => state.cart.totalPrice);
    const cartSubTotalPrice = useSelector((state) => state.cart.subTotalPrice);
    const promocode = useSelector((state) => state.cart.promocode);
    const isCartEmpty = useSelector(isCartEmptySelector);

    return (
        <div className="minicart--total-wrapper">
            {_isPromocodesEnabled(config) && (
                <div className="minicart--promocode">
                    <Promocode />
                </div>
            )}

            {cartDiscount || config.bonusProgramm?.status === "active" ? (
                <div className="minicart--subtotal-price">
                    {cartDiscount ? (
                        <>
                            <div className="price">
                                Сумма заказа{" "}
                                <span className="money">
                                    {cartSubTotalPrice.toLocaleString("ru-RU")}{" "}
                                    &#8381;
                                </span>
                            </div>
                            <div className="promocode">
                                <span>
                                    Промокод{" "}
                                    <span className="main-color">
                                        {promocode.code}
                                    </span>
                                </span>

                                <span className="money main-color">
                                    -{cartDiscount.toLocaleString("ru-RU")}{" "}
                                    &#8381;
                                </span>
                            </div>
                        </>
                    ) : null}
                    {config.bonusProgramm?.status === "active" ? (
                        <MiniCartBonuses
                            handleCloseMiniCart={handleCloseMiniCart}
                        />
                    ) : null}
                </div>
            ) : null}

            <Divider className="minicart--total-divider" sx={{ mb: "10px" }} />

            <div className="minicart--total-block">
                <span className="minicart--total-title">Итого</span>
                <span className="minicart--total-price">
                    {cartTotalPrice.toLocaleString("ru-RU")} &#8381;
                </span>
            </div>

            <Button
                variant="button"
                onClick={handleMakeOrder}
                className="btn--action openCheckout"
                disabled={isCartEmpty}
                sx={{ mt: 2 }}
            >
                К оформлению заказа
                <NavigateNextIcon className="button-arrow-icon" />
            </Button>
        </div>
    );
};

export default MiniCartTotal;
