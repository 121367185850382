import { useSelector } from "react-redux";
import { _getDomain, _getMobileType } from "../components/helpers";
import useTownNavigate from "../hooks/useTownNavigate";

const QrMobile = () => {
    const mobileType = _getMobileType();

    const { rootTownPathname } = useTownNavigate();

    const config = useSelector((state) => state.config.data);
    const configStatus = useSelector((state) => state.config.status);

    if (configStatus) {
        if (mobileType === "android" && config.CONFIG_GPLAY) {
            window.location.href = config.CONFIG_GPLAY;
        } else if (mobileType === "ios" && config.CONFIG_APPSTORE) {
            window.location.href = config.CONFIG_APPSTORE;
        } else {
            window.location.href = `https://${_getDomain()}${rootTownPathname}`;
        }
    }

    return <></>;
};

export default QrMobile;
