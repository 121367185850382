import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container } from "@mui/material";
import { _getDomain } from "../components/helpers.js";
import { clearCart } from "../redux/actions/cart";
import { Header, Footer, TownLink } from "../components";
import catOrderConfirm from "../img/cat-order-confirm.svg";
import { ReactComponent as CatConfirm } from "../img/cat-order-confirm.svg";
import "../css/order-complete-page.css";
import useMetricFunctions from "../hooks/useMetricFunctions.js";

export default function OrderComplete() {
    const dispatch = useDispatch();
    const { metricMakeOrder } = useMetricFunctions();

    const cartProducts = useSelector((state) => state.cart.items);
    const promocode = useSelector((state) => state.cart.promocode);
    const userPhone = useSelector((state) => state.user.user.phone);
    const userToken = useSelector((state) => state.user.user.token);

    const confirmOrdertext = useSelector(
        (state) => state.config.data.CONFIG_confirm_order_text
    );

    const customConfirmOrderImg = window.CONFIG_order_confirm_image;

    useEffect(() => {
        metricMakeOrder(cartProducts, promocode, userPhone);
        dispatch(clearCart());
    }, []);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
            }}
        >
            <Header />
            <Container sx={{ flexGrow: 1 }}>
                <h1 className="page-title" lang="ru">
                    Заказ принят
                </h1>
                <div className="order-complete-page">
                    <div className="order-complete--logo-container">
                        {customConfirmOrderImg ? (
                            <img
                                src={customConfirmOrderImg}
                                className="order-complete--logo"
                                alt="Заказ принят"
                            />
                        ) : (
                            <CatConfirm className="order-complete--logo" />
                        )}
                    </div>
                    <div className="order-complete-page--content-container">
                        {confirmOrdertext ? (
                            <div
                                className="order-complete-page--content"
                                dangerouslySetInnerHTML={{
                                    __html: confirmOrdertext,
                                }}
                            ></div>
                        ) : (
                            <>
                                <p>Ваш заказ принят.</p>
                                <p>
                                    Оператор свяжется с вами в ближайшее время
                                    для подтверждения заказа.
                                </p>
                            </>
                        )}
                        {userToken ? (
                            <TownLink
                                className="btn btn--action"
                                to="/account#orders"
                                style={{ marginTop: "15px" }}
                            >
                                В мои заказы
                            </TownLink>
                        ) : (
                            <TownLink
                                className="btn btn--action"
                                to="/"
                                style={{ marginTop: "15px" }}
                            >
                                На главную страницу
                            </TownLink>
                        )}
                    </div>
                </div>
            </Container>
            <Footer />
        </Box>
    );
}
