import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useSelector } from "react-redux";

const GoogleRecaptchaWrapper = ({ children }) => {
    const typeCaptcha = useSelector(
        (state) => state.config.data.CONFIG_auth_captcha_type
    );
    const recaptchaToken = useSelector(
        (state) => state.config.data.CONFIG_auth_recaptcha_site_token
    );

    if (typeCaptcha !== "google") {
        return children;
    }
    return (
        <GoogleReCaptchaProvider reCaptchaKey={recaptchaToken}>
            {children}
        </GoogleReCaptchaProvider>
    );
};

export default GoogleRecaptchaWrapper;
