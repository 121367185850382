import React, { useState, useEffect } from "react";
import { _isMobile } from "../helpers";
import { Container, Skeleton } from "@mui/material";

const BannersSkeleton = () => {
    return (
        <div
            className={
                _isMobile() ? "mobile-banners-wrapper" : "banners-wrapper"
            }
        >
            <Container>
                <div className="banners-skeleton">
                    <Skeleton
                        variant="rounded"
                        className="banners-skeleton--skeleton"
                    />
                </div>
            </Container>
        </div>
    );
};

export default BannersSkeleton;
