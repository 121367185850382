import { useSelector } from "react-redux";
import { Alert, Grid, RadioGroup } from "@mui/material";
import { CartBonusesProduct } from "./index";
import useBonusProducts from "../hooks/useBonusProducts";

export default function CartBonusesProducts(minicart = false) {
    const bonuses_items = useSelector((state) => state.products.bonuses_items);
    const cartBonusProduct = useSelector((state) => state.cart.bonusProduct);

    const {
        cartTotalPrice,
        disabledCategoriesNames,
        bonusesDisabled,
        bonusesDisabledByCategory,
        bonusesDisabledByPromocode,
    } = useBonusProducts();

    if (bonusesDisabled) {
        return null;
    }

    return (
        <div>
            {bonuses_items.length ? (
                <div className="cart--bonuses-products">
                    <h2 className="bonuses-products--title">
                        Выберите подарок
                    </h2>

                    {bonusesDisabledByPromocode ? (
                        <Alert
                            severity="info"
                            className="custom-alert"
                            sx={{ mb: 2, mr: 2, ml: 2 }}
                        >
                            Бонусные товары нельзя выбрать при использовании
                            промокода.
                        </Alert>
                    ) : (
                        ""
                    )}

                    {bonusesDisabledByCategory ? (
                        <Alert
                            severity="info"
                            sx={{ mb: 2, mr: 2, ml: 2 }}
                            className="custom-alert"
                        >
                            Товары из категории:{" "}
                            {disabledCategoriesNames.join(", ")} нельзя
                            использовать вместе со шкалой подарков.
                        </Alert>
                    ) : null}

                    <RadioGroup
                        name="bonusesProducts"
                        value={
                            Object.keys(cartBonusProduct).length
                                ? cartBonusProduct.id
                                : ""
                        }
                    >
                        <Grid container spacing={2}>
                            {Object.values(bonuses_items).map((product) => (
                                <CartBonusesProduct
                                    product={product}
                                    cartTotalPrice={cartTotalPrice}
                                    bonusesDisabledByCategory={
                                        bonusesDisabledByCategory
                                    }
                                    bonusesDisabledByPromocode={
                                        bonusesDisabledByPromocode
                                    }
                                    minicart={minicart}
                                    key={product.id}
                                />
                            ))}
                        </Grid>
                    </RadioGroup>
                </div>
            ) : (
                ""
            )}
        </div>
    );
}
