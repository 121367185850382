const initialState = {
    topMenu: [],
    bottomMenu: [],
    sales: [],
};

const pages = (state = initialState, action) => {
    switch (action.type) {
        case "SET_TOP_MENU":
            return {
                ...state,
                topMenu: action.payload,
            };
        case "SET_BOTTOM_MENU":
            return {
                ...state,
                bottomMenu: action.payload,
            };
        case "SET_SALES":
            return {
                ...state,
                sales: action.payload,
            };
        case "CLEAN_STORE": {
            return { ...initialState };
        }
        default:
            return state;
    }
};

export default pages;
