import { Grid, Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";

const ContactsSkeleton = () => {
    return (
        <Grid container spacing={4}>
            <Grid item sm={12} md={4} sx={{ width: 1 }}>
                <div className="contacts--block">
                    <Skeleton width={150} sx={{ mb: "6px" }} />
                    <Skeleton width={200} sx={{ mb: "6px" }} />
                    <Skeleton width={200} sx={{ mb: "6px" }} />
                </div>
                <div className="contacts--block">
                    <Skeleton width={150} sx={{ mb: "6px" }} />
                    <Skeleton width={200} sx={{ mb: "6px" }} />
                    <Skeleton width={200} sx={{ mb: "6px" }} />
                </div>
            </Grid>
            <Grid item sm={12} md={8} sx={{ width: 1 }}>
                <div className="contacts--map">
                    <Skeleton
                        variant="rounded"
                        width={"100%"}
                        height={"100%"}
                    />
                </div>
            </Grid>
        </Grid>
    );
};

export default ContactsSkeleton;
