import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Button, Snackbar, Slide, Box, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { _isMobile } from "./helpers";
import Cookies from "universal-cookie";
import { addDays } from "date-fns";

function TransitionRight(props) {
    return <Slide {...props} direction="right" />;
}

const SubscribeSnackbar = () => {
    const timeoutRef = useRef();
    const [subscribeOpen, setSubscribeOpen] = useState(false);

    const { data: config } = useSelector((state) => state.config);
    const userToken = useSelector((state) => state.user.user.token);

    const matches = useMediaQuery("(min-width:600px)");

    const cookies = new Cookies();

    const popupWidgetWasShown = cookies.get("popupWidgetWasShown");

    const shouldShowSnackbar =
        !popupWidgetWasShown &&
        config.CONFIG_popup_widget_status === "active" &&
        config.CONFIG_popup_widget_text &&
        config.CONFIG_popup_widget_title &&
        (userToken || config.CONFIG_popup_widget_show_auth_users !== "active");

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        cookies.set("popupWidgetWasShown", "true", {
            path: "/",
            expires: addDays(new Date(), 7),
            sameSite: "none",
            secure: true,
        });
        setSubscribeOpen(false);
    };

    const handleConfirm = () => {
        cookies.set("popupWidgetWasShown", "true", {
            path: "/",
            expires: addDays(new Date(), 7),
            sameSite: "none",
            secure: true,
        });
        setSubscribeOpen(false);
        if (config.CONFIG_popup_widget_link) {
            window.open(config.CONFIG_popup_widget_link, "_blank");
        }
    };

    useEffect(() => {
        if (shouldShowSnackbar) {
            timeoutRef.current = setTimeout(() => {
                setSubscribeOpen(true);
            }, parseInt(config.CONFIG_popup_widget_delay || 60) * 1000);
        } else if (subscribeOpen) {
            setSubscribeOpen(false);
        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [shouldShowSnackbar, config.CONFIG_popup_widget_delay]);

    return (
        <Snackbar
            open={subscribeOpen}
            anchorOrigin={{
                vertical: _isMobile() ? "top" : "bottom",
                horizontal: "left",
            }}
            onClose={handleClose}
            TransitionComponent={TransitionRight}
            message={
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Typography sx={{ fontWeight: 600, fontSize: "20px" }}>
                        {config.CONFIG_popup_widget_title}
                    </Typography>
                    <Typography sx={{ mb: 1 }}>
                        {config.CONFIG_popup_widget_text}
                    </Typography>
                    <Box sx={{ display: "flex", flexBasis: "0.5" }}>
                        <Button
                            variant="outlined"
                            onClick={handleClose}
                            sx={{ flexGrow: 1 }}
                        >
                            {config.CONFIG_popup_widget_cancel_button || "Нет"}
                        </Button>
                        <Button
                            onClick={handleConfirm}
                            variant="contained"
                            sx={{ ml: 1, flexGrow: 1, flexBasis: "0.5" }}
                            className="btn--action"
                        >
                            {config.CONFIG_popup_widget_apply_button || "Да"}
                        </Button>
                    </Box>
                </Box>
            }
            key="TransitionRight"
            action={<></>}
            sx={{
                mb: 4,
                "& .MuiPaper-root": {
                    maxWidth: matches ? "320px" : "auto",
                    boxShadow: "var(--box-shadow)",
                    borderRadius: "15px",
                },
                "& .MuiSnackbarContent-root": {
                    backgroundColor: "var(--background-color)",
                    color: "var(--text-color)",
                },
                "& .MuiSnackbarContent-message": {
                    width: "100%",
                },
            }}
            className="subscribe-snackbar"
        />
    );
};

export default SubscribeSnackbar;
