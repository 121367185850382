import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Alert, Box, Button, Grid } from "@mui/material";
import Product from "./Product";

import { _clone, _isCategoryDisabled } from "components/helpers";

const ProductCategory = ({ category, products }) => {
    const categoryProducts = Object.values(products).filter((product) =>
        product.categories.includes(category.term_id)
    );

    const productLayout = useSelector(
        (state) => state.config.data.CONFIG_type_products
    );

    const [activeCategoryTags, setActiveCategoryTags] = useState({});

    if (!categoryProducts?.length) {
        return null;
    }

    const handleClickCategoryTag = (categoryID, tagID) => {
        let tmpArray = _clone(activeCategoryTags);

        if (!tmpArray[categoryID] || !tmpArray[categoryID].includes(tagID)) {
            tmpArray[categoryID] = [tagID];
        } else {
            delete tmpArray[categoryID];
        }

        setActiveCategoryTags(tmpArray);
    };

    // Проверяем доступность категории
    const isCategoryDisabledResult = _isCategoryDisabled(category);

    // Скрываем недоступную категорию
    if (isCategoryDisabledResult.disabled && category.limit_type !== "block") {
        return null;
    }

    return (
        <div
            id={`category-${category.term_id}`}
            className={`category-${category.term_id} product--category-container`}
        >
            <h2 key={`title-${category.term_id}`}>{category.name}</h2>

            {isCategoryDisabledResult.message ? (
                <Alert
                    severity={
                        isCategoryDisabledResult.disabled ? "error" : "info"
                    }
                    sx={{ mb: 1 }}
                >
                    {isCategoryDisabledResult.message}
                </Alert>
            ) : null}

            {Object.values(category.tags).length ? (
                <Box
                    className="product--category-tags-container"
                    sx={
                        productLayout === "one"
                            ? {
                                  mb: "8px",
                              }
                            : {}
                    }
                >
                    {category.tags &&
                        Object.values(category.tags).map((tag) => (
                            <Button
                                key={`tag-${tag.term_id}`}
                                variant="button"
                                className={`btn btn--tag ${
                                    activeCategoryTags.hasOwnProperty(
                                        category.term_id
                                    )
                                        ? activeCategoryTags[
                                              category.term_id
                                          ].includes(tag.term_id)
                                            ? "btn--tag-active btn--action"
                                            : ""
                                        : ""
                                }`}
                                sx={{
                                    mr: 1,
                                    mb: 1,
                                    px: 1,
                                    bgcolor: "var(--background)",
                                }}
                                onClick={() =>
                                    handleClickCategoryTag(
                                        category.term_id,
                                        tag.term_id
                                    )
                                }
                            >
                                {tag.name}
                            </Button>
                        ))}
                </Box>
            ) : null}

            <Grid
                container
                spacing={{ xs: 0, sm: 2 }}
                key={`grid-${category.term_id}`}
            >
                {categoryProducts
                    .sort((product1, product2) =>
                        product1["order"] > product2["order"] ? 1 : -1
                    )
                    .map((product) =>
                        activeCategoryTags.hasOwnProperty(category.term_id) &&
                        activeCategoryTags[category.term_id].length ? (
                            Object.values(product.tags).filter((productTag) =>
                                activeCategoryTags[category.term_id].includes(
                                    productTag.term_id
                                )
                            ).length ? (
                                <Product
                                    key={product.id}
                                    product={product}
                                    disabled={isCategoryDisabledResult.disabled}
                                />
                            ) : (
                                ""
                            )
                        ) : (
                            <Product
                                key={product.id}
                                product={product}
                                category={category}
                                disabled={isCategoryDisabledResult.disabled}
                            />
                        )
                    )}
            </Grid>
        </div>
    );
};

export default ProductCategory;
