import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeMobileMenu, openMobileMenu } from "../redux/actions/header";
import { Link, useLocation } from "react-router-dom";
import { IntersectionObserverWrapper, MiniCart, TownLink } from "../components";
import Container from "@mui/material/Container";
import { Link as AnimateLink } from "react-scroll";
import { _isCategoryDisabled, _isMobile } from "../components/helpers.js";
import smoothscroll from "smoothscroll-polyfill";
import MenuIcon from "@mui/icons-material/Menu";
import clsx from "clsx";
import "../css/top-categories-menu.css";
import useTownNavigate from "../hooks/useTownNavigate";
import {
    MobileSceletonComponent,
    SceletonComponent,
} from "./Skeletons/TopCategoriesMenuSkeletons";
import useMetricFunctions from "../hooks/useMetricFunctions";

export default function TopCategoriesMenu() {
    smoothscroll.polyfill();
    const dispatch = useDispatch();
    const stickedBarRef = useRef();
    const categoriesMenuRef = useRef();
    const stickyDetector = useRef();
    const { pathname } = useLocation();
    const { rootTownPathname } = useTownNavigate();
    const { metricViewCategory } = useMetricFunctions();

    const productsStatus = useSelector((state) => state.products.status);
    const categories = useSelector((state) => state.products.categories);
    const products = useSelector((state) => state.products.items);
    const categoriesMenuType = useSelector(
        (state) => state.config.data.CONFIG_type_categories
    );
    const { mobileMenuOpen } = useSelector((state) => state.header);

    const [sticked, setSticked] = useState(false);
    const [availableCategories, setAvailableCategories] = useState(null);

    useEffect(() => {
        if (categories) {
            const temp = categories
                .map((item) => {
                    if (
                        _isCategoryDisabled(item).disabled &&
                        item.limit_type !== "block"
                    ) {
                        return null;
                    }
                    if (
                        Object.values(products).find((product) =>
                            product.categories.includes(item.term_id)
                        )
                    ) {
                        return item;
                    }
                })
                .filter((item) => item);
            setAvailableCategories(temp);
        }
    }, [categories, products]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [sticked]);

    const fullWidthHeader = document.querySelector(
        ".fullwidth-header--wrapper"
    );

    const handleScroll = () => {
        const fullWidthHeaderHeight = fullWidthHeader
            ? fullWidthHeader.offsetHeight
            : 0;

        if (
            (window.scrollY + fullWidthHeaderHeight >=
                stickyDetector.current.offsetTop) &
            !sticked
        ) {
            setSticked(true);
        }
        if (
            (window.scrollY + fullWidthHeaderHeight <
                stickyDetector.current.offsetTop) &
            sticked
        ) {
            setSticked(false);
        }
    };

    const scrollCategoriesMenu = () => {
        const activeCategory = document.querySelector(
            ".sticked-top-bar .active"
        );

        if (activeCategory) {
            const activeCategoryContainer = activeCategory.parentElement;
            categoriesMenuRef.current.scrollTo({
                left:
                    activeCategoryContainer.offsetLeft -
                    categoriesMenuRef.current.offsetWidth / 2 +
                    activeCategoryContainer.offsetWidth / 2,
                behavior: "smooth",
            });
        }
    };

    const toggleMobileMenu = () => {
        if (!mobileMenuOpen) {
            dispatch(openMobileMenu());
        } else {
            dispatch(closeMobileMenu());
        }
    };

    const isOnHomePage =
        pathname === rootTownPathname || pathname === rootTownPathname + "/";

    if (_isMobile()) {
        return (
            <>
                <div ref={stickyDetector}></div>
                <div className="sticked-top-bar--container">
                    <div
                        className={clsx("sticked-top-bar", {
                            sticked: sticked,
                            white: categoriesMenuType === "one",
                            filled: categoriesMenuType === "two",
                            fullWidthSite: !!window.fullWidthSite,
                        })}
                        ref={stickedBarRef}
                    >
                        <Container className="inner-wrapper">
                            <ul
                                className="categories-menu"
                                ref={categoriesMenuRef}
                            >
                                {_isMobile() ? (
                                    <li>
                                        <a className="menu-icon">
                                            <MenuIcon
                                                onClick={toggleMobileMenu}
                                            />
                                        </a>
                                    </li>
                                ) : null}
                                {!categories?.length && !productsStatus ? (
                                    <MobileSceletonComponent />
                                ) : (
                                    availableCategories?.map((item) => {
                                        const categoryActive =
                                            pathname.includes(
                                                `/category/${item.slug}`
                                            ) ||
                                            pathname.includes(
                                                `/product-category/${item.slug}`
                                            );
                                        return (
                                            <li
                                                key={item.term_id}
                                                className="viewCategory"
                                                data-targetid={item.term_id}
                                            >
                                                {isOnHomePage ? (
                                                    <AnimateLink
                                                        activeClass="active"
                                                        to={`category-${item.term_id}`}
                                                        spy={true}
                                                        smooth={true}
                                                        offset={-70}
                                                        duration={500}
                                                        onSetActive={
                                                            scrollCategoriesMenu
                                                        }
                                                        onClick={
                                                            metricViewCategory
                                                        }
                                                        spyThrottle={520}
                                                    >
                                                        {item.name}
                                                    </AnimateLink>
                                                ) : (
                                                    <TownLink
                                                        to={`/category/${item.slug}`}
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                        }}
                                                        className={clsx(
                                                            categoryActive &&
                                                                "active"
                                                        )}
                                                        onClick={
                                                            metricViewCategory
                                                        }
                                                    >
                                                        {item.name}
                                                    </TownLink>
                                                )}
                                            </li>
                                        );
                                    })
                                )}
                            </ul>
                            <MiniCart />
                        </Container>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <div ref={stickyDetector}></div>
            <div
                className={clsx("sticked-top-bar", {
                    sticked: sticked,
                    white: categoriesMenuType === "one",
                    filled: categoriesMenuType === "two",
                    fullWidthSite: !!window.fullWidthSite,
                })}
                ref={stickedBarRef}
            >
                <Container className="inner-wrapper">
                    {!categories?.length && !productsStatus ? (
                        <SceletonComponent />
                    ) : (
                        <IntersectionObserverWrapper ref={categoriesMenuRef}>
                            {availableCategories?.map((item) => {
                                const categoryActive =
                                    pathname.includes(
                                        `/category/${item.slug}`
                                    ) ||
                                    pathname.includes(
                                        `/product-category/${item.slug}`
                                    );
                                return (
                                    <li
                                        key={item.term_id}
                                        className="viewCategory"
                                        data-targetid={item.term_id}
                                    >
                                        {isOnHomePage ? (
                                            <AnimateLink
                                                activeClass="active"
                                                to={`category-${item.term_id}`}
                                                spy={true}
                                                smooth={true}
                                                offset={
                                                    window.fullWidthSite
                                                        ? -125
                                                        : -70
                                                }
                                                duration={500}
                                                hashSpy={true}
                                                spyThrottle={500}
                                                onClick={metricViewCategory}
                                            >
                                                {item.name}
                                            </AnimateLink>
                                        ) : (
                                            <TownLink
                                                to={`/category/${item.slug}`}
                                                onClick={metricViewCategory}
                                                style={{
                                                    textDecoration: "none",
                                                }}
                                                className={clsx(
                                                    categoryActive && "active"
                                                )}
                                            >
                                                {item.name}
                                            </TownLink>
                                        )}
                                    </li>
                                );
                            })}
                        </IntersectionObserverWrapper>
                    )}
                    {window.fullWidthSite ? null : <MiniCart />}
                </Container>
            </div>
        </>
    );
}
