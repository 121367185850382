import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, IconButton, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { _getPlatform, _isMobile } from "./helpers";
import { setTownModal } from "../redux/actions/config";
import ChooseTown from "./ChooseTown";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ChooseTownModal = () => {
    const dispatch = useDispatch();

    const openTownModal = useSelector((state) => state.config.openTownModal);

    const closeModalByCross = useSelector(
        (state) => state.config.data.CONFIG_close_modal_by_cross
    );

    const onChooseTown = () => {
        handleAlertClose();
    };

    const handleAlertClose = useCallback(() => {
        dispatch(setTownModal(false));
    }, []);

    let dialogProps = { open: openTownModal, maxWidth: "md" };
    if (_isMobile()) {
        dialogProps.TransitionComponent = Transition;
        dialogProps.fullScreen = true;
        dialogProps.scroll = "body";
    }

    return (
        <div className="choose-town--block">
            {window.towns ? (
                <Dialog
                    maxWidth="md"
                    {...dialogProps}
                    sx={{
                        "& .MuiPaper-root": {
                            borderRadius: _isMobile() ? "0px" : "20px",
                        },
                    }}
                    onClose={(event, reason) => {
                        if (reason === "escapeKeyDown") {
                            handleAlertClose();
                        }
                        if (
                            reason === "backdropClick" &&
                            closeModalByCross !== "active"
                        ) {
                            handleAlertClose();
                        }
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleAlertClose}
                        aria-label="close"
                        className="modal-close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <div className="modal-alert--wrapper choose-town">
                        <ChooseTown onChooseTown={onChooseTown} />
                    </div>
                </Dialog>
            ) : (
                ""
            )}
        </div>
    );
};

export default ChooseTownModal;
