import React, { useState, useEffect } from "react";
import { _getDomain, _getPlatform } from "../../components/helpers";
import { CircularProgress, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import AccrueInfo from "../BonusPage/AccrueInfo";
import AdditionalBonusesInfo from "../BonusPage/AdditionalBonusesInfo";
import AdditionalAccrueInfo from "../BonusPage/AdditionalAccrueInfo";
import AdditionalPaymentInfo from "../BonusPage/AdditionalPaymentInfo";
import UserBonusesInfo from "../BonusPage/UserBonusesInfo";
import UserBonusesHistory from "./UserBonusesHistory";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { BootstrapTooltip } from "../../components";
import useApiRequests from "../../hooks/useApiRequests";

const UserBonuses = () => {
    const { postRequestAPI } = useApiRequests();

    const user = useSelector(({ user }) => user.user);
    const configStatus = useSelector((state) => state.config.status);
    const platformBonusProgramStatus = useSelector(
        (state) => state.config.data.bonusProgramm?.status === "active"
    );
    const bonusProgrammType = useSelector(
        (state) => state.config.data.bonusProgrammType
    );

    const [frontpadBonuses, setFrontpadBonuses] = useState(0);
    const [loading, setLoading] = useState(
        platformBonusProgramStatus ? false : true
    );
    const [error, setError] = useState(false);

    useEffect(() => {
        if (
            (bonusProgrammType === "frontpad" ||
                bonusProgrammType === "sbis") &&
            user.token &&
            user.phone &&
            configStatus
        ) {
            setLoading(true);

            let apiUrl = "";

            if (bonusProgrammType === "frontpad") {
                apiUrl = "getFrontpadBonuses";
            } else if (bonusProgrammType === "sbis") {
                apiUrl = "getSbisBonuses";
            }

            postRequestAPI(apiUrl, "", {
                token: user.token,
                phone: user.phone,
            }).then((resp) => {
                if (resp.data.status === "success") {
                    if (resp.data.user?.bonuses > 0) {
                        setFrontpadBonuses(resp.data.user?.bonuses);
                    }
                } else {
                    setError(true);
                }
                setLoading(false);
            });
        }
    }, [bonusProgrammType, configStatus]);

    return (
        <div className="account-user-bonuses">
            {platformBonusProgramStatus ? (
                <Grid
                    container
                    justifyContent="space-between"
                    wrap="wrap-reverse"
                    alignItems="flex-end"
                >
                    <Grid item xs={12} desctop={8}>
                        <UserBonusesHistory />

                        <AccrueInfo />

                        <AdditionalBonusesInfo />

                        <AdditionalAccrueInfo />

                        <AdditionalPaymentInfo />
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        desctop={3}
                        sx={{
                            position: "sticky",
                            height: "fit-content",
                            top: "20px",
                            ["@media (max-width: 899px)"]: {
                                position: "static",
                            },
                        }}
                    >
                        <UserBonusesInfo borderType="solid" />
                    </Grid>
                </Grid>
            ) : !error ? (
                <>
                    <p>
                        На вашем счету{" "}
                        {loading ? (
                            <CircularProgress size={14} />
                        ) : (
                            <span className="main-color">
                                {frontpadBonuses}
                            </span>
                        )}
                        <span> бонусов.</span>
                        <BootstrapTooltip
                            placement="top"
                            title={"Бонусы обновляются 1 раз в 15 минут"}
                        >
                            <InfoOutlinedIcon className="checkout--info-icon" />
                        </BootstrapTooltip>
                    </p>
                    <p>
                        1 бонус = <span className="main-color">1 &#8381;</span>
                    </p>
                </>
            ) : (
                <span>
                    Не удалось получить баланс бонусов. Попробуйте позже.
                </span>
            )}
        </div>
    );
};

export default UserBonuses;
