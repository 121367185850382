import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Grid,
    Skeleton,
    Typography,
} from "@mui/material";

const SalesListSkeleton = () => {
    return (
        <>
            {[...Array(3)].map((_, index) => (
                <Grid item xs={12} md={4} key={index}>
                    <Card className="sale">
                        <CardActionArea
                            sx={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                            }}
                        >
                            <CardMedia sx={{ width: "100%" }}>
                                <Skeleton
                                    variant="rounded"
                                    width={"100%"}
                                    height={190}
                                />
                            </CardMedia>
                            <CardContent sx={{ flexGrow: 1, width: "100%" }}>
                                <div className="sale--content-inner-wrapper">
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                    >
                                        <Skeleton
                                            variant="rounded"
                                            width={150}
                                            height={18}
                                        />
                                    </Typography>
                                    <Typography variant="body2">
                                        <Skeleton width={"100%"} />
                                        <Skeleton width={"100%"} />
                                        <Skeleton width={"50%"} />
                                    </Typography>
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            ))}
        </>
    );
};

export default SalesListSkeleton;
