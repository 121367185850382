import { Grid, Skeleton } from "@mui/material";
import clsx from "clsx";

export const ProductsListSkeleton = () => {
    return (
        <Grid container spacing={{ xs: 0, sm: 2 }}>
            {[...Array(8)].map((_, inx) => (
                <Grid
                    xs={12}
                    desctop={4}
                    mg={3}
                    item
                    className={clsx("product-grid-item", "default-layout")}
                    key={inx}
                >
                    <div className="product product-item">
                        <div className="product--image">
                            <Skeleton
                                variant="rounded"
                                width={"100%"}
                                height={"100%"}
                                sx={{
                                    borderRadius: "10px",
                                }}
                            />
                        </div>
                        <div className="product--inner-wrapper">
                            <h4 className="product--title">
                                <Skeleton
                                    variant="rounded"
                                    width={"75%"}
                                    height={18}
                                    sx={{ borderRadius: "20px" }}
                                />
                            </h4>
                            <div className="product--description">
                                <Skeleton width={"100%"} height={18} />
                                <Skeleton width={"100%"} height={18} />
                            </div>
                            <div className="product--buying">
                                <div className="product--price-wrapper">
                                    <Skeleton
                                        variant="rounded"
                                        width={80}
                                        height={20}
                                        sx={{ borderRadius: "20px" }}
                                    />
                                </div>
                                <Skeleton
                                    variant="rounded"
                                    width={75}
                                    height={40}
                                    sx={{ borderRadius: "20px" }}
                                />
                            </div>
                        </div>
                    </div>
                </Grid>
            ))}
        </Grid>
    );
};
