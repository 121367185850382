import React from "react";
import { useSelector } from "react-redux";
import { Container, Divider } from "@mui/material";
import { Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMapLocationDot,
    faPhone,
    faEnvelope,
    faClock,
    faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import {
    faVk,
    faOdnoklassnikiSquare,
    faTelegram,
} from "@fortawesome/free-brands-svg-icons";
import { YMaps } from "react-yandex-maps";
import {
    Header,
    Footer,
    LeafletMap,
    ContactsYandexMap,
    ContactsZonesInfo,
} from "../components";
import "../css/contacts.css";
import { _getPlatform, _isObjEmpty } from "../components/helpers";
import { getDay } from "date-fns";
import ContactsSkeleton from "../components/Skeletons/ContactsSkeleton";
import useMetricFunctions from "../hooks/useMetricFunctions";

export default function Contacts() {
    const { mectricClickPhone } = useMetricFunctions();

    const { config } = useSelector(({ config }) => {
        return {
            config: config.data,
        };
    });

    const phones = config.CONFIG_home_phones
        ? config.CONFIG_home_phones.split(";")
        : [];

    // Получаем сегодняшний день недели
    const currentDayOfWeek =
        getDay(new Date()) === 0 ? 6 : getDay(new Date()) - 1;

    return (
        <>
            <Header />
            <Container
                className="contacts-page"
                sx={{
                    maxWidth: "1328px !important",
                }}
            >
                <h1 className="page-title" lang="ru">
                    Контакты
                </h1>

                {_isObjEmpty(config) ? (
                    <ContactsSkeleton />
                ) : (
                    <Grid container spacing={4}>
                        <Grid item sm={12} md={4} sx={{ width: 1 }}>
                            {config.CONFIG_address && (
                                <div className="contacts--block">
                                    <h4>Точки продаж</h4>
                                    <div className="contacts--adress">
                                        <div>
                                            <FontAwesomeIcon
                                                icon={faMapLocationDot}
                                            />
                                            {config.CONFIG_address}
                                        </div>
                                        <div>
                                            {phones &&
                                                phones.map(
                                                    (phone, index) =>
                                                        phone.replace(
                                                            /\D+/g,
                                                            ""
                                                        ) && (
                                                            <div
                                                                key={index}
                                                                className="contacts--phone"
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faPhone
                                                                    }
                                                                />
                                                                <a
                                                                    href={`tel:${phone.replace(
                                                                        /[^0-9+]+/g,
                                                                        ""
                                                                    )}`}
                                                                    onClick={
                                                                        mectricClickPhone
                                                                    }
                                                                    target="_parent"
                                                                >
                                                                    {phone}
                                                                </a>
                                                            </div>
                                                        )
                                                )}
                                        </div>
                                        {config.CONFIG_format_start_work &&
                                        config.CONFIG_format_end_work ? (
                                            <div className="contacts--schedule">
                                                <FontAwesomeIcon
                                                    icon={faClock}
                                                />
                                                Сегодня с{" "}
                                                {
                                                    config.CONFIG_format_start_work
                                                }{" "}
                                                до{" "}
                                                {config.CONFIG_format_end_work}
                                            </div>
                                        ) : (
                                            <div className="contacts--schedule">
                                                <FontAwesomeIcon
                                                    icon={faClock}
                                                />
                                                Сегодня закрыто
                                            </div>
                                        )}
                                        {config.CONFIG_filials?.length ? (
                                            <Divider
                                                sx={{
                                                    my: "8px",
                                                    width: "200px",
                                                }}
                                            />
                                        ) : null}
                                    </div>
                                    {config.CONFIG_filials?.map(
                                        (el, index, arr) => (
                                            <div className="contacts--adress">
                                                <div>
                                                    <FontAwesomeIcon
                                                        icon={faMapLocationDot}
                                                    />
                                                    {el.address}
                                                </div>
                                                {el.phones.map((phone) => {
                                                    if (phone) {
                                                        return (
                                                            <div className="contacts--phone">
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faPhone
                                                                    }
                                                                />
                                                                <a
                                                                    href={`tel:${phone.replace(
                                                                        /[^0-9+]+/g,
                                                                        ""
                                                                    )}`}
                                                                    onClick={
                                                                        mectricClickPhone
                                                                    }
                                                                    target="_parent"
                                                                >
                                                                    {phone}
                                                                </a>
                                                            </div>
                                                        );
                                                    }
                                                })}
                                                {
                                                    // Если у филиала свой график работы
                                                    el.workingTime ? (
                                                        el.workingTime[
                                                            currentDayOfWeek
                                                        ][0] &&
                                                        el.workingTime[
                                                            currentDayOfWeek
                                                        ][1] ? (
                                                            <div className="contacts--schedule">
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faClock
                                                                    }
                                                                />
                                                                Сегодня с{" "}
                                                                {
                                                                    el
                                                                        .workingTime[
                                                                        currentDayOfWeek
                                                                    ][0]
                                                                }{" "}
                                                                до{" "}
                                                                {
                                                                    el
                                                                        .workingTime[
                                                                        currentDayOfWeek
                                                                    ][1]
                                                                }
                                                            </div>
                                                        ) : (
                                                            <div className="contacts--schedule">
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faClock
                                                                    }
                                                                />
                                                                Сегодня закрыто
                                                            </div>
                                                        )
                                                    ) : // Если график работы филиала совпадает с основным
                                                    config.CONFIG_format_start_work &&
                                                      config.CONFIG_format_end_work ? (
                                                        <div className="contacts--schedule">
                                                            <FontAwesomeIcon
                                                                icon={faClock}
                                                            />
                                                            Сегодня с{" "}
                                                            {
                                                                config.CONFIG_format_start_work
                                                            }{" "}
                                                            до{" "}
                                                            {
                                                                config.CONFIG_format_end_work
                                                            }
                                                        </div>
                                                    ) : (
                                                        <div className="contacts--schedule">
                                                            <FontAwesomeIcon
                                                                icon={faClock}
                                                            />
                                                            Сегодня закрыто
                                                        </div>
                                                    )
                                                }
                                                {index ===
                                                arr.length - 1 ? null : (
                                                    <Divider
                                                        sx={{
                                                            my: "8px",
                                                            width: "200px",
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        )
                                    )}
                                </div>
                            )}

                            {config.CONFIG_additional_contacts ? (
                                <div className="contacts--block">
                                    <h4>Информация</h4>
                                    <div className="contacts--additional-info">
                                        <FontAwesomeIcon icon={faCircleInfo} />
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: config.CONFIG_additional_contacts,
                                            }}
                                        ></div>
                                    </div>
                                </div>
                            ) : null}

                            <div className="contacts--block">
                                <h4>Соцсети</h4>

                                {config.CONFIG_email && (
                                    <div className="contacts--email">
                                        <FontAwesomeIcon icon={faEnvelope} />{" "}
                                        <a
                                            href={
                                                _getPlatform() === "android" ||
                                                _getPlatform() === "ios"
                                                    ? `#`
                                                    : `mailto:${config.CONFIG_email}`
                                            }
                                        >
                                            {config.CONFIG_email}
                                        </a>
                                    </div>
                                )}
                                {config.CONFIG_vk && (
                                    <div className="contacts--vk">
                                        <FontAwesomeIcon icon={faVk} />{" "}
                                        <a
                                            href={config.CONFIG_vk}
                                            target="_blank"
                                        >
                                            {config.CONFIG_vk.replace(
                                                "https://",
                                                ""
                                            )}
                                        </a>
                                    </div>
                                )}
                                {config.CONFIG_fb && (
                                    <div className="contacts--fb">
                                        <a
                                            href={config.CONFIG_fb}
                                            target="_blank"
                                        >
                                            {config.CONFIG_fb.replace(
                                                "https://",
                                                ""
                                            )}
                                        </a>
                                    </div>
                                )}
                                {config.CONFIG_instagram && (
                                    <div className="contacts--instagram">
                                        <a
                                            href={config.CONFIG_instagram}
                                            target="_blank"
                                        >
                                            {config.CONFIG_instagram.replace(
                                                "https://",
                                                ""
                                            )}
                                        </a>
                                    </div>
                                )}
                                {config.CONFIG_ok && (
                                    <div className="contacts--ok">
                                        <FontAwesomeIcon
                                            icon={faOdnoklassnikiSquare}
                                        />{" "}
                                        <a
                                            href={config.CONFIG_ok}
                                            target="_blank"
                                        >
                                            {config.CONFIG_ok.replace(
                                                "https://",
                                                ""
                                            )}
                                        </a>
                                    </div>
                                )}
                                {config.CONFIG_tg && (
                                    <div className="contacts--tg">
                                        <FontAwesomeIcon icon={faTelegram} />{" "}
                                        <a
                                            href={config.CONFIG_tg}
                                            target="_blank"
                                        >
                                            {config.CONFIG_tg.replace(
                                                "https://",
                                                ""
                                            )}
                                        </a>
                                    </div>
                                )}
                            </div>
                            {/* <div className="contacts--block">
                            <h4>Время работы</h4>
                            {config && (
                                <div className="contacts--today-work">
                                    Сегодня с {config.CONFIG_format_start_work}{" "}
                                    до {config.CONFIG_format_end_work}
                                    {config.CONFIG_work_status && (
                                        <div
                                            className={`today-work--status ${config.CONFIG_work_status}`}
                                        >
                                            {config.CONFIG_work_status ===
                                            "open"
                                                ? " Сейчас открыто"
                                                : "Сейчас закрыто"}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div> */}

                            {config.CONFIG_legalInfo && (
                                <div className="contacts--block">
                                    <h4>Юридическая информация</h4>
                                    {config.CONFIG_legalInfo}
                                </div>
                            )}
                        </Grid>
                        <Grid item sm={12} md={8} sx={{ width: 1 }}>
                            {config.CONFIG_contact_map_type ===
                            "deliveryZones" ? (
                                <YMaps
                                    query={{
                                        apikey: config.deliveryZones
                                            ? config.deliveryZones.apiKey
                                            : "",
                                    }}
                                >
                                    <ContactsYandexMap />
                                </YMaps>
                            ) : config.CONFIG_contact_map_type === "iframe" &&
                              config.CONFIG_contact_map_script ? (
                                <div
                                    className="contacts--map"
                                    dangerouslySetInnerHTML={{
                                        __html: config.CONFIG_contact_map_script,
                                    }}
                                ></div>
                            ) : (
                                <div id="map" className="contacts--map">
                                    <LeafletMap />
                                </div>
                            )}
                            <ContactsZonesInfo />
                            {config.CONFIG_contact_delivery_info && (
                                <div
                                    className="contacts--delivery-info"
                                    dangerouslySetInnerHTML={{
                                        __html: config.CONFIG_contact_delivery_info.replace(
                                            /\n/g,
                                            "<br />"
                                        ),
                                    }}
                                ></div>
                            )}
                        </Grid>
                    </Grid>
                )}
            </Container>
            <Footer />
        </>
    );
}
