import React, { useState, useEffect } from "react";
import { Button, Dialog, IconButton, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { _getPlatform, _isMobile } from "./helpers";
import clsx from "clsx";
import { ReactComponent as CatSleepSvg } from "../img/cat-sleep.svg";
import { isWithinInterval, set } from "date-fns";
import "../css/hard-hours-modal.css";
import { useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const HardHoursModal = () => {
    const config = useSelector((state) => state.config.data);

    const hardHoursImg = window.CONFIG_platform_off_image;

    const [open, setOpen] = useState(false);

    const hardHoursStrStart =
        config.CONFIG_hard_hours_strStart || "01.01.1970 00:00";

    const hardHoursStrEnd =
        config.CONFIG_hard_hours_strEnd || "01.01.1970 00:00";

    const hardHoursDateStart = set(new Date(), {
        year: hardHoursStrStart.slice(6, 10),
        month: parseInt(hardHoursStrStart.slice(3, 5)) - 1,
        date: hardHoursStrStart.slice(0, 2),
        hours: hardHoursStrStart.slice(11, 13),
        minutes: hardHoursStrStart.slice(14),
        seconds: 0,
        milliseconds: 0,
    });

    const hardHoursDateEnd = set(new Date(), {
        year: hardHoursStrEnd.slice(6, 10),
        month: parseInt(hardHoursStrEnd.slice(3, 5)) - 1,
        date: hardHoursStrEnd.slice(0, 2),
        hours: hardHoursStrEnd.slice(11, 13),
        minutes: hardHoursStrEnd.slice(14),
        seconds: 0,
        milliseconds: 0,
    });

    const checkIsWithinHardHours = () => {
        try {
            return isWithinInterval(new Date(), {
                start: hardHoursDateStart,
                end: hardHoursDateEnd,
            });
        } catch (error) {
            console.log(
                `${error.message}, Something wrong in hard hours interval`
            );
            return false;
        }
    };

    const isHardHourApplied = checkIsWithinHardHours();

    useEffect(() => {
        if (
            isHardHourApplied &&
            config.CONFIG_maintenance_type === "hardHours"
        ) {
            setOpen(true);
        }
    }, [isHardHourApplied, config.CONFIG_maintenance_type]);

    if (!isHardHourApplied || config.CONFIG_maintenance_type !== "hardHours") {
        return null;
    }

    const handleModalClose = () => {
        setOpen(false);
    };

    let dialogProps = { open: open, maxWidth: "md" };
    if (_isMobile()) {
        dialogProps.TransitionComponent = Transition;
        dialogProps.fullScreen = true;
        dialogProps.scroll = "body";
    }

    return (
        <Dialog
            maxWidth="md"
            {...dialogProps}
            sx={{
                "& .MuiPaper-root": {
                    borderRadius: _isMobile() ? "0px" : "20px",
                },
            }}
            onClose={(event, reason) => {
                if (reason === "escapeKeyDown") {
                    handleModalClose();
                }
                if (
                    reason === "backdropClick" &&
                    config.CONFIG_close_modal_by_cross !== "active"
                ) {
                    handleModalClose();
                }
            }}
        >
            <div className="modal-alert--wrapper hard-hours-modal">
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleModalClose}
                    aria-label="close"
                    className={clsx(
                        "modal-close",
                        _getPlatform() === "vk" && "vk"
                    )}
                >
                    <CloseIcon />
                </IconButton>
                {hardHoursImg ? (
                    <img src={hardHoursImg} className="cat-sleep-img" />
                ) : (
                    <CatSleepSvg className="cat-sleep-img" />
                )}
                <h2 className="main-color">
                    {config.CONFIG_hard_hours_title || "Высокая нагрузка"}
                </h2>
                <div
                    dangerouslySetInnerHTML={{
                        __html:
                            config.CONFIG_hard_hours_text ||
                            "Сейчас у нас много заказов, поэтому время приготовления и доставки увеличено. Приносим извинения за предоставленные неудобства.",
                    }}
                    style={{ whiteSpace: "pre-line" }}
                ></div>
                <Button
                    className="btn--action"
                    variant="contained"
                    onClick={handleModalClose}
                    sx={{ mt: "20px" }}
                >
                    Смотреть меню
                </Button>
            </div>
        </Dialog>
    );
};

export default HardHoursModal;
