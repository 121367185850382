import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    setProducts,
    setCategories,
    setAddonProducts,
    setRecommendProducts,
    setBonusesProducts,
    setProductsStatus,
    setTopSalesProducts,
} from "./redux/actions/products";
import { setConfig, setConfigStatus } from "./redux/actions/config";
import { setGateways } from "./redux/actions/gateways";
import { setBanners } from "./redux/actions/banners";
import { logout, login } from "./redux/actions/user";
import { setTopMenu, setBottomMenu, setSales } from "./redux/actions/pages";
import { setStories } from "./redux/actions/stories";
import { setAutoDiscounts } from "./redux/actions/autoDiscounts";
import {
    AuthModal,
    InstallApp,
    SystemAlerts,
    WeClosed,
    SaleModal,
    GoogleRecaptchaWrapper,
    HardHoursModal,
    Actualizer,
    MetrikScripts,
} from "./components";
import {
    _getDomain,
    _getPlatform,
    _isCategoryDisabled,
    _isMobile,
    _isObjEmpty,
    _isPromocodesEnabled,
} from "./components/helpers";
import { ru } from "date-fns/locale";
import { setDefaultOptions } from "date-fns";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet";
import "./fonts/cera/CeraRoundProMedium.woff2";
import "./fonts/cera/CeraRoundProBold.woff2";
import "./App.css";
import bridge from "@vkontakte/vk-bridge";
import useChoosenTown from "./hooks/useChoosenTown";
import useApiRequests from "./hooks/useApiRequests";
import useOrganizationLD from "hooks/useOrganizationLD";

function App() {
    const { choosenTown } = useChoosenTown();
    const dispatch = useDispatch();
    setDefaultOptions({ locale: ru });
    const navigate = useNavigate();
    const { getRequestAPI } = useApiRequests();
    const { organizationLD } = useOrganizationLD();

    const configStatus = useSelector((state) => state.config.status);
    const isConfigEmpty = useSelector((state) =>
        _isObjEmpty(state.config.data)
    );
    const vkGroupId = useSelector(
        (state) => state.config.data.CONFIG_vk_group_id
    );

    const { user } = useSelector((state) => state.user);

    useEffect(() => {
        dispatch(setConfigStatus(false));
        dispatch(setProductsStatus(false));
        getRequestAPI("getSiteData").then((resp) => {
            dispatch(setConfig(resp.data.config));
            dispatch(setTopMenu(resp.data.top_menu));
            dispatch(setBottomMenu(resp.data.bottom_menu));
            dispatch(setSales(resp.data.sales));
            dispatch(setGateways(resp.data.gateways));
            dispatch(setBanners(resp.data.banners));
            dispatch(setStories(resp.data.stories));
            dispatch(setAutoDiscounts(resp.data.autoDiscounts));
            dispatch(setConfigStatus(true));
            dispatch(setCategories(resp.data.categories));
            dispatch(setProducts(resp.data.products));
            dispatch(setAddonProducts(resp.data.addon_products));
            dispatch(setRecommendProducts(resp.data.recommend_products));
            dispatch(setTopSalesProducts(resp.data.top_sales_products));
            dispatch(setBonusesProducts(resp.data.bonuses_products));
            dispatch(setProductsStatus(true));
        });
    }, [dispatch, choosenTown]);

    useEffect(() => {
        if (user.token && user.phone) {
            const queryParams = "&token=" + user.token + "&phone=" + user.phone;

            getRequestAPI("checkLogin", queryParams, { mode: "no-cors" }).then(
                (resp) => {
                    resp.data.status === "success"
                        ? dispatch(login(resp.data.user))
                        : dispatch(logout(true));
                }
            );
        }
    }, [dispatch]);

    useEffect(() => {
        if (_getPlatform() === "vk" && configStatus && vkGroupId) {
            let VKPageUrlHash = window.location.hash;
            if (window.location.href.includes("vk_access_token_settings")) {
                VKPageUrlHash = VKPageUrlHash.replace("#", "").replace(
                    "*",
                    "#"
                );

                navigate(VKPageUrlHash, { replace: true });
            }

            // Меняем хэш на странице vk-mini-app при изменении url внутри react app
            setInterval(() => {
                let reactAppUrlWithoutBaseUrl = window.location.href
                    .replace(window.location.origin, "")
                    .replace("#", "*");

                if (VKPageUrlHash !== reactAppUrlWithoutBaseUrl) {
                    bridge.send("VKWebAppSetLocation", {
                        location: reactAppUrlWithoutBaseUrl,
                    });

                    VKPageUrlHash = reactAppUrlWithoutBaseUrl;
                }
            }, 500);

            // Запрос на разрешение сообщений от группы
            bridge
                .send("VKWebAppStorageGet", {
                    keys: ["messagesFromGroupLastRequest"],
                })
                .then((data) => {
                    const lastRequestTime = +data.keys[0].value;
                    if (Date.now() - lastRequestTime > 1000 * 3600 * 24 * 5) {
                        setTimeout(() => {
                            bridge
                                .send("VKWebAppAllowMessagesFromGroup", {
                                    group_id: vkGroupId,
                                })
                                .catch(() => {
                                    bridge.send("VKWebAppStorageSet", {
                                        key: "messagesFromGroupLastRequest",
                                        value: `${Date.now()}`,
                                    });
                                });
                        }, 15000);
                    }
                });
        }
    }, [configStatus, vkGroupId]);

    if (window.townsTypeRoute === "folder" && !choosenTown) {
        navigate("/");
    }

    return (
        <div>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(organizationLD)}
                </script>
            </Helmet>
            <Box>
                <Outlet />
                {configStatus ? (
                    <GoogleRecaptchaWrapper>
                        <AuthModal />
                    </GoogleRecaptchaWrapper>
                ) : null}
                <SaleModal />
                <SystemAlerts />
                <InstallApp />
                {isConfigEmpty ? null : <WeClosed />}
                {isConfigEmpty ? null : <HardHoursModal />}
                {isConfigEmpty ? null : <Actualizer />}
                {configStatus ? <MetrikScripts /> : null}
            </Box>
        </div>
    );
}

export default App;
