import React from "react";
import { useSelector } from "react-redux";
import "../../css/checkout.css";
import ProductSalePrice from "./ProductSalePrice";

export default function CheckoutProduct({
    productCart,
    productCount,
    productTotalPrice,
    productIndex,
}) {
    let dataAttributes = {};
    if (productCart.attributes) {
        Object.values(productCart.attributes).forEach((value, index) => {
            dataAttributes[index] = value.name;
        });
    }

    const { promocodeProducts, promocode } = useSelector(({ cart }) => {
        return {
            promocodeProducts: cart.promocodeProducts,
            promocode: cart.promocode,
        };
    });

    const renderCheckoutProductResult = () => {
        // Цена по промокоду на %
        if (parseInt(productCart.options._promocode_price)) {
            if (productCart.type === "variations") {
                return (
                    <ProductSalePrice
                        oldPrice={productCart.options._price}
                        price={productCart.options._promocode_price}
                    />
                );
            } else {
                return (
                    <ProductSalePrice
                        oldPrice={productCart.options._price * productCount}
                        price={productTotalPrice}
                    />
                );
            }
        }

        // Цена товара со скидкой
        if (
            productCart.type === "simple" &&
            parseInt(productCart.options._regular_price) >
                parseInt(productCart.options._sale_price)
        ) {
            return (
                <ProductSalePrice
                    oldPrice={
                        productCart.options._regular_price * productCount +
                        (productCart.modificatorsAmount ?? 0)
                    }
                    price={productTotalPrice}
                />
            );
        }
        if (
            productCart.type === "variations" &&
            parseInt(productCart.variant?._regular_price) >
                parseInt(productCart.variant?.price)
        ) {
            return (
                <ProductSalePrice
                    oldPrice={
                        parseInt(productCart.variant?._regular_price) +
                        productCart.modificatorsAmount
                    }
                    price={productCart.options._price}
                />
            );
        }

        // Цена без скидкой и промокодов
        if (productCart.type === "variations") {
            return (
                <span>
                    {parseInt(productCart.options._price).toLocaleString(
                        "ru-RU"
                    )}{" "}
                    &#8381;
                </span>
            );
        }
        return (
            <span>
                {parseInt(productTotalPrice).toLocaleString("ru-RU")} &#8381;
            </span>
        );
    };

    return (
        <div className="checkout--product" data-product-id={productCart.id}>
            <div className="checkout--product-name">
                {productCart.title} x {productCount} шт.
                <br />
                {productCart.type === "variations" && (
                    <div className="minicart--product-attributes">
                        {Object.values(productCart.variant.attributes).map(
                            (attribute, keyAttr) => (
                                <div
                                    className="minicart--product-attribute"
                                    key={keyAttr}
                                >
                                    {dataAttributes[keyAttr]}: {attribute}
                                </div>
                            )
                        )}
                    </div>
                )}
                {productCart.choosenModificators?.length ? (
                    <div className="minicart--product-attributes">
                        +{" "}
                        {productCart.choosenModificators.map((el, inx, array) =>
                            inx !== array.length - 1 ? (
                                <span key={el.id}>
                                    {el.title} {el.count} шт,{" "}
                                </span>
                            ) : (
                                <span key={el.id}>
                                    {el.title} {el.count} шт
                                </span>
                            )
                        )}
                    </div>
                ) : null}
                {productCart.type === "variations" ? (
                    productCart.variant.weight && (
                        <div className="minicart--product-attributes">
                            <div className="weight">
                                {productCart.variant.weight} гр.
                            </div>
                        </div>
                    )
                ) : (
                    <div className="minicart--product-attributes">
                        {productCart.options.weight ? (
                            <div className="weight">
                                {productCart.options.weight} гр.
                            </div>
                        ) : (
                            ""
                        )}
                        {productCart.options.count_rolls ? (
                            <div className="count-rolls">
                                {productCart.options.count_rolls} шт.
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                )}
            </div>

            <div className="checkout--product-result">
                {renderCheckoutProductResult()}
            </div>
        </div>
    );
}
