import { useSelector } from "react-redux";

import {
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Grid,
    Typography,
} from "@mui/material";
import PromocodeButton from "./PromocodeButton";
import useContentOverflow from "hooks/useContentOverflow";
import { _isPromocodesEnabled } from "./helpers";

const SaleCard = ({ sale, handleSetActiveSale }) => {
    const { contentOverflow, containerRef, contentRef } = useContentOverflow();
    const config = useSelector((state) => state.config.data);

    return (
        <Grid
            item
            xs={12}
            md={4}
            lg={window.fullWidthSite ? 3 : 4}
            key={sale.saleID}
        >
            <Card className="sale">
                <CardActionArea
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        flexGrow: 1,
                    }}
                    onClick={() => handleSetActiveSale(sale)}
                >
                    {sale.saleImg ? (
                        <CardMedia
                            component="img"
                            image={sale.saleImg}
                            alt={sale.saleTitle}
                        />
                    ) : null}
                    <CardContent
                        sx={{
                            flexGrow: 1,
                            bgcolor: "var(--modal-background)",
                            width: "100%",
                        }}
                    >
                        <div className="sale--content-inner-wrapper">
                            <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                            >
                                {sale.saleTitle}
                            </Typography>
                            <Typography variant="body2">
                                <div
                                    ref={containerRef}
                                    className="sale--short-content"
                                >
                                    <div
                                        ref={contentRef}
                                        dangerouslySetInnerHTML={{
                                            __html: sale.saleContent,
                                        }}
                                    />
                                </div>
                            </Typography>
                            {contentOverflow && (
                                <div className="sale--fade">
                                    <span>Подробнее</span>
                                </div>
                            )}
                        </div>
                    </CardContent>
                </CardActionArea>
                {sale.promocodeCode && _isPromocodesEnabled(config) ? (
                    <CardActions sx={{ padding: 2 }}>
                        <PromocodeButton code={sale.promocodeCode} />
                    </CardActions>
                ) : null}
            </Card>
        </Grid>
    );
};

export default SaleCard;
