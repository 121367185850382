import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useApiRequests from "./useApiRequests";
import { _checkPromocode } from "components/helpers";
import {
    addPromocode,
    completelyRemovePromocode,
    setConditionalPromocode,
} from "redux/actions/cart";
import { updateAlerts } from "redux/actions/systemAlerts";

const usePromocode = (props) => {
    const dispatch = useDispatch();
    const { postRequestAPI } = useApiRequests();

    const { onApply = () => {}, onError = () => {} } = props || {};

    const [loading, setLoading] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState("");

    const { user, config, promocodeProduct, cartProducts, cartSubTotalPrice } =
        useSelector(({ user, cart, config }) => {
            return {
                user: user.user,
                config: config.data,
                promocodeProduct: cart.promocodeProducts,
                cartProducts: cart.items,
                cartTotal: cart.totalPrice,
                cartSubTotalPrice: cart.subTotalPrice,
            };
        });

    const handleApplyPromocode = (code, withToast) => {
        setLoading(true);

        postRequestAPI("getPromocode", "", {
            promocode: code,
            cartProducts: cartProducts,
            token: user.token ? user.token : false,
            phone: user.phone ? user.phone : false,
        }).then((resp) => {
            setLoading(false);
            if (resp.data.status === "error") {
                setAlertMessage(resp.data.message);
                onError();
                if (withToast) {
                    dispatch(
                        updateAlerts({
                            open: true,
                            message: resp.data.message,
                        })
                    );
                }
            } else {
                setAlertMessage("");
                onApply();
                const resultCheckPromocode = _checkPromocode({
                    promocode: resp.data.promocode,
                    promocodeProduct,
                    items: cartProducts,
                    cartTotal: cartSubTotalPrice,
                    config,
                    isInitial: true,
                });
                if (resultCheckPromocode.status === "error") {
                    dispatch(setConditionalPromocode(resp.data.promocode));
                    if (withToast) {
                        dispatch(
                            updateAlerts({
                                open: true,
                                message: "Промокод добавлен в корзину.",
                            })
                        );
                    }
                } else {
                    setAlertMessage("");
                    dispatch(addPromocode(resp.data.promocode));
                    if (withToast) {
                        dispatch(
                            updateAlerts({
                                open: true,
                                message: "Промокод добавлен в корзину.",
                            })
                        );
                    }
                }
            }
        });
    };

    const handleRemovePromocode = () => {
        dispatch(completelyRemovePromocode());
        dispatch(
            updateAlerts({
                open: true,
                message: "Промокод отменен.",
            })
        );
    };

    const handleCloseAlertMessage = () => {
        setAlertMessage("");
    };

    return {
        handleApplyPromocode,
        handleRemovePromocode,
        loading,
        alertMessage,
        handleCloseAlertMessage,
    };
};

export default usePromocode;
