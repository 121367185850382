import { useSelector } from "react-redux";

import BootstrapTooltip from "./BootstrapTooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const CheckoutDeliveryResult = ({ deliveryPrice }) => {
    const deliveryZone = useSelector(
        (state) => state.deliveryAddressModal?.deliveryZone
    );

    const CONFIG_checkout_hide_delivery_price = useSelector(
        (state) => state.config.data.CONFIG_checkout_hide_delivery_price
    );

    const tooltipContent = () => {
        return (
            <div>
                {!!deliveryZone?.orderMinPrice && (
                    <div>
                        Минимальная сумма заказа от{" "}
                        {deliveryZone?.orderMinPrice} ₽
                    </div>
                )}
                {!!deliveryZone?.deliveryPrice &&
                    CONFIG_checkout_hide_delivery_price !== "active" && (
                        <div>
                            Стоимость доставки {deliveryZone?.deliveryPrice} ₽
                        </div>
                    )}
                {!!deliveryZone?.freeDeliveryOrder &&
                    CONFIG_checkout_hide_delivery_price !== "active" && (
                        <div>
                            Бесплатная доставка от{" "}
                            {deliveryZone?.freeDeliveryOrder} ₽
                        </div>
                    )}
                {!!deliveryZone?.deliveryTime && (
                    <div>
                        Время доставки от {deliveryZone?.deliveryTime} мин.
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="result-delivery">
            <div className="result-delivery--title-container">
                <span>Доставка</span>
                {!!deliveryZone && (
                    <BootstrapTooltip
                        placement="top"
                        enterTouchDelay={0}
                        title={tooltipContent()}
                    >
                        <InfoOutlinedIcon className="checkout--info-icon" />
                    </BootstrapTooltip>
                )}
            </div>
            <span>
                {deliveryPrice === 0
                    ? "Бесплатно"
                    : `${deliveryPrice.toLocaleString("ru-RU")} ₽`}
            </span>
        </div>
    );
};

export default CheckoutDeliveryResult;
