import { stepButtonClasses } from "@mui/material";

const initialState = {
    banners: [],
    bannersMobile: [],
};

const banners = (state = initialState, action) => {
    switch (action.type) {
        case "SET_BANNERS":
            return {
                ...stepButtonClasses,
                ...action.payload,
            };
        case "CLEAN_STORE": {
            return { ...initialState };
        }
        default:
            return state;
    }
};

export default banners;
