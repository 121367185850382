import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Container, Grid } from "@mui/material";
import { Footer, Header, TopCategoriesMenu } from "../../components";
import AdditionalPaymentInfo from "./AdditionalPaymentInfo";
import AdditionalAccrueInfo from "./AdditionalAccrueInfo";
import AccrueInfo from "./AccrueInfo";
import AdditionalBonusesInfo from "./AdditionalBonusesInfo";
import UserBonusesInfo from "./UserBonusesInfo";
import { _isMobile, _isObjEmpty } from "../../components/helpers";
import "../../css/bonus-page.css";
import useTownNavigate from "../../hooks/useTownNavigate";
import BonusPageSkeleton from "../../components/Skeletons/BonusPageSkeleton";

const BonusesPage = () => {
    const { navigateTo } = useTownNavigate();

    const bonusProgrammStatus = useSelector(
        (state) => state.config.data?.bonusProgramm?.status
    );

    const configStatus = useSelector(
        (state) => !_isObjEmpty(state.config.data)
    );

    useEffect(() => {
        if (configStatus && bonusProgrammStatus !== "active") {
            navigateTo("/");
        }
    }, [configStatus, bonusProgrammStatus]);

    return (
        <Box
            sx={{ display: "flex", flexDirection: "column", height: "100vh" }}
            className="bonus-page"
        >
            <Header />
            <Container sx={{ flexGrow: "1" }}>
                <h1>Бонусная программа</h1>
                {configStatus ? (
                    <Grid
                        container
                        justifyContent="space-between"
                        wrap="wrap-reverse"
                        alignItems="flex-end"
                    >
                        <Grid item xs={12} desctop={8}>
                            <AccrueInfo />

                            <AdditionalBonusesInfo />

                            <AdditionalAccrueInfo />

                            <AdditionalPaymentInfo />
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            desctop={3}
                            sx={{
                                position: "sticky",
                                height: "fit-content",
                                top: "80px",
                                ["@media (max-width: 899px)"]: {
                                    position: "static",
                                },
                            }}
                        >
                            <UserBonusesInfo />
                        </Grid>
                    </Grid>
                ) : (
                    <BonusPageSkeleton />
                )}
            </Container>
            <Footer />
        </Box>
    );
};

export default BonusesPage;
