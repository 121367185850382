import { Skeleton } from "@mui/material";

const ProductPageSkeleton = () => {
    return (
        <div className="product-modal">
            <div className="product-page--image">
                <Skeleton
                    variant="rounded"
                    width={"100%"}
                    height={"100%"}
                    sx={{ aspectRatio: "1", borderRadius: "20px" }}
                />
            </div>
            <div className="product-modal--info no-bg">
                <h1
                    className="product-modal--title"
                    style={{ marginBottom: 10 }}
                >
                    <Skeleton
                        variant="rounded"
                        width={300}
                        height={30}
                        sx={{ borderRadius: "20px" }}
                    />
                </h1>
                <div className="product-modal--description">
                    <Skeleton width={"80%"} />
                    <Skeleton width={"90%"} />
                    <Skeleton width={200} />
                </div>
                <div className="variations-buying">
                    <div className="product-modal--price-wrapper">
                        <Skeleton
                            variant="rounded"
                            width={100}
                            height={28}
                            sx={{ borderRadius: "20px" }}
                        />
                    </div>
                    <Skeleton
                        variant="rounded"
                        height={40}
                        sx={{ borderRadius: "20px", flexBasis: "50%" }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProductPageSkeleton;
