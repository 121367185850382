import { Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";

const BonusPageSkeleton = () => {
    return (
        <div>
            <Skeleton variant="text" animation="wave" sx={{ width: 0.5 }} />
            <Skeleton variant="text" animation="wave" sx={{ width: 0.25 }} />
            <Skeleton variant="text" animation="wave" sx={{ width: 0.15 }} />
            <Skeleton variant="text" animation="wave" sx={{ width: 0.45 }} />
            <Skeleton variant="text" animation="wave" sx={{ width: 0.55 }} />
            <Skeleton variant="text" animation="wave" sx={{ width: 0.15 }} />
            <Skeleton variant="text" animation="wave" sx={{ width: 0.15 }} />
            <Skeleton variant="text" animation="wave" sx={{ width: 0.25 }} />
            <Skeleton variant="text" animation="wave" sx={{ width: 0.35 }} />
            <Skeleton variant="text" animation="wave" sx={{ width: 0.35 }} />
            <Skeleton variant="text" animation="wave" sx={{ width: 0.25 }} />
            <Skeleton variant="text" animation="wave" sx={{ width: 0.15 }} />
            <Skeleton variant="text" animation="wave" sx={{ width: 0.5 }} />
            <Skeleton variant="text" animation="wave" sx={{ width: 0.25 }} />
            <Skeleton variant="text" animation="wave" sx={{ width: 0.15 }} />
            <Skeleton variant="text" animation="wave" sx={{ width: 0.45 }} />
            <Skeleton variant="text" animation="wave" sx={{ width: 0.55 }} />
            <Skeleton variant="text" animation="wave" sx={{ width: 0.15 }} />
            <Skeleton variant="text" animation="wave" sx={{ width: 0.45 }} />
            <Skeleton variant="text" animation="wave" sx={{ width: 0.65 }} />
            <Skeleton variant="text" animation="wave" sx={{ width: 0.6 }} />
            <Skeleton variant="text" animation="wave" sx={{ width: 0.55 }} />
        </div>
    );
};

export default BonusPageSkeleton;
