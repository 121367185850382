import { Skeleton } from "@mui/material";

export const MobileSceletonComponent = () => {
    return (
        <>
            {[...Array(4)].map((_, inx) => (
                <li key={inx}>
                    <Skeleton
                        width={100}
                        height={32}
                        variant="rounded"
                        sx={{
                            borderRadius: "15px",
                            mr: "15px",
                        }}
                    />
                </li>
            ))}
        </>
    );
};

export const SceletonComponent = () => {
    return (
        <ul className="categories-menu">
            {[...Array(5)].map((_, inx) => (
                <li key={inx}>
                    <Skeleton
                        width={100}
                        height={32}
                        variant="rounded"
                        sx={{
                            bgcolor: "rgba(255, 255, 255, 0.11)",
                            borderRadius: "15px",
                            mr: "15px",
                        }}
                    />
                </li>
            ))}
        </ul>
    );
};
