export const setProductsStatus = (status) => ({
    type: "SET_PRODUCTS_STATUS",
    payload: status,
});

export const setProducts = (products) => ({
    type: "SET_PRODUCTS",
    payload: products,
});

export const setAddonProducts = (products) => ({
    type: "SET_ADDON_PRODUCTS",
    payload: products,
});

export const setRecommendProducts = (products) => ({
    type: "SET_RECOMMEND_PRODUCTS",
    payload: products,
});

export const setTopSalesProducts = (products) => ({
    type: "SET_TOP_SALES_PRODUCTS",
    payload: products,
});

export const setBonusesProducts = (products) => ({
    type: "SET_BONUSES_PRODUCTS",
    payload: products,
});

export const setCategories = (categories) => ({
    type: "SET_CATEGORIES",
    payload: categories,
});
