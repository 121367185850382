import { useParams } from "react-router";
import { Link } from "react-router-dom";

const TownLink = (props) => {
    const params = useParams();

    const choosenTown =
        window.townsTypeRoute === "folder"
            ? window.towns?.find((town) => params.town === town.slug)
            : null;

    let rootTownPathname = "/";

    if (choosenTown) {
        rootTownPathname += choosenTown.slug;
    }

    let pathString = "";

    if (props.to === "/") {
        pathString = rootTownPathname;
    } else {
        if (choosenTown) {
            pathString += "/" + choosenTown.slug;
        }
        pathString += props.to;
    }

    return <Link {...props} to={pathString}></Link>;
};

export default TownLink;
