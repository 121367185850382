import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useTownNavigate from "../hooks/useTownNavigate";
import Fuse from "fuse.js";
import { TextField, Autocomplete, Button, Popper, Grid } from "@mui/material";
import { Product } from "../components";
import { _isCategoryDisabled } from "./helpers";
import { useSearchParams } from "react-router-dom";

const CustomPopper = (props) => {
    return (
        <Popper
            sx={{
                "& .MuiPaper-root": {
                    borderRadius: "20px",
                    mt: "3px",
                },
            }}
            {...props}
        />
    );
};

const SearchBar = ({
    dontShowList,
    dontShowButton,
    products = [],
    handleFilter = () => {},
}) => {
    const { navigateTo } = useTownNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const searchQuery = searchParams.get("query");

    const { categories } = useSelector((state) => state.products);

    const [inputValue, setInputValue] = useState(
        searchQuery ? searchQuery : null
    );
    const [filteredProducts, setFilteredProducts] = useState(null);
    const [optionsOpen, setOptionsOpen] = useState(false);

    const fuse = new Fuse(products, {
        keys: ["title"],
        minMatchCharLength: 1,
        threshold: 0.2,
    });

    useEffect(() => {
        if (searchQuery) {
            const temp = fuse.search(searchQuery);
            setFilteredProducts(temp);
        }
    }, [dispatch]);

    useEffect(() => {
        if (dontShowList && filteredProducts && filteredProducts.length) {
            const temp = filteredProducts.map((el) => el.item);
            handleFilter(temp);
        } else if (dontShowList && inputValue) {
            handleFilter([]);
        } else if (dontShowList && !inputValue) {
            handleFilter(null);
        }
    }, [filteredProducts, inputValue]);

    const disabledCategoriesId = categories
        ?.filter((el) => _isCategoryDisabled(el).disabled)
        .map((el) => el.term_id);

    const inputChangeHandler = (value) => {
        setInputValue(value);
        const temp = fuse.search(value);
        setFilteredProducts(temp);
        if (value.length >= 1) {
            setOptionsOpen(true);
        } else setOptionsOpen(false);
    };

    const filteredPoructsList =
        dontShowList ? null : filteredProducts?.length ? (
            <Grid container spacing={{ xs: 0, sm: 2 }}>
                {filteredProducts.map((el) => (
                    <Product
                        product={el.item}
                        disabled={el.item.categories.some((r) =>
                            disabledCategoriesId?.includes(r)
                        )}
                        key={el.id}
                    />
                ))}
            </Grid>
        ) : (
            <div>К сожалению, ничего не найдено</div>
        );

    const handleSearch = () => {
        navigateTo(`/search${inputValue ? "?query=" + inputValue : ""}`);
    };

    return (
        <>
            <div
                style={{
                    display: "flex",
                    height: "40px",
                    marginBottom: "16px",
                }}
            >
                <Autocomplete
                    freeSolo
                    autoSelect={true}
                    inputValue={inputValue || ""}
                    id="search-bar"
                    open={optionsOpen}
                    onClose={() => setOptionsOpen(false)}
                    options={products}
                    getOptionLabel={(option) =>
                        option.title ? option.title : ""
                    }
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.title}
                            </li>
                        );
                    }}
                    onChange={(e, value, reason) => {
                        if (reason === "blur") {
                            return;
                        }
                        if (value) {
                            inputChangeHandler(value.title);
                        } else {
                            inputChangeHandler("");
                            setFilteredProducts(null);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            label="Поиск товаров"
                            value={inputValue}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    handleSearch();
                                }
                            }}
                            inputProps={{
                                ...params.inputProps,
                                onKeyDown: (e) => {
                                    if (e.key === "Enter") {
                                        e.stopPropagation();
                                    }
                                },
                            }}
                            onChange={(e) => {
                                inputChangeHandler(e.target.value);
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <div
                                        onClick={() => {
                                            inputChangeHandler("");
                                            setFilteredProducts(null);
                                        }}
                                    >
                                        {params.InputProps.endAdornment}
                                    </div>
                                ),
                            }}
                            sx={{
                                "& .MuiInputBase-root": {
                                    borderRadius: "20px !important",
                                    bgcolor: "var(--background-color)",
                                    height: "40px",
                                },
                            }}
                        />
                    )}
                    PopperComponent={CustomPopper}
                    sx={{
                        flexGrow: 1,
                        "& .MuiAutocomplete-endAdornment": {
                            position: "relative",
                        },
                    }}
                />
                {dontShowButton ? null : (
                    <Button
                        className="btn--action"
                        variant="outlined"
                        sx={{ width: "100px", ml: 1 }}
                        onClick={handleSearch}
                    >
                        Найти
                    </Button>
                )}
            </div>
            {filteredPoructsList}
        </>
    );
};

export default SearchBar;
