import { useSelector } from "react-redux";

const useOrganizationLD = (props) => {
    const CONFIG_company_name = useSelector(
        (state) => state.config.data.CONFIG_company_name
    );
    const CONFIG_format_phone = useSelector(
        (state) => state.config.data.CONFIG_format_phone
    );
    const CONFIG_email = useSelector((state) => state.config.data.CONFIG_email);
    const CONFIG_vk = useSelector((state) => state.config.data.CONFIG_vk);
    const CONFIG_company_logo_main = useSelector(
        (state) => state.config.data.CONFIG_company_logo_main
    );
    const CONFIG_town = useSelector((state) => state.config.data.CONFIG_town);
    const CONFIG_address = useSelector(
        (state) => state.config.data.CONFIG_address
    );

    const organizationLD = {
        "@context": "https://schema.org",
        "@type": "Organization",
        name: CONFIG_company_name,
        telephone: CONFIG_format_phone,
        email: CONFIG_email,
        sameAs: [CONFIG_vk],
        logo: CONFIG_company_logo_main,
        address: {
            "@type": "PostalAddress",
            addressCountry: "RU",
            addressLocality: `Россия, ${CONFIG_town}`,
            streetAddress: CONFIG_address,
        },
    };

    return { organizationLD };
};

export default useOrganizationLD;
