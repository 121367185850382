const initialState = {
    activeSale: null,
};

const saleModal = (state = initialState, action) => {
    switch (action.type) {
        case "SET_MODAL_SALE": {
            return {
                ...state,
                activeSale: action.payload,
            };
        }
        case "CLEAN_STORE": {
            return { ...initialState };
        }
        default:
            return state;
    }
};

export default saleModal;
