import { ChooseTown } from "../components";
import "../css/choose-town-page.css";

const ChooseTownPage = () => {
    const logoAlign = window.CONFIG_logo_align;

    return (
        <div className="choose-town-page">
            <div className="choose-town-page--logo-container">
                {window.CONFIG_company_logo_main ? (
                    <img
                        src={
                            window.CONFIG_design_dark_theme !== "active"
                                ? window.CONFIG_company_logo_main
                                : window.CONFIG_company_logo_footer
                        }
                        alt="Логотип"
                    />
                ) : null}
            </div>
            <div className="choose-town-page--page-backdrop">
                <div className="choose-town-page--block">
                    <div
                        className="block--image-container"
                        style={{
                            textAlign:
                                logoAlign === "center"
                                    ? "center"
                                    : logoAlign === "right"
                                    ? "end"
                                    : "start",
                        }}
                    >
                        {window.CONFIG_company_logo_main ? (
                            <img
                                src={
                                    window.CONFIG_design_dark_theme !== "active"
                                        ? window.CONFIG_company_logo_main
                                        : window.CONFIG_company_logo_footer
                                }
                                alt="Логотип"
                            />
                        ) : null}
                    </div>
                    <ChooseTown />
                </div>
            </div>
        </div>
    );
};

export default ChooseTownPage;
