import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useTownNavigate from "../hooks/useTownNavigate";
import { Alert, Button, Box, Container, Skeleton, Grid } from "@mui/material";
import {
    Header,
    Footer,
    TopCategoriesMenu,
    MobileMiniCart,
    Product,
    ProductModal,
    SearchBar,
    FooterBonuses,
} from "../components";
import {
    _isMobile,
    _clone,
    _isCategoryDisabled,
    _isObjEmpty,
} from "../components/helpers";
import { ProductsListSkeleton } from "../components/Skeletons/ProductsListSkeleton";

const CategoryPage = () => {
    const params = useParams();
    const { navigateTo } = useTownNavigate();

    const { configStatus, config, productLayout } = useSelector(
        ({ config }) => {
            return {
                configStatus: !_isObjEmpty(config.data),
                config: config.data,
                productLayout: config.data.CONFIG_type_products,
            };
        }
    );

    const { products, categories, productsStatus } = useSelector(
        ({ products, productModal }) => {
            return {
                products: products.items,
                categories: products.categories,
                productsStatus: products.status,
            };
        }
    );

    const [allProducts, setAllProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [activeCategoryTags, setActiveCategoryTags] = useState({});

    const currentCategory = categories.find((el) => {
        return el.slug === params.categoryName;
    });

    const isCategoryDisabledResult = currentCategory
        ? _isCategoryDisabled(currentCategory)
        : {};

    useEffect(() => {
        if (Object.values(products).length) {
            setFilteredProducts([].concat.apply([], Object.values(products)));
        }
    }, [products]);

    useEffect(() => {
        if (currentCategory) {
            const temp = [].concat
                .apply([], Object.values(products))
                .filter((el) =>
                    el.categories.includes(currentCategory.term_id)
                );
            setAllProducts(temp);
        }
    }, [currentCategory]);

    if (
        categories?.length &&
        (!currentCategory ||
            (isCategoryDisabledResult.disabled &&
                currentCategory.limit_type !== "block"))
    ) {
        if (config.CONFIG_empty_page_redirect === "on") {
            navigateTo("/");
        } else {
            navigateTo("/not-found");
        }
        return null;
    }

    const handleFilter = (filteredProducts) => {
        if (filteredProducts) {
            setFilteredProducts(filteredProducts);
        } else {
            setFilteredProducts([].concat.apply([], Object.values(products)));
        }
    };

    const handleClickCategoryTag = (categoryID, tagID) => {
        let tmpArray = _clone(activeCategoryTags);

        if (!tmpArray[categoryID] || !tmpArray[categoryID].includes(tagID)) {
            tmpArray[categoryID] = [tagID];
        } else {
            delete tmpArray[categoryID];
        }

        setActiveCategoryTags(tmpArray);
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
            <Header />
            <TopCategoriesMenu />
            <Container
                sx={{
                    flexGrow: "1",
                }}
            >
                <h1 className="page-title" lang="ru">
                    {currentCategory ? (
                        currentCategory.name
                    ) : productsStatus ? (
                        ""
                    ) : (
                        <Skeleton variant="rounded" width={350} height={40} />
                    )}
                </h1>
                {!configStatus || config.CONFIG_searching_disable ? null : (
                    <SearchBar
                        products={allProducts}
                        dontShowList
                        dontShowButton
                        handleFilter={handleFilter}
                    />
                )}
                {isCategoryDisabledResult.message ? (
                    <Alert
                        severity={
                            isCategoryDisabledResult.disabled ? "error" : "info"
                        }
                        sx={{ mb: 1 }}
                    >
                        {isCategoryDisabledResult.message}
                    </Alert>
                ) : null}
                {currentCategory &&
                Object.values(currentCategory.tags).length ? (
                    <Box
                        className="product--category-tags-container"
                        sx={
                            productLayout === "one"
                                ? {
                                      mb: "8px",
                                  }
                                : {}
                        }
                    >
                        {currentCategory.tags &&
                            Object.values(currentCategory.tags).map(
                                (tag, tagIndex) => (
                                    <Button
                                        key={`tag-${tag.term_id}`}
                                        variant="button"
                                        className={`btn btn--tag ${
                                            activeCategoryTags.hasOwnProperty(
                                                currentCategory.term_id
                                            )
                                                ? activeCategoryTags[
                                                      currentCategory.term_id
                                                  ].includes(tag.term_id)
                                                    ? "btn--tag-active btn--action"
                                                    : ""
                                                : ""
                                        }`}
                                        sx={{ mr: 1, mb: 1, bgcolor: "#fff" }}
                                        onClick={() =>
                                            handleClickCategoryTag(
                                                currentCategory.term_id,
                                                tag.term_id
                                            )
                                        }
                                    >
                                        {tag.name}
                                    </Button>
                                )
                            )}
                    </Box>
                ) : null}

                {!filteredProducts?.length &&
                !productsStatus &&
                !currentCategory ? (
                    <ProductsListSkeleton />
                ) : currentCategory ? (
                    <Grid container spacing={{ xs: 0, sm: 2 }}>
                        {filteredProducts
                            ?.sort((product1, product2) =>
                                product1["order"] > product2["order"] ? 1 : -1
                            )
                            ?.map((product) =>
                                product.categories.includes(
                                    currentCategory.term_id
                                ) ? (
                                    activeCategoryTags.hasOwnProperty(
                                        currentCategory.term_id
                                    ) &&
                                    activeCategoryTags[currentCategory.term_id]
                                        .length ? (
                                        Object.values(product.tags).filter(
                                            (productTag) =>
                                                activeCategoryTags[
                                                    currentCategory.term_id
                                                ].includes(productTag.term_id)
                                        ).length ? (
                                            <Product
                                                key={product.id}
                                                product={product}
                                            />
                                        ) : (
                                            ""
                                        )
                                    ) : (
                                        <Product
                                            key={product.id}
                                            product={product}
                                            disabled={
                                                _isCategoryDisabled(
                                                    currentCategory
                                                ).disabled
                                            }
                                        />
                                    )
                                ) : (
                                    ""
                                )
                            )}
                    </Grid>
                ) : null}
            </Container>
            <ProductModal />
            <div className="screen-footer">
                {_isMobile() ? <MobileMiniCart /> : ""}
                <FooterBonuses />
            </div>
            <Footer />
        </Box>
    );
};

export default CategoryPage;
