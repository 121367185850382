import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
    ProductModal,
    MobileMiniCart,
    Banners,
    FooterBonuses,
    Header,
    Footer,
    SubscribeSnackbar,
    ScrollToTop,
    StoriesList,
    HomeRecommends,
} from "../components";
import SearchBar from "../components/SearchBar";
import TopCategoriesMenu from "../components/TopCategoriesMenu";
import { Container, Skeleton } from "@mui/material";
import {
    _clone,
    _isMobile,
    _isCategoryDisabled,
    _isObjEmpty,
} from "../components/helpers.js";
import { ProductsListSkeleton } from "../components/Skeletons/ProductsListSkeleton";
import BannersSkeleton from "../components/Skeletons/BannersSkeleton";
import ProductCategory from "components/Product/ProductCategory";

export default function Home() {
    const config = useSelector((state) => state.config.data);
    const configStatus = useSelector(
        (state) => !_isObjEmpty(state.config.data)
    );
    const productsStatus = useSelector((state) => state.products.status);
    const products = useSelector((state) => state.products.items);
    const categories = useSelector((state) => state.products.categories);

    const productsForSearch = useMemo(() => {
        if (config.CONFIG_searching_disable) {
            return null;
        }
        return [].concat
            .apply([], Object.values(products))
            .filter((product) => {
                if (
                    product.categories.length === 1 &&
                    product.categories.includes(
                        parseInt(config.CONFIG_bonuses_category)
                    )
                ) {
                    return false;
                } else if (
                    product.categories.every((el) =>
                        config.CONFIG_exclude_categories?.includes(el)
                    )
                ) {
                    return false;
                }
                return true;
            });
    }, [products, config.CONFIG_searching_disable]);

    return (
        <>
            <Header />
            {!!window.newHeaderDesign && <TopCategoriesMenu />}

            <div className="home">
                {configStatus ? (
                    config.CONFIG_type_slider === "stories" ? (
                        <StoriesList />
                    ) : (
                        <Banners />
                    )
                ) : (
                    <BannersSkeleton />
                )}

                {!window.newHeaderDesign && <TopCategoriesMenu />}

                {configStatus ? <HomeRecommends /> : null}

                <Container>
                    {!configStatus || config.CONFIG_searching_disable ? null : (
                        <SearchBar
                            dontShowList={true}
                            products={productsForSearch}
                        />
                    )}
                    {!categories?.length && !productsStatus ? (
                        <div className="product--category-container">
                            <h2>
                                <Skeleton
                                    variant="rounded"
                                    width={200}
                                    height={22}
                                    sx={{ borderRadius: "20px" }}
                                />
                            </h2>
                            <ProductsListSkeleton />
                        </div>
                    ) : (
                        categories.map((category) => (
                            <ProductCategory
                                products={products}
                                category={category}
                                key={`container-category-${category.term_id}`}
                            />
                        ))
                    )}
                </Container>

                <ProductModal />

                <SubscribeSnackbar />

                <div className="screen-footer">
                    {_isMobile() ? <MobileMiniCart /> : ""}
                    {_isMobile() ? null : <ScrollToTop />}
                    <FooterBonuses />
                </div>
            </div>
            <Footer />
        </>
    );
}
