import { Container } from "@mui/material";

import useMetricFunctions from "../hooks/useMetricFunctions";

import AppStoreIcon from "../img/app-store-bage-white.svg";
import GooglePlayIcon from "../img/google-play-bage-white.svg";
import PhoneIcon from "../img/iphone_x.png";

import "../css/mobile-apps-widget.css";
import { useSelector } from "react-redux";

const MobileAppsWidget = () => {
    const { mectricClickAndroid, mectricClickIos } = useMetricFunctions();

    const config = useSelector((state) => state.config.data);

    if (!config.CONFIG_APPSTORE && !config.CONFIG_GPLAY) {
        return null;
    }

    return (
        <div className="mobile-apps-widget">
            <Container>
                <div className="mobile-apps-widget--inner-wrapper">
                    <div className="mobile-apps-widget--logo-container">
                        <div className="mobile-apps-widget--circle">
                            <div className="mobile-apps-widget--inner-circle"></div>
                        </div>
                        <img
                            src={PhoneIcon}
                            className="mobile-apps-widget--logo"
                            alt="Логотип"
                        />
                        <img
                            src={config.CONFIG_company_logo_main}
                            className="mobile-apps-widget--img"
                            alt="Логотип"
                        />
                    </div>
                    <div className="mobile-apps-widget--description">
                        Установи наше приложение и заказывай любимую еду с
                        доставкой ещё быстрее!
                    </div>

                    <div className="mobile-apps-widget--links-container">
                        {config.CONFIG_APPSTORE && (
                            <div className="mobile-apps-widget--link">
                                <a
                                    href={config.CONFIG_APPSTORE}
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={mectricClickIos}
                                >
                                    <img src={AppStoreIcon} alt="iOS APP" />
                                </a>
                            </div>
                        )}
                        {config.CONFIG_GPLAY && (
                            <div className="mobile-apps-widget--link">
                                <a
                                    href={config.CONFIG_GPLAY}
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={mectricClickAndroid}
                                >
                                    <img
                                        src={GooglePlayIcon}
                                        alt="Android APP"
                                    />
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default MobileAppsWidget;
