import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { setOpenModalAuth } from "../../redux/actions/user";
import { Box, Container, Tab, Tabs } from "@mui/material";
import { Header, Footer } from "../../components";
import { _getDomain, _isMobile } from "../../components/helpers.js";
import "../../css/account.css";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Orders from "./Orders";
import UserSettings from "./UserSettings";
import MyAddresses from "./MyAddresses";
import UserBonuses from "./UserBonuses";

export default function Account() {
    const dispatch = useDispatch();

    const user = useSelector(({ user }) => user.user, shallowEqual);
    const config = useSelector(({ config }) => config.data);

    const [activeTab, setActiveTab] = useState(
        window.location.hash ? window.location.hash.slice(1) : "settings"
    );

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
        window.location.hash = newValue;
    };

    const handleOpenAuthModal = () => {
        dispatch(setOpenModalAuth(true));
    };

    const isBonusProgrammActive =
        !!config.bonusProgrammStatus ||
        config.bonusProgramm?.status === "active";

    return (
        <Box
            sx={{
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Header />
            <Container sx={{ flexGrow: 1, maxWidth: "1328px !important" }}>
                <h1 className="page-title" lang="ru">
                    Личный кабинет
                </h1>

                {user.token ? (
                    <TabContext value={activeTab}>
                        <Tabs
                            onChange={handleChange}
                            className="account--tab-list"
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            value={activeTab}
                            sx={{
                                "& .MuiTabs-scrollButtons.Mui-disabled": {
                                    opacity: _isMobile() ? 0.3 : 0,
                                },
                            }}
                        >
                            <Tab
                                disableRipple
                                label="Настройки"
                                value="settings"
                            />
                            {isBonusProgrammActive ? (
                                <Tab
                                    disableRipple
                                    label="Бонусы"
                                    value="bonuses"
                                />
                            ) : null}
                            <Tab disableRipple label="Заказы" value="orders" />
                            <Tab
                                disableRipple
                                label="Мои адреса"
                                value="addresses"
                            />
                        </Tabs>

                        <TabPanel
                            value="settings"
                            sx={{
                                boxShadow: "var(--box-shadow)",
                                borderRadius: "10px 10px 10px 10px",
                                bgcolor: "var(--modal-background)",
                            }}
                        >
                            <UserSettings />
                        </TabPanel>

                        {isBonusProgrammActive ? (
                            <TabPanel
                                value="bonuses"
                                sx={{
                                    boxShadow: "var(--box-shadow)",
                                    borderRadius: "10px",
                                    bgcolor: "var(--modal-background)",
                                }}
                            >
                                <UserBonuses />
                            </TabPanel>
                        ) : null}

                        <TabPanel
                            value="orders"
                            sx={{
                                boxShadow: "var(--box-shadow)",
                                borderRadius: "10px",
                                bgcolor: "var(--modal-background)",
                            }}
                        >
                            <Orders />
                        </TabPanel>
                        <TabPanel
                            value="addresses"
                            sx={{
                                boxShadow: "var(--box-shadow)",
                                borderRadius: "10px",
                                bgcolor: "var(--modal-background)",
                            }}
                        >
                            <MyAddresses />
                        </TabPanel>
                    </TabContext>
                ) : (
                    <div className="auth">
                        <p>Вы не авторизованы.</p>
                        <p>
                            <a
                                className="main-color"
                                onClick={handleOpenAuthModal}
                            >
                                Авторизуйтесь
                            </a>
                            , чтобы войти в личный кабинет.
                        </p>
                    </div>
                )}
            </Container>
            <Footer />
        </Box>
    );
}
