import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Skeleton } from "@mui/material";

const HeaderPhoneSkeleton = () => {
    return (
        <div className="header-phone">
            <LocationOnIcon
                className="main-color"
                sx={{ fontSize: 40, height: "auto", mr: "0.5rem" }}
            />
            <div className="header-phone--content">
                <div className="title">
                    <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{
                            width: 120,
                            height: 20,
                        }}
                    />
                </div>
                <b className={"choosenTown town"}>
                    <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{
                            width: 170,
                            height: 24,
                            transform: "scale(1, 0.80)",
                        }}
                    />
                </b>
            </div>
        </div>
    );
};

export default HeaderPhoneSkeleton;
