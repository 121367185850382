import useMetricFunctions from "hooks/useMetricFunctions";
import { useDispatch, useSelector } from "react-redux";
import { openMiniCart } from "redux/actions/miniCart";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { _declension } from "components/helpers";

const MiniCartButton = () => {
    const dispatch = useDispatch();
    const { mectricOpenCart } = useMetricFunctions();

    const cartTotalPrice = useSelector((state) => state.cart.totalPrice);
    const cartCountItems = useSelector((state) => state.cart.countItems);

    const handleOpenMiniCart = () => {
        window.location.hash = "minicart-sidebar";
        mectricOpenCart();
        dispatch(openMiniCart());
    };

    return (
        <button
            className="btn--action minicart openMiniCart"
            onClick={handleOpenMiniCart}
            style={{
                height: window.fullWidthSite ? "40px" : "auto",
                marginLeft: window.fullWidthSite ? "12px" : "0",
            }}
        >
            <ShoppingCartIcon className="minicart--cart-icon" />
            <div className="minicart--separator"></div>
            <div className="minicart--topcart--total">
                <span className="minicart--topcart--count-item">
                    {cartCountItems}{" "}
                    {_declension(cartCountItems, [
                        "товар",
                        "товара",
                        "товаров",
                    ])}
                </span>
                <span className="minicart--topcart--price-total">
                    {cartTotalPrice.toLocaleString("ru-RU")} &#8381;
                </span>
            </div>
        </button>
    );
};

export default MiniCartButton;
