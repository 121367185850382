import { ThemeProvider, createTheme } from "@mui/material";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

const MaterialThemeProvider = ({ children }) => {
    const mainColor = window.CONFIG_main_color
        ? window.CONFIG_main_color
        : "#000";
    const secondColor = window.CONFIG_second_color
        ? window.CONFIG_second_color
        : "#000";

    const getMUIcolorPallete = (mode) => ({
        primary: {
            light: "#757ce8",
            main: mainColor.match(/#[a-f0-9]{6}\b/gi) ? mainColor : "#000",
            dark: secondColor.match(/#[a-f0-9]{6}\b/gi) ? secondColor : "#000",
            contrastText: "#fff",
        },
        secondary: {
            light: "#ff7961",
            main: secondColor.match(/#[a-f0-9]{6}\b/gi) ? secondColor : "#000",
            dark: "#ba000d",
            contrastText: "#000",
        },
        white: {
            main: "#fff",
            contrastText: "#fff",
        },
        vk: {
            main: "#0177ff",
        },
        mode,
        ...(mode === "light"
            ? {
                  // palette values for light mode
                  //   primary: "red",
                  //   divider: amber[200],
                  //   text: {
                  //       primary: colorsPalette.light.textColor,
                  //       secondary: grey[800],
                  //   },
              }
            : {
                  background: {
                      paper: "#141414",
                  },
              }),
    });

    const themeMode =
        window.CONFIG_design_dark_theme == "active" ? "dark" : "light";
    const palette = useMemo(() => getMUIcolorPallete(themeMode), [themeMode]);

    const theme = createTheme({
        palette,
        typography: {
            fontFamily: "inherit",
            button: {
                textTransform: "none",
                fontSize: 16,
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: 20,
                        padding: "6px 20px !important",
                        boxShadow: "none",
                        "&:hover": {
                            boxShadow: "none",
                        },
                    },
                },
            },
            MuiContainer: {
                styleOverrides: {
                    root: {
                        maxWidth: window.fullWidthSite
                            ? "none !important"
                            : "1328px !important",
                    },
                },
            },
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                mg: 1050,
                lg: 1200,
                xl: 1536,
                xxl: 1750,
                mobilexs: 0,
                mobilesm: 375,
                mobilemd: 600,
                mobilelg: 720,
                desctop: 899,
            },
        },
    });
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MaterialThemeProvider;
