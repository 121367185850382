import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Dialog, IconButton, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useWorkingStatus from "../hooks/useWorkingStatus";
import { _getPlatform, _isMobile } from "./helpers";
import clsx from "clsx";
import { ReactComponent as CatSleepSvg } from "../img/cat-sleep.svg";
import "../css/we-closed.css";
import { useNavigate } from "react-router";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function WeClosed() {
    const { workingStatus, maintenanceStatus } = useWorkingStatus();
    const navigate = useNavigate();

    const {
        CONFIG_maintenance_text,
        CONFIG_maintenance_title,
        CONFIG_maintenance_type,
        CONFIG_preorder_limit_type,
        CONFIG_close_modal_by_cross,
    } = useSelector((state) => state.config.data);

    const weClosedImg = window.CONFIG_not_working_image;
    const maintenanceImg = window.CONFIG_platform_off_image;

    const [openWeClosedModal, setOpenWeClosedModal] = useState(false);

    useEffect(() => {
        if (!workingStatus || !maintenanceStatus) {
            setOpenWeClosedModal(true);
        }
    }, [workingStatus, maintenanceStatus]);

    const handleModalClose = () => {
        setOpenWeClosedModal(false);
    };

    const handleBackToChooseTown = () => {
        if (window.townsTypeRoute === "folder") {
            navigate("/");
        } else {
            window.location.href = window.location.origin;
        }
    };

    if (
        (workingStatus && maintenanceStatus) ||
        (window.adminAccess &&
            !window.location.href.includes("maintenance_preview"))
    )
        return null;

    let dialogProps = { open: openWeClosedModal, maxWidth: "md" };
    if (_isMobile()) {
        dialogProps.TransitionComponent = Transition;
        dialogProps.fullScreen = true;
        dialogProps.scroll = "body";
    }
    return (
        <Dialog
            maxWidth="md"
            {...dialogProps}
            sx={{
                "& .MuiPaper-root": {
                    borderRadius: _isMobile() ? "0px" : "20px",
                },
            }}
            onClose={(event, reason) => {
                if (
                    reason === "escapeKeyDown" ||
                    (reason === "backdropClick" &&
                        CONFIG_close_modal_by_cross !== "active")
                ) {
                    if (
                        !maintenanceStatus &&
                        CONFIG_maintenance_type !== "canViewSite"
                    ) {
                        return;
                    } else {
                        handleModalClose();
                    }
                }
            }}
        >
            <div className="modal-alert--wrapper we-closed-modal">
                {!maintenanceStatus &&
                CONFIG_maintenance_type !== "canViewSite" ? null : (
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleModalClose}
                        aria-label="close"
                        className={clsx(
                            "modal-close",
                            _getPlatform() === "vk" && "vk"
                        )}
                    >
                        <CloseIcon />
                    </IconButton>
                )}
                {!maintenanceStatus ? (
                    maintenanceImg ? (
                        <img src={maintenanceImg} className="cat-sleep-img" />
                    ) : (
                        <CatSleepSvg className="cat-sleep-img" />
                    )
                ) : weClosedImg ? (
                    <img src={weClosedImg} className="cat-sleep-img" />
                ) : (
                    <CatSleepSvg className="cat-sleep-img" />
                )}
                <h2 className="main-color">
                    {!maintenanceStatus && CONFIG_maintenance_title
                        ? CONFIG_maintenance_title
                        : "Сейчас мы закрыты."}
                </h2>
                <div
                    dangerouslySetInnerHTML={{
                        __html: !maintenanceStatus
                            ? CONFIG_maintenance_text
                            : CONFIG_preorder_limit_type === "disable" ||
                              CONFIG_preorder_limit_type === "currentDay"
                            ? ""
                            : "Вы можете оставить заказ и мы начнём готовить его в первую очередь!",
                    }}
                ></div>
                {(!maintenanceStatus &&
                    CONFIG_maintenance_type !== "canViewSite") ||
                CONFIG_preorder_limit_type === "disable" ||
                CONFIG_preorder_limit_type === "currentDay" ? null : (
                    <Button
                        className="btn--action"
                        variant="contained"
                        onClick={handleModalClose}
                        sx={{ mt: "20px" }}
                    >
                        Смотреть меню
                    </Button>
                )}
                {!maintenanceStatus &&
                CONFIG_maintenance_type === "fullClose" &&
                window.towns?.length ? (
                    <Button
                        className="btn--action"
                        variant="contained"
                        onClick={handleBackToChooseTown}
                        sx={{ mt: "20px" }}
                    >
                        Вернуться на главную
                    </Button>
                ) : null}
            </div>
        </Dialog>
    );
}
