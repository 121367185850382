import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    completelyRemovePromocode,
    removeProductFromCart,
} from "../redux/actions/cart";
import { updateAlerts } from "../redux/actions/systemAlerts";
import { cleanStore } from "../redux/actions/global";

import {
    _isCategoryDisabled,
    _isObjEmpty,
    _isPromocodesEnabled,
} from "./helpers";

import useChoosenTown from "../hooks/useChoosenTown";

const Actualizer = () => {
    const dispatch = useDispatch();
    const { choosenTown } = useChoosenTown();

    const {
        categories,
        items: products,
        status: productsStatus,
    } = useSelector((state) => state.products);

    const { items: cartItems } = useSelector((state) => state.cart);

    const promocode = useSelector((state) => state.cart.promocode);
    const conditionalPromocode = useSelector(
        (state) => state.cart.conditionalPromocode
    );

    const config = useSelector((state) => state.config.data);
    const configStatus = useSelector((state) => state.config.status);

    // Очищаем redux store если сохраненный конфиг не соответствует текущему городу
    useEffect(() => {
        if (
            config.currentTownId &&
            choosenTown &&
            config.currentTownId !== choosenTown.id
        ) {
            dispatch(cleanStore());
        }
    }, [choosenTown, config.currentTownId]);

    // Проверяем на актуальность товары в корзине
    useEffect(() => {
        if (categories && productsStatus && products) {
            // Создаем массив айдишников недоступных на данное время категорий
            const disabledCategoriesArr = categories.filter(
                (category) => _isCategoryDisabled(category).disabled
            );
            const disabledCategoriesInx = disabledCategoriesArr.map(
                (category) => category.term_id
            );

            const allCartProducts = [].concat.apply(
                [],
                Object.values(cartItems).map((obj) => obj.items)
            );
            // Удаляем товары с недоступными категориями, товары с измененной ценой и удалённые товары
            let isCartChanged = false;
            allCartProducts.forEach((product) => {
                if (product.type === "simple") {
                    let productPrice = parseInt(product.options._price);
                    if (product.modificatorsAmount) {
                        productPrice -= parseInt(product.modificatorsAmount);
                    }
                    if (
                        product.categories?.some((id) =>
                            disabledCategoriesInx.includes(id)
                        ) ||
                        !products[product.id] ||
                        productPrice !=
                            parseInt(products[product.id].options._price)
                    ) {
                        isCartChanged = true;
                        dispatch(
                            removeProductFromCart({
                                ...product,
                                disabled: true,
                            })
                        );
                    }
                } else if (product.type === "variations") {
                    if (
                        product.categories?.some((id) =>
                            disabledCategoriesInx.includes(id)
                        ) ||
                        !products[product.id] ||
                        !products[product.id]?.variants[
                            product.variant.variant_id
                        ] ||
                        product.variant.price !=
                            products[product.id].variants[
                                product.variant.variant_id
                            ].price
                    ) {
                        isCartChanged = true;
                        dispatch(
                            removeProductFromCart({
                                ...product,
                                disabled: true,
                            })
                        );
                    }
                }
            });
            if (isCartChanged) {
                dispatch(
                    updateAlerts({
                        open: true,
                        message:
                            "Часть товаров удалены из корзины, т.к. время действия акции закончилось.",
                    })
                );
            }
        }
    }, [categories, products, productsStatus]);

    //Удаляем промокод если они отключены
    useEffect(() => {
        if (
            configStatus &&
            !_isPromocodesEnabled(config) &&
            (!_isObjEmpty(promocode) || conditionalPromocode)
        ) {
            dispatch(completelyRemovePromocode());
            dispatch(
                updateAlerts({
                    open: true,
                    message: "Промокод был отменен",
                })
            );
        }
    }, [configStatus]);

    return <></>;
};

export default Actualizer;
