import { useSelector } from "react-redux";
import {
    _cartHasDiscountProduct,
    checkProductForAutoDiscount,
} from "../components/helpers";

const useAccrueBonuses = (
    usedBonuses = 0,
    autoDiscountAmount = 0,
    autoDiscount
) => {
    const cartProducts = useSelector((state) =>
        Object.values(state.cart.items)
    );
    const promocode = useSelector((state) => state.cart.promocode);
    const bonusProduct = useSelector((state) => state.cart.bonusProduct);
    const cartSubTotal = useSelector((state) => state.cart.subTotalPrice);
    const cartTotal = useSelector((state) => state.cart.totalPrice);

    const {
        programStatus,
        deliveryCashback,
        selfDeliveryCashback,
        specifiedCategories,
        excludeCategories,
        allowWithPromocode,
        allowWithBonusProduct,
        allowWithDiscountProducts,
        allowWithBonuses,
    } = useSelector(
        ({
            config: {
                data: { bonusProgramm },
            },
        }) => {
            return {
                programStatus: bonusProgramm.status === "active",
                deliveryCashback: bonusProgramm.deliveryPercent,
                selfDeliveryCashback: bonusProgramm.selfDeliveryPercent,
                specifiedCategories: bonusProgramm.categories,
                excludeCategories: bonusProgramm.excludeCategories === "yes",
                allowWithPromocode: bonusProgramm.allowPromocode === "active",
                allowWithBonusProduct:
                    bonusProgramm.allowBonusProduct === "active",
                allowWithDiscountProducts:
                    bonusProgramm.allowSaleProduct === "active",
                allowWithBonuses: bonusProgramm.allowWithBonuses === "active",
            };
        }
    );

    let accrualBase = 0;

    const disabledByPromocode = !allowWithPromocode && promocode.code;
    const disabledByBonusProduct = !allowWithBonusProduct && bonusProduct.id;
    const disabledByUsedBonuses = !allowWithBonuses && usedBonuses;

    const disabled =
        disabledByPromocode || disabledByBonusProduct || disabledByUsedBonuses;

    if (!disabled && programStatus) {
        cartProducts.forEach((cartProduct) => {
            // Проверяем исключена ли категория товара из начисления бонусов
            if (specifiedCategories?.length && excludeCategories) {
                const excludedCategoryId = cartProduct.items[0].categories.find(
                    (productCategoryId) =>
                        specifiedCategories.includes(productCategoryId)
                );
                if (excludedCategoryId) {
                    return;
                }
            } else if (specifiedCategories?.length) {
                if (
                    !cartProduct.items[0].categories.some((id) =>
                        specifiedCategories.includes(id)
                    )
                ) {
                    return;
                }
            }

            cartProduct.items.forEach((item) => {
                // Если начисление не действует на товары по скидке
                if (
                    !allowWithDiscountProducts &&
                    ((item.options._sale_price &&
                        parseInt(item.options._regular_price) >
                            parseInt(item.options._sale_price)) ||
                        item.variant?._sale_price)
                ) {
                    return;
                }

                // Учитываем применённые промокоды и автоскидки при подсчёте начисляемых бонусов
                if (!!promocode?.code || autoDiscountAmount) {
                    let autoDiscountPercent = 0;
                    if (autoDiscountAmount && autoDiscount) {
                        if (autoDiscount.type === "fixed") {
                            autoDiscountPercent =
                                autoDiscountAmount / cartTotal;
                        } else if (
                            autoDiscount.type === "percent" &&
                            checkProductForAutoDiscount(autoDiscount, item)
                        ) {
                            autoDiscountPercent = autoDiscount.amount / 100;
                        }
                    }

                    if (promocode.type === "fixed_cart") {
                        const paidWithPromocodePercent =
                            promocode.amount / cartSubTotal;

                        accrualBase +=
                            parseInt(item.options._price) *
                            (1 - paidWithPromocodePercent) *
                            (1 - autoDiscountPercent);
                    } else if (
                        promocode.type === "percent" ||
                        promocode.type === "fixed_product"
                    ) {
                        if (item.options._promocode_price >= 0) {
                            accrualBase +=
                                Math.ceil(item.options._promocode_price) *
                                (1 - autoDiscountPercent);
                        } else {
                            accrualBase +=
                                parseInt(item.options._price) *
                                (1 - autoDiscountPercent);
                        }
                    } else {
                        accrualBase +=
                            parseInt(item.options._price) *
                            (1 - autoDiscountPercent);
                    }
                } else {
                    accrualBase += parseInt(item.options._price);
                }
            });
        });
    }

    const deliveryAccruedBonuses = Math.floor(
        accrualBase * (deliveryCashback / 100)
    );
    const selfDeliveryAccruedBonuses = Math.floor(
        accrualBase * (selfDeliveryCashback / 100)
    );

    return {
        deliveryAccruedBonuses,
        selfDeliveryAccruedBonuses,
        disabledByPromocode,
        disabledByBonusProduct,
        disabledByUsedBonuses,
    };
};

export default useAccrueBonuses;
